import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Decimal from "decimal.js";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_OPPORTUNITIES_STATUS, OPPORTUNITY_DETAILS_ROUTE, SHOW_MINER_ACTION_MESSAGE } from "../../app/constants";
import {
  amountFormatSatoshisToBTC,
  getStreamingBasicStatusEnumClass,
  roundToSignificantDigits,
} from "../../app/helper/utils";
import { OpportunityStatus, Sort, YesOrNoChoice } from "../../app/model/api";
import { useQueryGetMinerOpportunities } from "../../app/query/useQueryGetUser";
import Checkbox from "../../components/atoms/Checkbox";
import Loader from "../../components/atoms/Loader";
import ProgressPercentBar from "../../components/atoms/ProgressPercentBar";
import SectionTitle from "../../components/atoms/SectionTitle";
import Pagination from "../../components/molecules/Pagination";
import HeroMinerOpportunities from "../../components/organisms/HeroMinerOpportunities";
import Table from "../../components/organisms/Table";

export function MinerOpportunities() {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [settlementDirection, setSettlementDirection] = useState<Sort>(Sort.Asc);
  const [activeSinceDirection, setActiveSinceDirection] = useState<Sort>(Sort.Asc);
  const [status, setStatus] = useState<OpportunityStatus | "All">("All");
  const [actionFirst, setActionFirst] = useState<YesOrNoChoice>(YesOrNoChoice.Yes);
  const [hideSettled, setHideSettled] = useState<YesOrNoChoice>(YesOrNoChoice.Yes);

  const { data, isFetching } = useQueryGetMinerOpportunities({
    offset: (page - 1) * perPage,
    limit: perPage,
    status: status === "All" ? undefined : status,
    hideSettled,
    actionFirst,
    activeSince: activeSinceDirection,
    settlement: settlementDirection,
  });

  const handleActionFirst = (name: string, value: string | number | boolean) => {
    setActionFirst(value ? YesOrNoChoice.Yes : YesOrNoChoice.No);
    setPage(1);
  };

  const handleSettledAction = (name: string, value: string | number | boolean) => {
    setHideSettled(value ? YesOrNoChoice.Yes : YesOrNoChoice.No);
    setPage(1);
  };

  const paginationPages = data?.count ? Math.ceil(data?.count / perPage) : 0;

  return (
    <div>
      <HeroMinerOpportunities />
      {!data?.rows && !isFetching && (
        <div>
          <SectionTitle title="Opportunity list" />
          <div className="container-custom mx-auto !pt-14 !pb-16">
            <div className="bg-white w-[1170px] h-[524px] flex flex-col justify-center items-center">
              <h1 className=" text-center text-4xl pb-2 font-pp-neue-machina">Welcome to Block Green!</h1>
              <div className=" px-80 items-center text-center text-base ">
                <p>
                  Please work with your Account Manager at Block Green to configure your first opportunity, or{" "}
                  <a
                    href="mailto:support@block.green"
                    className="text-purple underline hover:no-underline font-pp-neue-machina"
                    rel="noreferrer"
                  >
                    {" "}
                    reach out here.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {data?.rows && (
        <div>
          <div>
            <SectionTitle title="Opportunity list" />

            <div className="container-custom mx-auto !pt-14 !pb-16">
              <div className="flex justify-between items-center">
                <FormControl sx={{ width: 250 }}>
                  <InputLabel id="select-status">Status</InputLabel>
                  <Select
                    labelId="select-status"
                    label={"Status"}
                    id="select-status"
                    value={status}
                    size="small"
                    onChange={(newValue) => {
                      if (newValue.target.value === "All") setStatus("All");
                      else {
                        setStatus(newValue.target.value as OpportunityStatus);
                      }

                      setPage(1);
                    }}
                  >
                    {ADMIN_OPPORTUNITIES_STATUS.map((action) => (
                      <MenuItem key={"key-select-status" + action.value} value={action.value}>
                        {action.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="flex gap-[35px]">
                  <Checkbox
                    name="tst"
                    label={SHOW_MINER_ACTION_MESSAGE}
                    onChange={(name, value) => handleActionFirst(name, value)}
                    checked={actionFirst === YesOrNoChoice.Yes}
                  />
                  <Checkbox
                    name="hideSettled"
                    label="Hide Settled"
                    onChange={(name, value) => handleSettledAction(name, value)}
                    checked={hideSettled === YesOrNoChoice.Yes}
                  />
                </div>
              </div>

              <Table>
                <Table.Head>
                  <Table.TR>
                    <Table.TH>Status</Table.TH>
                    <Table.TH>Opportunity ID</Table.TH>
                    <Table.TH className="!text-start" onSort={(direction) => setActiveSinceDirection(direction)}>
                      Opportunity active since
                    </Table.TH>
                    <Table.TH className="!text-center" onSort={(direction) => setSettlementDirection(direction)}>
                      Agreement end date
                    </Table.TH>
                    <Table.TH className="!text-center">Days remaining</Table.TH>
                    <Table.TH className="!text-center">ALLOCATED HASHRATE</Table.TH>
                    <Table.TH className="!text-start">Liquidity received</Table.TH>
                  </Table.TR>
                </Table.Head>

                <Table.Body>
                  {data?.rows &&
                    data?.rows.map((item) => {
                      const percent = item.liquidityReceived?.total
                        ? new Decimal(item.liquidityReceived?.current || 0)
                            .mul(100)
                            .div(item.liquidityReceived?.total)
                            .toNumber()
                        : 0;

                      return (
                        <Table.TR
                          key={item.id}
                          className="cursor-pointer"
                          onClick={() => navigate(OPPORTUNITY_DETAILS_ROUTE.replace(":id", String(item.id)))}
                        >
                          <Table.TD>
                            <div className={`status-box ${getStreamingBasicStatusEnumClass(item.status)}`}>
                              {item.status}
                            </div>
                          </Table.TD>
                          <Table.TD>#{item.idNum}</Table.TD>
                          <Table.TD className="!text-center">
                            {item.activeSince ? moment(item.activeSince).format("DD MMM YYYY") : "-"}
                          </Table.TD>
                          <Table.TD className="!text-center">
                            {item.agreementEndDate ? moment(item.agreementEndDate).format("DD MMM YYYY") : "-"}
                          </Table.TD>
                          <Table.TD>{item.remainingDuration ?? "-"}</Table.TD>
                          <Table.TD className="!text-center">
                            {item.currentHashrate ? `${roundToSignificantDigits(item.currentHashrate)} PH/s` : "-"}
                          </Table.TD>
                          <Table.TD>
                            <div className="flex items-center gap-1 font-bold text-dark mb-2">
                              {amountFormatSatoshisToBTC(item.liquidityReceived?.current || 0)} /
                              {amountFormatSatoshisToBTC(item.liquidityReceived?.total || 0)} BTC
                            </div>
                            <ProgressPercentBar value={parseFloat(percent.toFixed(2))} />
                          </Table.TD>
                        </Table.TR>
                      );
                    })}

                  {data && data?.rows?.length === 0 && !isFetching && (
                    <Table.TR>
                      <Table.TD colSpan={6} className="relative text-center !h-[150px]">
                        No results.
                      </Table.TD>
                    </Table.TR>
                  )}
                  {isFetching && (
                    <Table.Loader>
                      <Table.TD colSpan={6} className="relative !h-[865px]">
                        <Loader />
                      </Table.TD>
                    </Table.Loader>
                  )}
                </Table.Body>
              </Table>
              {paginationPages > 1 && (
                <Pagination
                  page={page}
                  paginationPages={paginationPages}
                  onPrev={() => setPage((prev: number) => prev - 1)}
                  onNext={() => setPage((prev: number) => prev + 1)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
