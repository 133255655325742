import { amountFormat, amountFormatSatoshisToBTC } from "../../../app/helper/utils";
import { useGetQueryPortfolioSummary } from "../../../app/query/useQueryGetLP";
import { ReactComponent as CoinsIcon } from "../../../assets/images/coins-icon.svg";
import heroAllTimeBck from "../../../assets/images/hero-bck-1.png";
import heroDailyBck from "../../../assets/images/hero-bck-2.png";
import { ReactComponent as InvestmentIcon } from "../../../assets/images/investment-icon.svg";
import HeroBox from "../../atoms/HeroBox";
import Loader from "../../atoms/Loader";
import "./style.scss";

const HeroPortfolio = () => {
  const { data, isFetching } = useGetQueryPortfolioSummary();

  return (
    <div className="hero-portfolio">
      <div className="container-custom mx-auto !py-16">
        <div className="grid grid-cols-2 gap-4">
          <HeroBox title="Overall Daily Revenue" titleIcon={<CoinsIcon />} backgroundImage={heroDailyBck}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <>
                <div className="crypto-value">{amountFormatSatoshisToBTC(data?.dailyRevenue.btc || 0)}</div>
                <sup>BTC</sup>
                <div className="fiat-value">{amountFormat(data?.dailyRevenue.usd || 0)}</div>
              </>
            )}
          </HeroBox>
          <HeroBox title="All Time Revenue" titleIcon={<InvestmentIcon />} backgroundImage={heroAllTimeBck}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <>
                <div className="crypto-value">{amountFormatSatoshisToBTC(data?.overallRevenue?.btc || 0)}</div>
                <sup>BTC</sup>
                <div className="fiat-value">{amountFormat(data?.overallRevenue?.usd || 0)}</div>
              </>
            )}
          </HeroBox>
        </div>
      </div>
    </div>
  );
};

export default HeroPortfolio;
