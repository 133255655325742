import { yupResolver } from "@hookform/resolvers/yup";
import HelpIcon from "@mui/icons-material/Help";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  ENERGY_MIX,
  ENTRY_MINER_PROGRESS_BAR,
  ERROR_DEFAULT_MESSAGE,
  MINER_GRANULAR_TIME_HORIZON_OF_LIQUIDITY,
  SELECT_COUNTRY,
  THANK_YOU_ROUTE,
} from "../../app/constants";
import { useMutationRegisterMiner } from "../../app/query/useMutationUser";
import Form from "../../components/organisms/Form";
import { PowerOfAttorneyType, PrimaryContactType, PUBLICLY_LISTED_INFO } from "./common.schema";
import { ContactDetails } from "./ContactDetails";
import { createRegisterMinerDto, MinerOnboardingForm, YupMinerFormSchema } from "./MinerOnboarding.schema";
import { PowerOfAttorneyAlert } from "./PowerOfAttorneyAlert";

export const MinerOnboarding = () => {
  const {
    unregister,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    control,
  } = useForm<MinerOnboardingForm>({
    resolver: yupResolver(YupMinerFormSchema),
    defaultValues: {
      primaryContact: "signup-user",
      powerOfAttorney: "yes",
      isPubliclyListed: false,
      countryOfIncorporation: "United States",
      timeHorizon: 0,
    },
  });
  const notify = (message: string) => toast.error(message);

  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutationRegisterMiner();
  const captchaRef = useRef<ReCAPTCHA>(null);

  const primaryContact: PrimaryContactType = watch("primaryContact");
  const powerOfAttorney: PowerOfAttorneyType = watch("powerOfAttorney");
  const isPubliclyListed: boolean = watch("isPubliclyListed");

  // if the user doesn't know the primary contact, auto set the power of attorney answer and lock it
  if (primaryContact === "notsure" && powerOfAttorney !== "notsure") setValue("powerOfAttorney", "notsure");

  React.useEffect(() => {
    // we need to manually register/unregister the annualReportUrl for validation
    if (isPubliclyListed) {
      register("annualReportUrl");
    } else {
      unregister("annualReportUrl");
    }
  }, [isPubliclyListed, register, unregister]);

  React.useEffect(() => {
    // we need to register/unregister the another fields for validation, depending
    // on the primary contact value
    if (primaryContact === "another-person") {
      register("anotherUserfirstname");
      register("anotherUserlastname");
      register("anotherUseremail");
      register("anotherUserphone");
    } else {
      unregister("anotherUserfirstname");
      unregister("anotherUserlastname");
      unregister("anotherUseremail");
      unregister("anotherUserphone");
    }
  }, [primaryContact, register, unregister]);

  const onSubmit = async (data: MinerOnboardingForm) => {
    try {
      const payload = createRegisterMinerDto(data);
      await mutateAsync(payload);

      navigate(THANK_YOU_ROUTE, {
        state: {
          title: "Request submitted",
          progressBar: ENTRY_MINER_PROGRESS_BAR,
          content:
            "We will get back to you within a few business days regarding the next onboarding steps, which will focus around KYC/AML and a financial health assessment (“KYM”). For more information please ",
          helperLink: true,
        },
      });
    } catch (error) {
      notify(ERROR_DEFAULT_MESSAGE);
    }
  };

  return (
    <Box width={"100%"}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box>
            <Divider textAlign="left" sx={{ mt: 4, mb: 4 }}>
              Personal information
            </Divider>
            <ContactDetails
              control={control}
              errors={{
                firstname: errors.currentUserfirstname?.message,
                lastname: errors.currentUserlastname?.message,
                email: errors.currentUseremail?.message,
                phone: errors.currentUserphone?.message,
              }}
              prefix="currentUser"
              register={register}
            ></ContactDetails>
          </Box>
          <Divider textAlign="left" sx={{ mt: 4, mb: 4 }}>
            Company details
          </Divider>
          <Box>
            <Grid direction="row" container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="companyName"
                  label={"Company legal name"}
                  placeholder="Bitcoin Liquidity Partners"
                  {...register("companyLegalName")}
                  fullWidth
                  variant="outlined"
                  error={!!errors.companyLegalName}
                  helperText={
                    <>
                      {errors.companyLegalName && <Box>{errors.companyLegalName.message}</Box>}
                      <Box>Please include legal suffixes such as 'AG', 'Inc.', or 'Ltd.'</Box>
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} alignItems={"center"}>
                <TextField
                  id="roleAtCompany"
                  label={"Role at company"}
                  {...register("roleAtCompany")}
                  placeholder="Assistant regional manager"
                  fullWidth
                  variant="outlined"
                  error={!!errors.roleAtCompany}
                  helperText={<>{errors.roleAtCompany && <Box>{errors.roleAtCompany.message}</Box>}</>}
                />
              </Grid>
            </Grid>
            <Grid direction="row" container spacing={2} mt={1}>
              <Grid item xs={12} md={6} alignItems={"center"}>
                <TextField
                  id="homepageUrl"
                  label={"[Optional] Homepage URL"}
                  {...register("homepageUrl")}
                  placeholder="https://example.com"
                  fullWidth
                  variant="outlined"
                  error={!!errors.homepageUrl}
                  helperText={<>{errors.homepageUrl && <Box>{errors.homepageUrl.message}</Box>}</>}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: "100%" }}>
                  <Autocomplete
                    id="country-of-incorporation"
                    options={SELECT_COUNTRY}
                    autoHighlight
                    defaultValue={{ label: "United States", value: "United States" }}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(first: (typeof SELECT_COUNTRY)[0], second: (typeof SELECT_COUNTRY)[0]) => {
                      return first.value === second.value;
                    }}
                    renderOption={(props, option) => {
                      return (
                        <Box component="li" {...props}>
                          {option.value}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...register("countryOfIncorporation")}
                        label="Country of incorporation"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        error={!!errors.countryOfIncorporation}
                        helperText={
                          <>{errors.countryOfIncorporation && <Box>{errors.countryOfIncorporation.message}</Box>}</>
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} mt={1} height={75}>
              <FormControl component="fieldset">
                <FormControlLabel
                  value="start"
                  control={
                    <>
                      <Switch color="primary" {...register("isPubliclyListed")} />
                    </>
                  }
                  label="Is your company publicly listed on a stock exchange?"
                  labelPlacement="end"
                />
              </FormControl>
              <Tooltip color="primary" title={PUBLICLY_LISTED_INFO}>
                <HelpIcon fontSize="small" sx={{ mr: 2 }} />
              </Tooltip>
              {isPubliclyListed && (
                <TextField
                  id="annualReportUrl"
                  label={"Annual report URL"}
                  {...register("annualReportUrl")}
                  placeholder="https://example.com"
                  fullWidth
                  sx={{ maxWidth: 500, mt: 1 }}
                  variant="outlined"
                  error={!!errors.annualReportUrl}
                  helperText={<>{errors.annualReportUrl && <Box>{errors.annualReportUrl.message}</Box>}</>}
                />
              )}
            </Grid>
            <Divider textAlign="left" sx={{ mt: 4 }}>
              KYC
            </Divider>

            <Box mt={2}>
              <Typography color="secondary" variant="body1">
                To complete your onboarding, we must conduct a standard KYC (Know Your Customer) and
                Anti-Money-Laundering verification.
              </Typography>
              <Grid container mt={2}>
                <Box display="flex" alignItems={"center"}>
                  <FormLabel id="rbtGrpPrimaryGroup" color="secondary">
                    <Box display="flex" color={"black"} alignItems={"center"}>
                      Who is the primary contact at your company to complete our KYC process?
                    </Box>
                  </FormLabel>
                  <Controller
                    name="primaryContact"
                    defaultValue="signup-user"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} sx={{ ml: 2 }} row>
                        <FormControlLabel value="signup-user" label="Myself" control={<Radio />} />
                        <FormControlLabel value="another-person" label="Another individual" control={<Radio />} />
                        <FormControlLabel value="notsure" label="Ask me later" control={<Radio />}></FormControlLabel>
                      </RadioGroup>
                    )}
                  ></Controller>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} alignItems={"center"} mt={1}>
                {primaryContact === "another-person" && (
                  <Box>
                    <Typography color="secondary" variant="body1" mb={1}>
                      Specify the primary contact responsible for completing a video ID process, the KYC questionnaire
                      and submitting necessary documents (e.g., certificate of incorporation, list of directors).
                    </Typography>
                    <ContactDetails
                      prefix="anotherUser"
                      control={control}
                      register={register}
                      errors={{
                        firstname: errors.anotherUserfirstname?.message,
                        lastname: errors.anotherUserlastname?.message,
                        email: errors.anotherUseremail?.message,
                        phone: errors.anotherUserphone?.message,
                      }}
                    ></ContactDetails>
                  </Box>
                )}
              </Grid>
              <Box mt={2}>
                <Box display="flex" alignItems={"center"}>
                  <FormLabel id="rbtGrpPowerOfAttorney" color="secondary">
                    <Box display="flex" color={"black"} alignItems={"center"}>
                      Does the primary contact have legal Power of Attorney for your organization?
                    </Box>
                  </FormLabel>

                  <Controller
                    name="powerOfAttorney"
                    defaultValue="yes"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup sx={{ ml: 2 }} row {...field}>
                        <FormControlLabel
                          disabled={primaryContact === "notsure"}
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={primaryContact === "notsure"}
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel value="notsure" control={<Radio />} label="Ask me later" />
                      </RadioGroup>
                    )}
                  ></Controller>
                </Box>
                {powerOfAttorney !== "yes" && (
                  <Grid container item maxWidth={1200} alignContent={"center"}>
                    <PowerOfAttorneyAlert />
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
          <Divider textAlign="left" sx={{ mt: 4, mb: 4 }}>
            Mining details
          </Divider>
          <Grid direction="row" container spacing={2} mt={1}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Self-mining installed capacity"
                placeholder="30"
                fullWidth
                type="number"
                {...register("selfMiningCapacity")}
                InputProps={{
                  endAdornment: <InputAdornment position="end">PH/S</InputAdornment>,
                }}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                error={!!errors.selfMiningCapacity}
                helperText={errors.selfMiningCapacity && <Box>{errors.selfMiningCapacity.message}</Box>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("averageEnergyPrice")}
                label="[Optional] Energy price"
                placeholder="30"
                fullWidth
                type="number"
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">USD/MWh</InputAdornment>,
                }}
                error={!!errors.averageEnergyPrice}
                helperText={errors.averageEnergyPrice && <Box>{errors.averageEnergyPrice.message}</Box>}
              />
              <Typography color="silver" variant="caption">
                Average all-in energy price at self-operated sites
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <Autocomplete
                  id="primaryEnergySource"
                  options={ENERGY_MIX}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(first: (typeof ENERGY_MIX)[0], second: (typeof ENERGY_MIX)[0]) => {
                    return first.value === second.value;
                  }}
                  renderOption={(props, option) => {
                    return (
                      <Box component="li" {...props}>
                        {option.value}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="[Optional] Primary energy source"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      {...register("primaryEnergySource")}
                      error={!!errors.primaryEnergySource}
                      helperText={<>{errors.primaryEnergySource && <Box>{errors.primaryEnergySource.message}</Box>}</>}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box mt={4}>
            <Box display="flex" color={"black"} alignItems={"center"}>
              Desired liquidity agreement duration
            </Box>
            <Box pl={5} pr={5}>
              <Slider
                onChange={(evt: Event, value: number | number[]) =>
                  setValue("timeHorizon", typeof value === "number" ? value : 0)
                }
                size="medium"
                defaultValue={0}
                step={1}
                max={MINER_GRANULAR_TIME_HORIZON_OF_LIQUIDITY.length - 1}
                marks={MINER_GRANULAR_TIME_HORIZON_OF_LIQUIDITY}
                valueLabelDisplay="off"
              />
            </Box>
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box mt={2} gap={2} display="flex" alignItems={"center"} justifyContent={"flex-end"}>
            <Box>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                onChange={() => setValue("captcha", "valid")}
                onErrored={() => setValue("captcha", undefined)}
                onExpired={() => setValue("captcha", undefined)}
              />
              {!!errors.captcha && !watch("captcha") && (
                <Typography variant="caption" color="red">
                  {errors.captcha?.message}
                </Typography>
              )}
            </Box>
            <Box>
              <Button type="submit" disabled={isLoading} variant="contained" sx={{ height: 40 }}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};
