import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Decimal from "decimal.js";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { ERROR_DEFAULT_MESSAGE, OPPORTUNITY_DETAILS_ROUTE, SATOSHIS_TO_BTC } from "../../app/constants";
import { isCustomError } from "../../app/helper/errors";
import { amountFormat, copyToClipboard, downloadFile, getTransactionStatusClass } from "../../app/helper/utils";
import {
  AuthUserResponseDto,
  AuthUserResponseDtoUserTypeEnum,
  GetCsvDataParamsCsvTransactionTypeEnum,
  GetWalletOverviewHistoryTxsParamsSortEnum,
  GetWalletOverviewHistoryTxsParamsStatusEnum,
  GetWalletOverviewHistoryTxsParamsTxtypeEnum,
  Sort,
} from "../../app/model/api";
import { useMutationExportCsv } from "../../app/query/useMutationCsv";
import { useQueryGetWalletOverviewHistory } from "../../app/query/useQueryGetWallet";
import { ReactComponent as CopyIcon } from "../../assets/images/copy-icon.svg";
import { ReactComponent as DepositIcon } from "../../assets/images/deposit-dark-icon.svg";
import { ReactComponent as TxIcon } from "../../assets/images/txLink-icon.svg";
import { ReactComponent as WithDrawIcon } from "../../assets/images/withdraw-dark-icon.svg";
import Button from "../../components/atoms/Button";
import Loader from "../../components/atoms/Loader";
import SectionTitle from "../../components/atoms/SectionTitle";
import { TableAmountCell } from "../../components/atoms/TableAmountCell";
import Pagination from "../../components/molecules/Pagination";
import Hero from "../../components/organisms/Hero";
import Modal from "../../components/organisms/Modal";
import Table from "../../components/organisms/Table";
import "./style.scss";

export const Wallet = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);

  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [direction, setDirection] = useState<Sort>(Sort.Desc);
  const [status, setStatus] = useState<GetWalletOverviewHistoryTxsParamsStatusEnum | "All">("All");
  const [txtype, setTxtype] = useState<GetWalletOverviewHistoryTxsParamsTxtypeEnum | "All">("All");
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState<boolean>(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);

  const { data: dataWalletHistory, isFetching: isFetchingWalletHistory } = useQueryGetWalletOverviewHistory({
    offset: (page - 1) * perPage,
    limit: perPage,
    sort:
      direction === Sort.Desc
        ? GetWalletOverviewHistoryTxsParamsSortEnum.Desc
        : GetWalletOverviewHistoryTxsParamsSortEnum.Asc,
    status: status === "All" ? undefined : status,
    txtype: txtype === "All" ? undefined : txtype,
  });
  const { mutateAsync: exportCsv } = useMutationExportCsv();

  const handleCopy = (text: string) => {
    copyToClipboard(text);
    toast.success("Address copied to clipboard");
  };

  const handleSubmitFunding = () => {
    setIsReviewModalOpen(false);
    setIsTransactionModalOpen(true);
  };

  const handleDownloadCsv = async () => {
    try {
      const { url } = await exportCsv({ csvTransactionType: GetCsvDataParamsCsvTransactionTypeEnum.All });
      downloadFile(url);
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const paginationPages = dataWalletHistory?.count ? Math.ceil(dataWalletHistory?.count / perPage) : 0;
  const transactionTypes =
    user?.data?.userType === AuthUserResponseDtoUserTypeEnum.Miner ? TRANSACTION_TYPES_MINER : TRANSACTION_TYPES_LP;

  return (
    <div>
      <Hero />
      <div>
        <SectionTitle title="Recent Transactions" />

        <div className="container-custom mx-auto !pt-14 !pb-16">
          <Box display="flex" alignItems={"center"} gap={1}>
            <FormControl sx={{ width: 250 }}>
              <InputLabel id="select-type">Transaction type</InputLabel>
              <Select
                labelId="select-type"
                label={"Transaction type"}
                id="select-type"
                value={txtype}
                size="small"
                onChange={(newValue) => {
                  if (newValue.target.value === "All") setTxtype("All");
                  else {
                    setTxtype(newValue.target.value as GetWalletOverviewHistoryTxsParamsTxtypeEnum);
                  }

                  setPage(1);
                }}
              >
                {transactionTypes.map((action) => (
                  <MenuItem key={"key-select-type" + action.value} value={action.value}>
                    {action.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 250 }}>
              <InputLabel id="select-status">Status</InputLabel>
              <Select
                labelId="select-status"
                label={"Status"}
                id="select-status"
                value={status}
                size="small"
                onChange={(newValue) => {
                  if (newValue.target.value === "All") setStatus("All");
                  else {
                    setStatus(newValue.target.value as GetWalletOverviewHistoryTxsParamsStatusEnum);
                  }

                  setPage(1);
                }}
              >
                {WALLET_HISTORY_STATUSES.map((action) => (
                  <MenuItem key={"key-select-status" + action.value} value={action.value}>
                    {action.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <div>
            <Table onExportCsv={() => handleDownloadCsv()}>
              <Table.Head>
                <Table.TR>
                  <Table.TH>Opportunity</Table.TH>
                  <Table.TH>Status</Table.TH>
                  <Table.TH>Type</Table.TH>
                  <Table.TH onSort={(direction) => setDirection(direction)}>Date & Time</Table.TH>
                  <Table.TH className="!text-end">Amount</Table.TH>
                  <Table.TH className="!text-end">TX Link</Table.TH>
                </Table.TR>
              </Table.Head>
              <Table.Body>
                {dataWalletHistory?.rows &&
                  !isFetchingWalletHistory &&
                  dataWalletHistory.rows.map((item, key: number) => {
                    return (
                      <Table.TR key={key}>
                        <Table.TD>
                          {item.transactionType === "Withdrawal" && <WithDrawIcon />}
                          {item.transactionType === "Deposit" && <DepositIcon />}
                          {!["Withdrawal", "Deposit"].includes(item.transactionType) && (
                            <Link
                              className="text-light-purple"
                              to={OPPORTUNITY_DETAILS_ROUTE.replace(":id", String(item.opportunityId))}
                            >
                              {item?.idNum ? `#${item?.idNum}` : ""}
                            </Link>
                          )}
                        </Table.TD>
                        <Table.TD className="w-[207px]">
                          <div className={`status-box ${getTransactionStatusClass(item.status)}`}>{item.status}</div>
                        </Table.TD>
                        <Table.TD className="w-[230px]">
                          <div className="history-type text-dark">{item.transactionType}</div>
                        </Table.TD>
                        <Table.TD className="w-[250px]">
                          <div className="flex flex-col items-center">
                            <span className="date-value text-dark">
                              {item.datetime && moment(item.datetime).format("LL")}
                            </span>
                            <span className="time-value text-[13px] text-[#6d7282] tracking-wide">
                              {item.datetime && moment(item.datetime).format("LT")}
                            </span>
                          </div>
                        </Table.TD>
                        <Table.TD className="!text-end w-[252px]">
                          <TableAmountCell
                            type={item.transactionType}
                            amountBtc={new Decimal(item.amountBtc).div(SATOSHIS_TO_BTC).toNumber()}
                            amountUsd={item.amountUsd}
                          />
                        </Table.TD>
                        <Table.TD className="text-end">
                          <Link to={item.txLink} target="_blank">
                            <TxIcon className="w-5" />
                          </Link>
                        </Table.TD>
                      </Table.TR>
                    );
                  })}

                {dataWalletHistory?.rows &&
                  !isFetchingWalletHistory &&
                  dataWalletHistory.rows.length === 0 &&
                  !isFetchingWalletHistory && (
                    <Table.TR>
                      <Table.TD colSpan={6} className="relative text-center !h-[150px]">
                        No results.
                      </Table.TD>
                    </Table.TR>
                  )}

                {isFetchingWalletHistory && (
                  <Table.Loader>
                    <Table.TD colSpan={6} className="relative !h-[865px]">
                      <Loader />
                    </Table.TD>
                  </Table.Loader>
                )}
              </Table.Body>
            </Table>
            {paginationPages > 1 && (
              <Pagination
                page={page}
                paginationPages={paginationPages}
                onPrev={() => setPage((prev: number) => prev - 1)}
                onNext={() => setPage((prev: number) => prev + 1)}
              />
            )}
          </div>
        </div>
      </div>

      {isTransactionModalOpen ? (
        <Modal open={isTransactionModalOpen} onClose={() => setIsTransactionModalOpen(false)}>
          <div className="px-1 py-6">
            <div className="text-center mb-8">
              <h1 className="text-dark text-[33px] font-pp-neue-machina font-semibold leading-[120%]">
                Transaction Successful
              </h1>
            </div>
            <div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  Status
                </div>
                <div className="text-dark text-[14px]">Confirmed</div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  Date & Time
                </div>
                <div className="text-dark text-[14px]">17 Jan 2022 | 02:00 PM</div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  From
                </div>
                <div className="text-dark text-[14px]">bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  To
                </div>
                <div className="text-dark text-[14px]">bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  Deposit Amount
                </div>
                <div className="text-dark text-[14px]">1.2 BTC ($19363,44)</div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  Bitcoin Network Fee
                </div>
                <div className="text-dark text-[14px]">
                  {amountFormat(0.0012, "BTC")} BTC ({amountFormat(19)})
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  Tx Hash
                </div>
                <div className="text-dark text-[14px] flex gap-2 items-center">
                  <span>91bf15fdfcdd8f2d3b0e5e...ae8d38c752024f0802cee</span>
                  <button
                    className="bg-gray-100 px-2 py-1"
                    onClick={() => handleCopy("91bf15fdfcdd8f2d3b0e5e...ae8d38c752024f0802cee")}
                  >
                    <CopyIcon className="w-4" />
                  </button>
                  <button className="bg-gray-100 px-2 py-1" onClick={() => alert("link")}>
                    <TxIcon className="w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}

      {isReviewModalOpen ? (
        <Modal open={isReviewModalOpen} onClose={() => setIsReviewModalOpen(false)}>
          <div className="px-1 py-6">
            <div className="text-center mb-8">
              <h1 className="text-dark text-[33px] font-pp-neue-machina font-semibold leading-[120%]">
                Review and Confirm Your Transaction
              </h1>
            </div>
            <div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  Date & Time
                </div>
                <div className="text-dark text-[14px]">17 Jan 2022 | 02:00 PM</div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  From
                </div>
                <div className="text-dark text-[14px]">bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  To
                </div>
                <div className="text-dark text-[14px]">bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  Deposit Amount
                </div>
                <div className="text-dark text-[14px]">
                  {amountFormat(1.2, "BTC")} BTC ({amountFormat(19363.44)})
                </div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
                  Bitcoin Network Fee
                </div>
                <div className="text-dark text-[14px]">
                  {amountFormat(0.0012, "BTC")} BTC ({amountFormat(19)})
                </div>
              </div>
            </div>
            <div className="flex gap-2 mt-10">
              <Button
                label="Cancel"
                backgroundColor="#F9F5FF"
                color="#8F49FD"
                className="py-[8px] text-[14px] font-inter font-medium"
                onClick={() => setIsReviewModalOpen(false)}
              />
              <Button
                label="Initiate Funding"
                backgroundColor="#8F49FD"
                color="#ffffff"
                className="py-[8px] text-[14px] font-inter font-medium"
                onClick={() => handleSubmitFunding()}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

const WALLET_HISTORY_STATUSES = [
  {
    value: "All",
    label: "All",
  },
  {
    value: GetWalletOverviewHistoryTxsParamsStatusEnum.Pending,
    label: "Pending",
  },
  {
    value: GetWalletOverviewHistoryTxsParamsStatusEnum.Confirmed,
    label: "Confirmed",
  },
  {
    value: GetWalletOverviewHistoryTxsParamsStatusEnum.AwaitingApproval,
    label: "Awaiting approval",
  },
];

type TransactionType =
  | "Reward Distribution"
  | "Opportunity Funding"
  | "Liquidity Refund"
  | "Collateral Release"
  | "Collateral Deposit"
  | "Reward Delivery"
  | "Liquidity Delivery"
  | "Excess Reward Release"
  | "Collateral Liquidation"
  | "Deposit"
  | "Reconciliation"
  | "Withdrawal";

const TRANSACTION_TYPES_MINER: { value: TransactionType | "All"; label: TransactionType | "All" }[] = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Withdrawal",
    label: "Withdrawal",
  },
  {
    value: "Deposit",
    label: "Deposit",
  },
  {
    value: "Collateral Release",
    label: "Collateral Release",
  },
  {
    value: "Collateral Deposit",
    label: "Collateral Deposit",
  },

  {
    value: "Reward Delivery",
    label: "Reward Delivery",
  },
  {
    value: "Liquidity Delivery",
    label: "Liquidity Delivery",
  },
  {
    value: "Reward Distribution",
    label: "Reward Distribution",
  },
  {
    value: "Opportunity Funding",
    label: "Opportunity Funding",
  },
  {
    value: "Liquidity Refund",
    label: "Liquidity Refund",
  },
];

const TRANSACTION_TYPES_LP: { value: TransactionType | "All"; label: TransactionType | "All" }[] = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Withdrawal",
    label: "Withdrawal",
  },
  {
    value: "Deposit",
    label: "Deposit",
  },
  {
    value: "Reward Distribution",
    label: "Reward Distribution",
  },
  {
    value: "Opportunity Funding",
    label: "Opportunity Funding",
  },
  {
    value: "Liquidity Refund",
    label: "Liquidity Refund",
  },
];
