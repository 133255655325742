import {
  Alert,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MiningPoolCredentialsDto } from "../../../app/model/api";
import { useSetupMiningProxy } from "../../../app/query/useMutationMiner";

type Props = {
  onSuccess: () => void;
  onCancel: false | (() => void);
  initialValues?: MiningPoolCredentialsDto;
};

export const PoolConfigurationForm: React.FC<Props> = ({ onSuccess, onCancel, initialValues }) => {
  const [error, setError] = useState<string>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MiningPoolCredentialsDto>({
    defaultValues: initialValues,
  });

  const { mutateAsync: setupMiningProxy } = useSetupMiningProxy();

  const onSubmit = async (data: MiningPoolCredentialsDto) => {
    try {
      await setupMiningProxy(data);
      onSuccess();
    } catch (err) {
      setError("Failed to setup mining proxy. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <TextField
        fullWidth
        label="Pool URL"
        {...register("poolUrl", { required: "Pool URL is required" })}
        error={!!errors.poolUrl}
        helperText={errors.poolUrl?.message}
        placeholder="stratum+tcp://pool.example.com:3333"
        sx={{ mb: 3 }}
      />
      <TextField
        fullWidth
        label="Username"
        variant="outlined"
        {...register("username", { required: "Username is required" })}
        error={!!errors.username}
        helperText={errors.username?.message}
        sx={{ mb: 3 }}
      />
      <TextField
        fullWidth
        label="Password"
        variant="outlined"
        {...register("password", { required: "Password is required" })}
        error={!!errors.password}
        helperText={errors.password?.message}
        sx={{ mb: 3 }}
      />
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel>Worker ID Mode</InputLabel>
        <Select label="Worker ID Mode" {...register("workerIdMode")} defaultValue="none">
          <MenuItem value="passthrough">Passthrough</MenuItem>
          <MenuItem value="username">Username</MenuItem>
          <MenuItem value="none">None</MenuItem>
        </Select>
        <FormHelperText>
          Passthrough: miner's worker ID is used as the worker ID to the pool
          <br />
          Username: miner's username is used as the worker ID to the pool
          <br />
          None: No worker ID used
        </FormHelperText>
      </FormControl>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Button variant="contained" type="submit">
          {initialValues ? "Update Configuration" : "Save Configuration"}
        </Button>
        {onCancel && (
          <Button onClick={onCancel} variant="outlined">
            Cancel
          </Button>
        )}
      </Stack>
    </form>
  );
};
