import { useQuery } from "@tanstack/react-query";
import { apig, getToken } from "../config/query/clientgen";
import { AuthUserResponseDto, OpportunityStatus, Sort, YesOrNoChoice } from "../model/api";

const fetchUser = async (): Promise<AuthUserResponseDto | null> => {
  const token = getToken();

  if (token) {
    return (await apig.user.getUser()).data;
  }

  return null;
};
interface CommonProps {
  offset?: number;
  limit?: number;
  actionFirst?: YesOrNoChoice;
}

interface OpportunityListProps extends CommonProps {
  status?: OpportunityStatus;
  hideSettled?: YesOrNoChoice;
  activeSince: Sort;
  settlement: Sort;
}

const fetchWhitelist = async (params: Parameters<typeof apig.user.getUserWhitelistedAddress>[0]) => {
  return (await apig.user.getUserWhitelistedAddress(params)).data;
};

const getWhitelistProposal = async (params: Parameters<typeof apig.user.getWhitelistProposal>[0]) => {
  return (await apig.user.getWhitelistProposal(params)).data;
};

const fetchAllMiners = async () => {
  return (await apig.user.getUserMiners()).data;
};

const getMinerOpportunitiesSummary = async () => {
  return (await apig.miner.getMinerOpportunitiesSummary()).data;
};

const getMinerOpportunities = async (params: OpportunityListProps) => {
  return (await apig.miner.getMinerOpportunities(params)).data;
};

const getUserNotifications = async () => {
  return (await apig.notification.getUserNotifications()).data;
};

const useQueryGetUser = () => {
  return useQuery(["user"], () => fetchUser());
};

const useQueryGetUserOnMount = () => {
  return useQuery([], () => fetchUser(), { refetchOnMount: "always" });
};

const useQueryGetWhitelist = (params: Parameters<typeof apig.user.getUserWhitelistedAddress>[0]) => {
  return useQuery(["whitelist", params], () => fetchWhitelist(params), { refetchOnMount: "always" });
};

const useQueryGetMiners = () => {
  return useQuery(["miners"], () => fetchAllMiners(), { refetchOnMount: "always" });
};

const useQueryGetMinerOpportunities = (params: OpportunityListProps) => {
  return useQuery(["minersOpportunities", params], () => getMinerOpportunities(params), { refetchOnMount: "always" });
};

const useQueryGetMinerOpportunitiesSummary = () => {
  return useQuery(["minersOpportunitiesSummary"], () => getMinerOpportunitiesSummary(), { refetchOnMount: "always" });
};

const useQueryGetUserNotifications = () => {
  return useQuery(["notifications"], () => getUserNotifications(), { refetchInterval: 20000 });
};

const useQueryWhiteListProposal = (
  params: Parameters<typeof apig.user.getWhitelistProposal>[0],
  isEnabled: boolean
) => {
  return useQuery(["whitelist_proposal", params], () => getWhitelistProposal(params), {
    refetchOnMount: "always",
    enabled: isEnabled,
  });
};

export {
  useQueryGetMinerOpportunities,
  useQueryGetMinerOpportunitiesSummary,
  useQueryGetMiners,
  useQueryGetUser,
  useQueryGetUserNotifications,
  useQueryGetUserOnMount,
  useQueryGetWhitelist,
  useQueryWhiteListProposal,
};
