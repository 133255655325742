import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { CreateProposalDto, InviteOrganizationUserDto, RejectOrAcceptProposalDto } from "../model/api";

const inviteOrganizationUser = async (data: InviteOrganizationUserDto) => {
  return await apig.user.inviteOrganizationUser(data);
};

const removeOrganizationUser = async (id: string) => {
  return await apig.user.removeOrganizationUser({ id });
};

const rejectOrApproveProposal = async (data: RejectOrAcceptProposalDto) => {
  return await apig.user.rejectOrApproveProposal(data);
};

const createProposal = async (data: CreateProposalDto) => {
  return await apig.user.proposeNewRole(data);
};

const removeRole = async (data: Parameters<typeof apig.user.removeRole>[0]) => {
  return await apig.user.removeRole(data);
};

const useMutationInviteOrganizationUser = () => {
  return useMutation((data: InviteOrganizationUserDto) => inviteOrganizationUser(data));
};

const useMutationRemoveOrganizationUser = () => {
  return useMutation((id: string) => removeOrganizationUser(id));
};

const useMutationRejectOrApproveProposal = () => {
  return useMutation((data: RejectOrAcceptProposalDto) => rejectOrApproveProposal(data));
};

const useMutationCreateProposal = () => {
  return useMutation((data: CreateProposalDto) => createProposal(data));
};

const useMutationRemoveRole = () => {
  return useMutation((data: Parameters<typeof apig.user.removeRole>[0]) => removeRole(data));
};

export {
  useMutationCreateProposal,
  useMutationInviteOrganizationUser,
  useMutationRejectOrApproveProposal,
  useMutationRemoveOrganizationUser,
  useMutationRemoveRole,
};
