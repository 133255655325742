import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";

const getLpPortfolioChart = async (params: Parameters<typeof apig.lp.getLpPortfolioChart>[0]) => {
  return (await apig.lp.getLpPortfolioChart(params)).data;
};

const getLpOpportunityList = async (params: Parameters<typeof apig.lp.getLpOpprotunities>[0]) => {
  return (await apig.lp.getLpOpprotunities(params)).data;
};

const getLpOpportunitiesFunded = async (params: Parameters<typeof apig.lp.getLpOpprotunitiesFunded>[0]) => {
  return (await apig.lp.getLpOpprotunitiesFunded(params)).data;
};

const getOpportunityFunding = async (opportunityId: string) => {
  return (await apig.opportunity.getOpportunityFunding(opportunityId)).data;
};

const getLpOpportunity = async (opportunityId: string) => {
  return (await apig.lp.getLpOpprotunity(opportunityId)).data;
};

const getLpPreviewOpportunity = async (previewOpportunityId: string) => {
  return (await apig.lp.getLpPreviewStreamingOpportunity(previewOpportunityId)).data;
};

const getLpPreviewLoan = async (previewLoanId: string) => {
  return (await apig.lp.getLpPreviewLoan(previewLoanId)).data;
};

const getLpPreviewLease = async (previewLeaseId: string) => {
  return (await apig.lp.getLpPreviewLease(previewLeaseId)).data;
};

const getLiquidityProvidersV2 = async (
  opportunityId: string,
  query: {
    offset?: number;
    limit?: number;
  }
) => {
  return (await apig.opportunity.getLiquidityProvidersV2(opportunityId, query)).data;
};

const getPortfolioSummary = async () => {
  return (await apig.lp.getPortfolioSummary()).data;
};

const getOpportunitiesSummary = async () => {
  return (await apig.lp.getOpportunitiesSummary()).data;
};

export const useQueryGetLPPortfolioChart = (params: Parameters<typeof apig.lp.getLpPortfolioChart>[0]) => {
  return useQuery(["portfolio_chart", params], () => getLpPortfolioChart(params), { refetchOnMount: "always" });
};

export const useQueryGetLpOpportunityList = (params: Parameters<typeof apig.lp.getLpOpprotunities>[0]) => {
  return useQuery(["opportunity_list", params], () => getLpOpportunityList(params), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

export const useQuerygetLpOpportunitiesFunded = (params: Parameters<typeof apig.lp.getLpOpprotunitiesFunded>[0]) => {
  return useQuery(["opportunity_funded", params], () => getLpOpportunitiesFunded(params), { cacheTime: 0 });
};

export const useGetQueryOpportunityFunding = (opportunityId: string, isEnabled: boolean) => {
  return useQuery(["initiate_opportunity", opportunityId], () => getOpportunityFunding(opportunityId), {
    enabled: isEnabled,
  });
};

export const useGetQueryStreamingOpportunity = (opportunityId: string) => {
  return useQuery(["lp_opportunity", opportunityId], () => getLpOpportunity(opportunityId));
};

export const useGetQueryPreviewStreamingOpportunity = (previewOpportunityId: string) => {
  return useQuery(["lp_preview_opportunity", previewOpportunityId], () =>
    getLpPreviewOpportunity(previewOpportunityId)
  );
};

export const useGetQueryPreviewLoan = (previewLoanId: string) => {
  return useQuery(["lp_preview_loan", previewLoanId], () => getLpPreviewLoan(previewLoanId));
};

export const useGetQueryPreviewLease = (previewLeaseId: string) => {
  return useQuery(["lp_preview_lease", previewLeaseId], () => getLpPreviewLease(previewLeaseId));
};

export const useGetQueryOpportunityLiquidityProvidersV2 = (
  opportunityId: string,
  query: {
    offset?: number;
    limit?: number;
  }
) => {
  return useQuery(["liquidity_providers_v2", opportunityId], () => getLiquidityProvidersV2(opportunityId, query), {
    refetchOnMount: "always",
    cacheTime: 0,
    enabled: !!opportunityId,
  });
};

export const useGetQueryPortfolioSummary = () => {
  return useQuery(["portfollio_summary"], () => getPortfolioSummary(), {
    refetchOnMount: "always",
  });
};

export const useGetQueryOpportunitiesSummary = () => {
  return useQuery(["opportunities_lp_summary"], () => getOpportunitiesSummary(), {
    refetchOnMount: "always",
  });
};
