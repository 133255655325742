import { useState } from "react";
import toast from "react-hot-toast";

import { ERROR_DEFAULT_MESSAGE, OPPORTUNITY_DETAILS_ROUTE } from "../../app/constants";
import { amountFormatSatoshisToBTC, roundToSignificantDigits } from "../../app/helper/utils";
import { LiquidityDelivery, LiquidityDeliveryRequestDto } from "../../app/model/api";
import { useMutationLiquidityDelivery } from "../../app/query/useMutationAdmin";
import { useGetTrancheDelivery as useGetLiquidityDelivery } from "../../app/query/useQueryGetAdmin";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { Box, IconButton, Link, Skeleton, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import Decimal from "decimal.js";
import { v4 } from "uuid";
import { isCustomError } from "../../app/helper/errors";

type RowPagination = {
  readonly page: number;
  readonly pageSize: number;
};

export const LiquidityDeliveryPage = () => {
  const [paginationModel, setPaginationModel] = useState<RowPagination>({
    page: 0,
    pageSize: 25,
  });

  const { data, isFetching, refetch } = useGetLiquidityDelivery({
    offset: paginationModel.pageSize * paginationModel.page,
    limit: paginationModel.pageSize,
  });
  const { mutateAsync, isLoading } = useMutationLiquidityDelivery();

  const handleAction = async (payload: LiquidityDeliveryRequestDto) => {
    try {
      await mutateAsync(payload);
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
    refetch();
  };

  const columns: GridColDef<LiquidityDelivery>[] = [
    {
      field: "opportunityIdNum",
      headerName: "Opportunity",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      display: "flex",
      renderCell: (params) => {
        return (
          <Link color="primary" href={OPPORTUNITY_DETAILS_ROUTE.replace(":id", params.row.opportunityId)}>
            #{params.row.opportunityIdNum}
          </Link>
        );
      },
    },
    {
      field: "minerCompanyName",
      headerName: "Miner",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: "flex",
    },
    {
      field: "requiredHashratePh",
      headerName: "Required hashrate",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: "flex",
      renderCell: (params) => {
        const requiredHashratePh = new Decimal(params.row.requiredHashrateTh || 0).div(1000).toNumber();
        return `${roundToSignificantDigits(requiredHashratePh, 1)} PH/s`;
      },
    },
    {
      field: "newRequiredCollateralSatoshis",
      headerName: "Required collateral",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: "flex",
      renderCell: (params) => `${amountFormatSatoshisToBTC(params.row.newRequiredCollateralSatoshis)} BTC`,
    },
    {
      field: "collateralBalance",
      headerName: "Collateral balance",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: "flex",
      renderCell: (params) => `${amountFormatSatoshisToBTC(params.row.collateralBalanceSatoshis)} BTC`,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      width: 200,
      display: "flex",
      renderCell: (params) => {
        return (
          <Box maxWidth={"100%"} display="flex" justifyContent={"flex-end"} alignItems={"center"} gap={1} pt={1.5}>
            <IconButton
              color="primary"
              size="small"
              sx={{ padding: 0 }}
              disabled={isFetching || isLoading || params.row.isSufficientHashrateAllocated}
              onClick={() =>
                handleAction({
                  opportunityId: params.row.opportunityId,
                  isSufficientHashrateAllocated: true,
                })
              }
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              color="error"
              size="small"
              sx={{ padding: 0 }}
              disabled={isFetching || isLoading || !params.row.isSufficientHashrateAllocated}
              onClick={() =>
                handleAction({
                  opportunityId: params.row.opportunityId,
                  isSufficientHashrateAllocated: false,
                })
              }
            >
              <CancelIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const handlePaginationChange = (input: RowPagination) => {
    if (input.pageSize !== paginationModel.pageSize) {
      setPaginationModel({
        page: 0,
        pageSize: input.pageSize,
      });
    } else if (input.page !== paginationModel.page) {
      setPaginationModel(input);
    }
  };

  const rows = (data?.rows ?? []).map((row) => ({ id: row.opportunityId + v4(), ...row }));

  return (
    <Stack alignItems={"center"} width={"100%"}>
      <Stack sx={{ background: "white", width: "100%", maxWidth: 1600, padding: 3 }} spacing={2}>
        <Box pl={5} pr={4}>
          <Typography variant="body1" color="text.secondary" textAlign={"justify"}>
            The following opportunities are awaiting mining reward redirection. Please confirm with the miner that
            sufficient rewards are allocated, then press the check mark. Note that liquidity will not be delivered until
            we also have sufficient collateral.
          </Typography>
        </Box>

        {isFetching ? (
          <Stack alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100%"} spacing={1}>
            <Skeleton width="100%" height={50} variant="rectangular" />
            <Skeleton width="100%" height={50} variant="rectangular" />
            <Skeleton width="100%" height={50} variant="rectangular" />
          </Stack>
        ) : null}

        {!data?.rows.length && !isFetching && (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={"100%"}
            sx={{ minHeight: "60px" }}
          >
            <Typography variant="h4" color="text.secondary">
              No entries found.
            </Typography>
          </Stack>
        )}
        {!!rows.length && (
          <DataGridPro
            rows={rows}
            rowCount={data?.count ?? 0}
            columns={columns}
            loading={isFetching}
            pagination
            paginationMode="server"
            sortingMode="server"
            disableColumnFilter={true}
            pageSizeOptions={[25, 50, 100]}
            paginationModel={paginationModel}
            initialState={{
              pagination: { paginationModel },
            }}
            onPaginationModelChange={(input) => {
              if (input.pageSize === 25 || input.pageSize === 50 || input.pageSize === 100)
                handlePaginationChange({
                  page: input.page,
                  pageSize: input.pageSize,
                });
            }}
            sx={{
              bgcolor: "white",
              boxShadow: 3,
              height: "65vh",
              padding: 1,
            }}
          ></DataGridPro>
        )}
      </Stack>
    </Stack>
  );
};
