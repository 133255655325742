import { useMutation, useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";

const getWalletOverviewHistoryTxs = async (params: Parameters<typeof apig.wallet.getWalletOverviewHistoryTxs>[0]) => {
  return (await apig.wallet.getWalletOverviewHistoryTxs(params)).data;
};

const getWalletDepositTxs = async (params: Parameters<typeof apig.wallet.getWalletDepositTxs>[0]) => {
  return (await apig.wallet.getWalletDepositTxs(params)).data;
};

const postWalletWithdrawPreview = async (params: Parameters<typeof apig.wallet.postWalletWithdrawPreview>[0]) => {
  return (await apig.wallet.postWalletWithdrawPreview(params)).data;
};

const postWalletWithdrawRequest = async (params: Parameters<typeof apig.wallet.postWalletWithdrawRequest>[0]) => {
  return (await apig.wallet.postWalletWithdrawRequest(params)).data;
};

const getFee = async () => {
  return (await apig.wallet.getFee()).data;
};

const getSatoshiToUsdPrice = async () => {
  return (await apig.wallet.getSatoshiToUsdPrice()).data;
};

const useQueryGetWalletOverviewHistory = (params: Parameters<typeof apig.wallet.getWalletOverviewHistoryTxs>[0]) => {
  return useQuery(["wallet_overview_history", params], () => getWalletOverviewHistoryTxs(params), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

const useQueryGetWalletDeposit = (params: Parameters<typeof apig.wallet.getWalletDepositTxs>[0]) => {
  return useQuery(["wallet_deposit", params], () => getWalletDepositTxs(params), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

const useMutationPostWalletWithdrawPreview = () => {
  return useMutation((data: Parameters<typeof apig.wallet.postWalletWithdrawPreview>[0]) =>
    postWalletWithdrawPreview(data)
  );
};

const useMutationPostWalletWithdrawRequest = () => {
  return useMutation((data: Parameters<typeof apig.wallet.postWalletWithdrawRequest>[0]) =>
    postWalletWithdrawRequest(data)
  );
};

const useGetQueryWalletFee = () => {
  return useQuery(["wallet_fee"], () => getFee());
};

const useGetQuerySatoshiPriceInUsd = () => {
  return useQuery(["price_satoshi_usd"], () => getSatoshiToUsdPrice());
};

export {
  useGetQuerySatoshiPriceInUsd,
  useGetQueryWalletFee,
  useMutationPostWalletWithdrawPreview,
  useMutationPostWalletWithdrawRequest,
  useQueryGetWalletDeposit,
  useQueryGetWalletOverviewHistory,
};
