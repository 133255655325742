import { useCallback } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { Box, Divider, Grid, Button as MuiButton, Typography } from "@mui/material";
import Button from "../../components/atoms/Button";
import SectionTitle from "../../components/atoms/SectionTitle";
import OrganizationEnterpriseSettings from "../../components/organisms/OrganizationEnterpriseSettings";

import {
  APPROVE_USER_MESSAGE,
  DURATION_MAPPER,
  ERROR_DEFAULT_MESSAGE,
  GRANULAR_LP_RESPONSE_DTO_LIQUIDITY_HORIZON,
  REJECT_USER_MESSAGE,
} from "../../app/constants";
import { amountFormat, getOnboardingStatusAdminPanelStatusClass } from "../../app/helper/utils";
import { useMutationAdminApproveOrRejectUser } from "../../app/query/useMutationAdmin";

import { ReactComponent as CheckIcon } from "../../assets/images/check-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";

import Decimal from "decimal.js";
import { BasicInfoDtoOnboardingStatusEnum } from "../../app/model/api";
import { useQueryGetLpById } from "../../app/query/useQueryGetAdmin";
import "./style.scss";

export const LpOrganizationDetails = () => {
  const { id } = useParams();

  if (!id) return <></>;
  else return <LpOrganizationDetailsInner id={id} />;
};

const LpOrganizationDetailsInner = ({ id }: { id: string }) => {
  const { data: lp, refetch, isFetching } = useQueryGetLpById(id);
  const { mutateAsync, isLoading } = useMutationAdminApproveOrRejectUser();

  const handleAction = useCallback(
    async (type: string) => {
      if (lp) {
        try {
          await mutateAsync({ userId: String(lp.basicInfo.id), approved: type === "approve" });
          refetch();
          toast.success(type === "approve" ? APPROVE_USER_MESSAGE : REJECT_USER_MESSAGE);
        } catch (error) {
          toast.error(ERROR_DEFAULT_MESSAGE);
        }
      }
    },
    [lp, mutateAsync, refetch]
  );

  const isDisabled = !(
    lp?.basicInfo.onboardingStatus === BasicInfoDtoOnboardingStatusEnum.EntrypointPending ||
    lp?.basicInfo.onboardingStatus === BasicInfoDtoOnboardingStatusEnum.KYCPending ||
    lp?.basicInfo.onboardingStatus === BasicInfoDtoOnboardingStatusEnum.KYMPending
  );

  return (
    <>
      <div className="user-profile-wrapper">
        <div className="container-custom mx-auto !pb-16">
          <SectionTitle title={"Liquidity provider"}>
            <div className="ml-auto flex gap-12">
              <div>
                <div
                  className={`status-box w-[200px] text-white ${
                    lp?.basicInfo?.onboardingStatusAdminPanel
                      ? getOnboardingStatusAdminPanelStatusClass(lp?.basicInfo?.onboardingStatusAdminPanel)
                      : undefined
                  }`}
                >
                  {lp?.basicInfo?.onboardingStatusAdminPanel}
                </div>
              </div>
              <div className="flex gap-2">
                <div className="inline-block w-auto">
                  <Button
                    notAllowed={isDisabled}
                    disabled={isFetching || isLoading}
                    backgroundColor="#8f49fd"
                    className="w-[36px] p-0 text-white"
                    label=""
                    icon={<CheckIcon />}
                    onClick={() => handleAction("approve")}
                  />
                </div>
                <div className="inline-block w-auto">
                  <Button
                    notAllowed={isDisabled}
                    disabled={isFetching || isLoading}
                    backgroundColor="#8f49fd"
                    className="w-[36px] p-0 text-white"
                    label=""
                    icon={<CloseIcon />}
                    onClick={() => handleAction("reject")}
                  />
                </div>
              </div>
            </div>
          </SectionTitle>
          <div className="p-7 bg-white">
            <div className="grid grid-cols-2 mb-8">
              <div>
                <Typography variant="subtitle1">Company name</Typography>
                <Typography variant="body1">{lp?.basicInfo?.company || "-"}</Typography>
              </div>
            </div>
            <div className="grid grid-cols-2 mb-8">
              <div>
                <Typography variant="subtitle1">Homepage url</Typography>
                <h4 className="text-base">
                  {lp?.basicInfo?.homepageUrl ? (
                    <MuiButton
                      target="_blank"
                      href={
                        lp.basicInfo.homepageUrl.startsWith("http")
                          ? lp.basicInfo.homepageUrl
                          : "https://" + lp.basicInfo.homepageUrl
                      }
                      variant="text"
                      sx={{ height: 20, padding: 0, "&:hover": { textDecoration: "underline" } }}
                    >
                      {lp?.basicInfo?.homepageUrl}
                    </MuiButton>
                  ) : (
                    "-"
                  )}
                </h4>
              </div>
              <div>
                <Typography variant="subtitle1">Location</Typography>
                <Typography variant="body1">{lp?.basicInfo?.location || "-"}</Typography>
              </div>
            </div>
            <div className="grid grid-cols-2 mb-8">
              <div>
                <Typography variant="subtitle1">Onboarding user</Typography>
                <h4 className="text-base">
                  {lp?.basicInfo.onboardingUser.firstName + " " + lp?.basicInfo.onboardingUser.lastName}
                </h4>
                <h4 className="text-base">{lp?.basicInfo.onboardingUser.email}</h4>
                <h4 className="text-base">{lp?.basicInfo.onboardingUser.cellphone}</h4>
              </div>
              <div>
                <Typography variant="subtitle1">Primary contact</Typography>
                <h4 className="text-base">
                  {lp?.basicInfo?.primaryContact?.primaryContactKind === "notsure"
                    ? "Not sure"
                    : lp?.basicInfo?.primaryContact?.primaryContactKind === "signup-user"
                    ? "Same as onboarding user"
                    : ""}
                </h4>
              </div>
            </div>
            <div className="grid grid-cols-2 mb-8">
              <div>
                <Typography variant="subtitle1">Does primary contact have signatory power</Typography>
                <h4 className="text-base">
                  {lp?.basicInfo?.primaryContact?.hasPowerOfAttorney === "yes"
                    ? "Yes"
                    : lp?.basicInfo?.primaryContact?.hasPowerOfAttorney === "no"
                    ? "No"
                    : "Not sure"}
                </h4>
              </div>
            </div>

            <div className="grid grid-cols-2 mb-8">
              <div>
                <Typography variant="subtitle1">Assets Under Management</Typography>
                <Typography variant="body1">
                  {lp?.lpInfo.AUMMillionUSD
                    ? `${amountFormat(new Decimal(lp?.lpInfo.AUMMillionUSD || 0).mul(1e6).toNumber())}`
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle1">Portion of Bitcoin assets in AUM</Typography>
                <Typography variant="body1">
                  {lp?.lpInfo.portionOfBitcoinAssets ? `${lp?.lpInfo.portionOfBitcoinAssets}%` : "-"}
                </Typography>
              </div>
            </div>
            <div className="grid grid-cols-2 mb-8">
              <div>
                <Typography variant="subtitle1">Projected allocation on the protocol</Typography>
                <Typography variant="body1">{lp?.lpInfo.projectedAllocation || "-"}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1">Time horizon of liquidity</Typography>
                <Typography variant="body1">
                  {DURATION_MAPPER[lp?.lpInfo.timeHorizonOfLiquidity as keyof typeof DURATION_MAPPER] || "-"}
                </Typography>
              </div>
            </div>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              Onboarding 2.0 Fields
            </Divider>

            <Grid container>
              <Grid item xs={12} md={6} mb={2}>
                <Box>
                  <Typography variant="subtitle1">Is publicly listed?</Typography>
                  <Typography variant="body1">
                    {lp?.basicInfo.isPubliclyListed == undefined
                      ? "N/A"
                      : lp?.basicInfo.isPubliclyListed
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} mb={2}>
                <Box>
                  <Typography variant="subtitle1">Annual report url</Typography>
                  {lp?.basicInfo?.annualReport ? (
                    <MuiButton
                      sx={{ height: 20, padding: 0, "&:hover": { textDecoration: "underline" } }}
                      target="_blank"
                      href={lp.basicInfo.annualReport}
                      variant="text"
                    >
                      {lp.basicInfo.annualReport}
                    </MuiButton>
                  ) : (
                    "-"
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} mb={2}>
                <Box>
                  <Typography variant="subtitle1">Bitcoin Assets in AuM (BTC)</Typography>
                  <Typography variant="body1">{lp?.lpInfo.bitcoinAum || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} mb={2}>
                <Box>
                  <Typography variant="subtitle1">Projected investment size (in BTC)</Typography>
                  <Typography variant="body1">{lp?.lpInfo.projectedBitcoinInvestment || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} mb={2}>
                <Grid item xs={12} md={6} mb={2}>
                  <Box>
                    <Typography variant="subtitle1">Time horizon of liquidity</Typography>
                    <Typography variant="body1">
                      {GRANULAR_LP_RESPONSE_DTO_LIQUIDITY_HORIZON.find((x) => x.id === lp?.lpInfo.liquidityHorizon)
                        ?.label ?? "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {lp?.walletDetails?.walletAddress &&
              lp?.walletDetails?.bitgoWalletUrl &&
              lp?.walletDetails.bitgoWalletId && (
                <>
                  <Divider textAlign="left" sx={{ mb: 2 }}>
                    Wallet info
                  </Divider>
                  <div className="grid grid-cols-2 mb-8 mt-4">
                    <div>
                      <Typography variant="subtitle1">BitGo wallet ID</Typography>
                      <MuiButton
                        sx={{ height: 20, padding: 0, "&:hover": { textDecoration: "underline" } }}
                        target="_blank"
                        href={lp.walletDetails.bitgoWalletUrl}
                        variant="text"
                      >
                        {lp.walletDetails.bitgoWalletId}
                      </MuiButton>
                    </div>
                    <div>
                      <Typography variant="subtitle1">Wallet deposit address</Typography>
                      <Typography variant="body1">{lp.walletDetails.walletAddress}</Typography>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>

      {lp?.basicInfo.organizationId ? (
        <OrganizationEnterpriseSettings organizationId={lp?.basicInfo.organizationId} />
      ) : null}
    </>
  );
};
