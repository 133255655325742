import { AdminBlocks } from "../../pages/Admin/Tooling/AdminBlocks";
import { AdminComputedHashprices } from "../../pages/Admin/Tooling/AdminComputedHashprices";
import { AdminUserManagement } from "../../pages/Admin/Tooling/AdminUserManagement";
import BroadcastManager from "../../pages/BroadcastManager";
import {
  BROADCAST_MANAGER_ROUTE,
  HOME_ROUTE,
  ROUTE_ADMIN_COMPUTED_HASHPRICES,
  ROUTE_ADMIN_REWARDS_BLOCKS,
  ROUTE_ADMIN_STREAMING_SYNTHETIC,
  ROUTE_ADMIN_USER_MANAGEMENT,
  ROUTES_LOANS,
} from "./routes";

export const ADMIN_TABS_TOOLING = [
  {
    name: "Blocks",
    path: ROUTE_ADMIN_REWARDS_BLOCKS,
    tabComponent: () => <AdminBlocks />,
  },
  {
    name: "Computed hashprices",
    path: ROUTE_ADMIN_COMPUTED_HASHPRICES,
    tabComponent: () => <AdminComputedHashprices />,
  },
  {
    name: "Admin users",
    path: ROUTE_ADMIN_USER_MANAGEMENT,
    tabComponent: () => <AdminUserManagement />,
  },
  {
    name: "Broadcast",
    path: BROADCAST_MANAGER_ROUTE,
    tabComponent: () => <BroadcastManager />,
  },
];

export const ADMIN_TABS_OPPORTUNITIES = [
  {
    name: "All opportunities",
    path: HOME_ROUTE,
  },
  {
    name: "Streaming (synthetic)",
    path: ROUTE_ADMIN_STREAMING_SYNTHETIC,
  },
  {
    name: "Loans",
    path: ROUTES_LOANS.Admin.Loans,
  },
];
