import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import { useState } from "react";
import {
  ReqGenerateDemo,
  ReqGenerateDemoScenarioEnum,
  ResAdminLoanDto,
  ResGenerateDemoStatusEnum,
} from "../../../app/model/api";
import { useAdminGenerateDemo } from "../loanMutations";
import toast from "react-hot-toast";
import { checkExhaustive } from "../../Admin/MinerDetails/Opportunity/simulation/simulationTimelineItems";

export const GenerateDemoData = ({ loan, onUpdate }: { loan: ResAdminLoanDto; onUpdate: () => void }) => {
  const [deliveriesCount, setDeliveriesCount] = useState(1);
  const [scenario, setScenario] = useState<ReqGenerateDemoScenarioEnum>(ReqGenerateDemoScenarioEnum.OverDelivery);
  const { mutateAsync, isLoading: isGenerating } = useAdminGenerateDemo();

  const onGenerate = async () => {
    try {
      const result = await mutateAsync({
        loanId: loan.id,
        deliveriesCount,
        scenario,
      });

      switch (result.data.status) {
        case ResGenerateDemoStatusEnum.CanNoLongerGenerate:
          toast.error("Can no longer generate deliveries.");
          break;
        case ResGenerateDemoStatusEnum.Done:
          toast.success(`Generated ${result.data.count} demo deliveries.`);
          break;
        case ResGenerateDemoStatusEnum.NotDemo:
          toast.error("Not a demo loan.");
          break;
        default:
          checkExhaustive(result.data.status);
      }
    } catch (err) {
      toast.error("There was an issue trying to generate the data");
    } finally {
      onUpdate();
    }
  };

  const maxDeliveries = loan.expectedPayments.length - loan.minerDeliveries.length;

  const handleChangeScenario = (_event: React.MouseEvent<HTMLElement>, newAlignment?: ReqGenerateDemo["scenario"]) => {
    if (newAlignment) setScenario(newAlignment);
  };

  return (loan.status !== "Active" && loan.status !== "UnderDelivered") || maxDeliveries <= 0 ? null : (
    <Box width={500}>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography variant="subtitle1">Generate demo deliveries</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} alignItems={"flex-start"}>
            <TextField
              label={"Deliveries to generate"}
              fullWidth
              size="small"
              variant="outlined"
              onWheel={(evt) => {
                (evt.target as HTMLElement).blur(); // disable edit by scroll
              }}
              value={deliveriesCount}
              onChange={(evt) => {
                const newAmount = +evt.target.value;
                setDeliveriesCount(newAmount);
              }}
              helperText={`Min=1, max=${maxDeliveries}`}
              error={deliveriesCount < 1 || deliveriesCount > loan.expectedPayments.length}
              type="number"
            />
            <FormControl sx={{ width: "100%", ml: 2 }}>
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={scenario}
                exclusive
                onChange={handleChangeScenario}
              >
                <ToggleButton value="ExactDelivery">Exact delivery</ToggleButton>
                <ToggleButton value="OverDelivery">Over delivery</ToggleButton>
                <ToggleButton value="UnderDelivery">Under delivery</ToggleButton>
                <ToggleButton value="Random">Randomise (+/-25% of target)</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            <LoadingButton
              loading={isGenerating}
              sx={{ width: "fit-content" }}
              variant="contained"
              disabled={deliveriesCount < 1 || deliveriesCount > loan.expectedPayments.length}
              onClick={onGenerate}
            >
              Add
            </LoadingButton>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
