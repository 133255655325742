import { Link } from "@mui/material";
import Decimal from "decimal.js";
import moment from "moment";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ENERGY_MIX_WITH_ICONS, ERROR_DEFAULT_MESSAGE, SATOSHIS_TO_BTC } from "../../app/constants";
import {
  amountFormat,
  calculatePriceBtcThSecoundDays,
  normalizeUrl,
  roundToSignificantDigits,
} from "../../app/helper/utils";
import { StreamingBasicStatusEnum } from "../../app/model/api";
import { useMutationRequestAccessToDataRoom } from "../../app/query/useMutationAdmin";
import { useGetQueryStreamingOpportunity } from "../../app/query/useQueryGetLP";
import Button from "../../components/atoms/Button";
import { ScoreIcon } from "../../components/atoms/ScoreIcon";
import SectionTitle from "../../components/atoms/SectionTitle";
import FundOpportunity from "../../components/organisms/FundOpportunity";
import { HeroOpportunityDetailsLP } from "../../components/organisms/HeroOpportunityDetailsLP";
import LiquidityProviderTable from "../../components/organisms/LiquidityProviderTable";
import { TruncatedCell } from "../Loans/commons/TruncatedCopyCell";
import { getAddressUrl } from "../Loans/commons/utils";
import "./style.scss";

export const StreamingDetailsLP = () => {
  const { id } = useParams();

  const { data: opportunityData, isFetching } = useGetQueryStreamingOpportunity(id as string);
  const { mutateAsync: requestAccessToDataRoom, isLoading: isLoadingRequest } = useMutationRequestAccessToDataRoom();

  const {
    opportunityBasic,
    energyMix,
    description,
    maxAmountOfLiquidity,
    fullHashrateThPerSecond,
    historicalUptime,
    fundingDeadline,
    durationOfAgreement,
    forecastRewards,
    managementFeePercentage,
    synHashPoolAddress,
    collateralAddress,
    rewardAddress,
    expectedReturn,
  } = opportunityData?.opportunityDetails || {};
  const { miner, status, liquidityReceived, currentHashrate } = opportunityBasic || {};
  const {
    entrypointForm,
    homepageUrl,
    dateOfIncorporation,
    company,
    sustainabilityRate,
    location,
    financialHealthRate,
    operationalHealthRate,
  } = miner || {};
  const { miningCapacityPh } = entrypointForm || {};

  const priceBtcPhSecondDays = calculatePriceBtcThSecoundDays({
    maxAmountOfLiquidity,
    fullHashrateThPerSecond,
    durationOfAgreementDays: durationOfAgreement,
  });

  const getEnergyMix = () => {
    if (!energyMix || (energyMix && Object.keys(energyMix).length === 0)) return [];

    const fallbackItem = ENERGY_MIX_WITH_ICONS.Other;
    return Object.entries(energyMix)
      .map(([key, value]) => {
        const item = ENERGY_MIX_WITH_ICONS[key as keyof typeof ENERGY_MIX_WITH_ICONS] ?? fallbackItem;

        return {
          icon: item.icon,
          value,
          name: key,
          color: item.color,
        };
      })
      .filter((item) => Number(item.value) > 0);
  };

  const handleRequestDataroom = async () => {
    try {
      if (id) {
        await requestAccessToDataRoom({ opportunityId: id });
        toast.success("You have sent a request regarding accessing the data room");
      }
    } catch (error) {
      toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const isDisabled =
    status === undefined ||
    ![
      StreamingBasicStatusEnum.Active,
      StreamingBasicStatusEnum.AwaitingCollateral,
      StreamingBasicStatusEnum.AwaitingFunding,
    ].includes(status);

  const forecastRewardsToBtc = new Decimal(forecastRewards || 0).div(SATOSHIS_TO_BTC).toNumber();

  const energyMixItems = getEnergyMix();

  return (
    <div>
      <HeroOpportunityDetailsLP
        expectedAnnualizedReturn={expectedReturn}
        liquidityProvided={liquidityReceived}
        hashAllocated={
          currentHashrate !== undefined && fullHashrateThPerSecond !== undefined
            ? { current: currentHashrate * 1000, maximum: fullHashrateThPerSecond }
            : undefined
        }
        isFetching={isFetching}
      />
      <SectionTitle title="Opportunity Details" />
      <div>
        <div className="container-custom mx-auto !pt-14 !pb-16">
          <div className="grid grid-cols-3 gap-5 ">
            <div className="col-span-2">
              {description && (
                <div className="bg-white mb-6">
                  <SectionTitle title="Description" />
                  <div className="p-6 opportunity-info-box">
                    <div>
                      <p>{description}</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="bg-white mb-6">
                <SectionTitle title="Opportunity information" />
                <div className="p-6 opportunity-info-box">
                  <div className="grid grid-cols-2 gap-6 mb-6">
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Miner Name</strong>
                      </p>
                      <p>{`${company || ""}`}</p>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Miner homepage</strong>
                      </p>
                      {homepageUrl?.includes(".") ? (
                        <Link target="_blank" href={normalizeUrl(homepageUrl)}>
                          {homepageUrl}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Funding deadline</strong>
                      </p>
                      <p>{fundingDeadline ? moment(fundingDeadline).format("DD MMM YYYY HH:mm") : "-"}</p>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Duration</strong>
                      </p>
                      <p>{durationOfAgreement ? `${durationOfAgreement} days` : "-"}</p>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Forecast rewards</strong>
                      </p>
                      <p>{roundToSignificantDigits(forecastRewardsToBtc, forecastRewardsToBtc >= 1 ? 2 : 1)} BTC</p>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Price per PH/s/day</strong>
                      </p>
                      <p>{amountFormat(priceBtcPhSecondDays, "BTC")} BTC/PH/s/day</p>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Management fee</strong>
                      </p>
                      <p>{managementFeePercentage || 0} %</p>
                    </div>
                    <div>
                      <p className="mb-2 col-span-2 uppercase">
                        <strong>SynHash Pool Address</strong>
                      </p>
                      <TruncatedCell fullText={synHashPoolAddress || "-"}>
                        {synHashPoolAddress ? (
                          <Link target="_blank" href={getAddressUrl(synHashPoolAddress)}>
                            {synHashPoolAddress}
                          </Link>
                        ) : (
                          "-"
                        )}
                      </TruncatedCell>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Reward Vault Address</strong>
                      </p>
                      <TruncatedCell fullText={rewardAddress || "-"}>
                        {rewardAddress ? (
                          <Link target="_blank" href={getAddressUrl(rewardAddress)}>
                            {rewardAddress}
                          </Link>
                        ) : (
                          "-"
                        )}
                      </TruncatedCell>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Collateral Vault Address</strong>
                      </p>
                      <TruncatedCell fullText={collateralAddress || "-"}>
                        {collateralAddress ? (
                          <Link target="_blank" href={getAddressUrl(collateralAddress)}>
                            {collateralAddress}
                          </Link>
                        ) : (
                          "-"
                        )}
                      </TruncatedCell>
                    </div>
                  </div>
                  {energyMixItems?.length > 0 && (
                    <div>
                      <div>
                        <p className="mb-2 uppercase">
                          <strong>Energy Mix</strong>
                        </p>
                      </div>
                      <div className="flex gap-3 mb-6">
                        {energyMixItems?.map((item, index) => {
                          return (
                            <div key={index}>
                              <div
                                className="flex items-center gap-[8px] px-[8px] py-[6px]"
                                style={{ backgroundColor: `${item.color}` }}
                              >
                                <img src={item.icon} alt={item.name} className="w-5" />
                                <h5 className="text-sm">{item.name}</h5>
                                <div className="percent bg-white text-sm py-[2px] px-[6px]">{item.value}%</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className=" bg-white mb-6">
                <SectionTitle title="Company information" />
                <div className="p-6 opportunity-info-box">
                  <div className="grid grid-cols-2 gap-7">
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Company name</strong>
                      </p>
                      <p>{company || "-"}</p>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Date of incorporation</strong>
                      </p>
                      <p>{dateOfIncorporation ? moment(dateOfIncorporation).format("DD MMM YYYY") : "-"}</p>
                    </div>
                    {location && (
                      <div>
                        <p className="mb-2 uppercase">
                          <strong>Country of incorporation</strong>
                        </p>
                        <p>{location}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className=" bg-white mb-6">
                <SectionTitle title="Operations" />
                <div className="p-6 opportunity-info-box">
                  <div className="grid grid-cols-3 gap-8">
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Historical uptime</strong>
                      </p>
                      <p>{historicalUptime ? `${historicalUptime}%` : "-"}</p>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Total deployed hashrate</strong>
                      </p>
                      <p>{miningCapacityPh ? `${miningCapacityPh} PH/s` : "-"} </p>
                    </div>
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Effective hashrate</strong>
                      </p>
                      <p>
                        {historicalUptime && miningCapacityPh
                          ? roundToSignificantDigits(
                              new Decimal(historicalUptime).div(100).mul(miningCapacityPh).toNumber()
                            )
                          : "-"}{" "}
                        PH/s
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="bg-white mb-5">
                <SectionTitle
                  title={
                    <div>
                      Risk Scores <span className="text-[14px] tracking-[0] normal-case">(Know-Your-Miner)</span>
                    </div>
                  }
                />
                <div className="px-6 py-6">
                  <div className="flex justify-between gap-4 py-4 pt-0 border-b-[1px] border-b-[#BDCCD4]">
                    <p className="uppercase font-semibold">Financial health</p>
                    <div className="flex gap-2">
                      <ScoreIcon value={financialHealthRate} />
                    </div>
                  </div>
                  <div className="flex justify-between gap-4 py-4 border-b-[1px] border-b-[#BDCCD4]">
                    <p className="uppercase font-semibold">Operational health</p>
                    <div className="flex gap-2">
                      <ScoreIcon value={operationalHealthRate} />
                    </div>
                  </div>
                  <div className="flex justify-between gap-4 py-4 border-b-[1px] border-b-[#BDCCD4]">
                    <p className="uppercase font-semibold">Sustainability</p>
                    <div className="flex gap-2">
                      <ScoreIcon value={sustainabilityRate} />
                    </div>
                  </div>
                  <div className="my-6">
                    <p>
                      To perform your own due diligence on the opportunity financials and operations, request access to
                      the data room.
                    </p>
                  </div>
                  <Button
                    label="Request Access to Data Room"
                    backgroundColor="#F9F5FF"
                    className="!w-[320px] text-purple font-normal pb-4"
                    onClick={() => handleRequestDataroom()}
                    disabled={isLoadingRequest}
                  />
                </div>
              </div>
              <FundOpportunity price={priceBtcPhSecondDays} disabled={isDisabled} />
            </div>
          </div>

          <LiquidityProviderTable />
        </div>
      </div>
    </div>
  );
};
