import * as yup from "yup";
import { LP_GRANULAR_TIME_HORIZON_OF_LIQUIDITY } from "../../app/constants";
import { RegisterLpDto, RegisterLpDtoTimeHorizonOfLiquidityEnum } from "../../app/model/api";
import {
  CommonOnboardingForm,
  powerOfAttorneyStringToEnum,
  primaryContactStringToEnum,
  YUP_COMMON,
  YUP_NOT_REQUIRED_POSITIVE_INTEGER,
} from "./common.schema";

export const YupLpFormSchema = yup
  .object({})
  .shape({
    ...YUP_COMMON,

    totalAssetsUsd: YUP_NOT_REQUIRED_POSITIVE_INTEGER,
    bitcoinAssetsAum: YUP_NOT_REQUIRED_POSITIVE_INTEGER,
    bitcoinInvestmentSize: YUP_NOT_REQUIRED_POSITIVE_INTEGER,
  })
  .required();

export type EntryFormLPFormData = CommonOnboardingForm & {
  readonly totalAssetsUsd?: number;
  readonly bitcoinAssetsAum?: number;
  readonly bitcoinInvestmentSize?: number;
};

export const createRegisterLpDto = (input: EntryFormLPFormData): RegisterLpDto => {
  // we default to empty values here because the api will validate it anyway,
  const result: RegisterLpDto = {
    user: {
      firstName: input.currentUserfirstname ?? "",
      lastName: input.currentUserlastname ?? "",
      email: input.currentUseremail ?? "",
      cellphone: input.currentUserphone ?? "",
    },
    primaryContact: {
      primaryContactKind: primaryContactStringToEnum(input.primaryContact),
      hasPowerOfAttorney: powerOfAttorneyStringToEnum(input.powerOfAttorney),
      reference:
        input.primaryContact === "another-person"
          ? {
              firstName: input.anotherUserfirstname ?? "",
              lastName: input.anotherUserlastname ?? "",
              email: input.anotherUseremail ?? "",
              cellphone: input.anotherUserphone ?? "",
            }
          : undefined,
    },
    companyName: input.companyLegalName ?? "",
    companyRole: input.roleAtCompany ?? "",
    homepageUrl: input.homepageUrl,
    companyCountryOfIncorporation: input.countryOfIncorporation ?? "",
    isPubliclyListed: input.isPubliclyListed,
    aumUsdMillions: input.totalAssetsUsd,
    bitcoinAssetsUnderManagement: input.bitcoinAssetsAum,
    projectedBitcoinInvestmentSize: input.bitcoinInvestmentSize,
    timeHorizonOfLiquidity:
      LP_GRANULAR_TIME_HORIZON_OF_LIQUIDITY.find((x) => x.value === input.timeHorizon)?.id ??
      RegisterLpDtoTimeHorizonOfLiquidityEnum.Notsure,
  };

  return result;
};
