import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useReducer } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { ENERGY_MIX, EnergyEntry } from "../../app/constants";
import { KymEnergyProfileDto, ReqKymEnergyProfileDto } from "../../app/model/api";
import { useMutationKymEnergyProfile } from "../../app/query/useKymMutations";
import { useQueryGetKym2EnergyProfile } from "../../app/query/useKymQueries";
import { ChipFileItem, DisplayFileList } from "../../components/atoms/FileInput/ChipFile";
import { MuiFileDragAndDropUploader } from "../../components/atoms/FileInput/MuiFileDragAndDropUploader";
import { MuiCircularPercentage } from "../../components/atoms/MuiCircularPercentage/MuiCircularPercentage";
import { UploadGridRow, buildChipFileItem, mapFiles } from "./commons";

interface EnergyFormData {
  readonly energySources: { name: string; percentage: number }[];
  readonly renewableEnergyPercentage?: number;
  readonly averageAllInEnergyPriceUsdPerMWh?: number;

  readonly hedgesEnergyPrice: boolean;
  readonly hedgingDescription?: string;

  readonly curtailmentPrograms?: string;
  readonly minimumPowerTakeOut?: string;
}
const sumOfArrayValidator = (sources: { percentage: number | undefined }[] | undefined) => {
  return sources?.reduce((acc, val) => acc + (val.percentage ?? 0), 0) === 100;
};

const YupFormSchema = yup
  .object({})
  .shape({
    energySources: yup
      .array()
      .of(
        yup.object({}).shape({
          name: yup.string().required(),
          percentage: yup.number().min(1).max(100).required(),
        })
      )
      .required()
      .test("is-sum-100", "The total percentage must add up to 100%.", sumOfArrayValidator),
    hedgingDescription: yup.string().when("hedgesEnergyPrice", {
      is: (hedgesEnergyPrice: boolean) => hedgesEnergyPrice,
      then: yup.string().required("This value is required"),
    }),
  })
  .required();

type FileAction =
  | { kind: "clear" }
  | {
      kind: "remove";
      id: string;
    }
  | { kind: "add"; files: File[] };

interface FormFilesState {
  hasUpdates: boolean;
  files: ChipFileItem[];
}

const reducer = (state: FormFilesState, action: FileAction): FormFilesState => {
  switch (action.kind) {
    case "add": {
      const newFiles = [
        ...state.files.filter((x) => !action.files.some((newFile) => newFile.name === x.id)),
        ...action.files.map((x) => {
          const file: ChipFileItem = { kind: "new", id: x.name, file: x };
          return file;
        }),
      ];
      const newState = {
        hasUpdates: true,
        files: newFiles,
      };
      return newState;
    }
    case "clear": {
      return {
        hasUpdates: true,
        files: [],
      };
    }
    case "remove": {
      const newState = {
        hasUpdates: true,
        files: state.files.filter((x) => x.id !== action.id),
      };
      return newState;
    }
  }
};

export const KymEnergyContainer = () => {
  const {
    refetch: reloadEnergyProfile,
    data: energyProfile,
    isFetching: isLoadingEnergyProfile,
  } = useQueryGetKym2EnergyProfile();

  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);

  const onRefetch = () => {
    setDestroyKey(!destroyKey); // force the component to be rebuilt
    reloadEnergyProfile();
  };

  return isLoadingEnergyProfile ? (
    <Stack>
      <Skeleton width={"100%"} height={300} />
    </Stack>
  ) : (
    <KymEnergy input={energyProfile?.data.payload} key={destroyKey.toString()} triggerRefetch={onRefetch} />
  );
};

const KymEnergy = ({
  input,
  triggerRefetch,
}: {
  input: KymEnergyProfileDto | undefined;
  triggerRefetch: () => void;
}) => {
  const [selection, setSelection] = React.useState<EnergyEntry | undefined>();
  const [percentage, setPercentage] = React.useState<number | undefined>();
  const { mutateAsync: saveEnergyProfile, isLoading: isSavingEnergyProfile } = useMutationKymEnergyProfile();

  const [filesState, dispatch] = useReducer(reducer, {
    hasUpdates: false,
    files: input?.hedgingSignedPpa?.map(buildChipFileItem) ?? [],
  });

  const {
    register,
    unregister,
    watch,
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<EnergyFormData>({
    resolver: yupResolver(YupFormSchema),
    defaultValues: {
      energySources: input?.energyElements.map((x) => ({ name: x.source, percentage: x.percentage })) ?? [],
      renewableEnergyPercentage: input?.renewableEnergyPercentage,
      averageAllInEnergyPriceUsdPerMWh: input?.averageAllInEnergyPriceUsdPerMWh,
      hedgesEnergyPrice: input?.hedgesEnergyPrice ?? false,
      hedgingDescription: input?.hedgingDescription,
      curtailmentPrograms: input?.curtailmentPrograms,
      minimumPowerTakeOut: input?.minimumPowerTakeOut,
    },
  });

  const {
    fields: energySources,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "energySources",
  });

  const totalEnergySourcePercentage = energySources.reduce((acc, crt) => acc + crt.percentage, 0);

  const disableAddEnergy = totalEnergySourcePercentage >= 100 || !percentage || !selection;

  const hedgesEnergyPrice: boolean = watch("hedgesEnergyPrice");

  React.useEffect(() => {
    // we need to manually register/unregister these for validation
    if (hedgesEnergyPrice) {
      register("hedgingDescription");
    } else {
      dispatch({ kind: "clear" });
      unregister("hedgingDescription");
    }
  }, [hedgesEnergyPrice, register, unregister]);

  const disableSubmit = (!isDirty && !filesState.hasUpdates) || totalEnergySourcePercentage < 100;

  const onSubmit = async (data: EnergyFormData) => {
    const request: ReqKymEnergyProfileDto = {
      energyElements: data.energySources?.map((x) => ({ source: x.name, percentage: x.percentage })) ?? [],
      renewableEnergyPercentage: data?.renewableEnergyPercentage,
      averageAllInEnergyPriceUsdPerMWh: data?.averageAllInEnergyPriceUsdPerMWh ?? 0,
      hedgesEnergyPrice: data.hedgesEnergyPrice,
      hedgingDescription: data.hedgesEnergyPrice ? data.hedgingDescription : undefined,
      hedgingSignedPpa: await mapFiles(filesState.files ?? []),
      curtailmentPrograms: data.curtailmentPrograms,
      minimumPowerTakeOut: data.minimumPowerTakeOut,
    };

    try {
      const result = await saveEnergyProfile(request);
      if (result.data.kind === false) {
        toast.error("There has been an issue while trying to save your changes. Please try again.");
      } else {
        toast.success("Your updates have been saved successfully.");
        triggerRefetch();
      }
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again.");
    }
  };

  return (
    <Box width={"100%"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UploadGridRow>
          <Box pt={2} pl={2}>
            <Typography variant="subtitle1">Energy sources</Typography>
            <Box>
              <Typography variant="subtitle2">Please enter all your energy sources.</Typography>
              {errors.energySources && (
                <Typography variant="caption" color={"error"}>
                  The total percentage must add up to 100%.
                </Typography>
              )}
            </Box>
          </Box>
          <Grid container item spacing={2}>
            <Grid container xs={12} pl={2} pt={2} direction={"row"}>
              <Autocomplete
                size="small"
                id="primaryEnergySource"
                options={ENERGY_MIX.filter((x) => !energySources.some((es) => es.name === x.value))}
                autoHighlight
                disabled={totalEnergySourcePercentage >= 100}
                getOptionLabel={() => selection?.label ?? ""}
                isOptionEqualToValue={(first: EnergyEntry, second: EnergyEntry) => {
                  return first.value === second.value;
                }}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props}>
                      {option.value}
                    </Box>
                  );
                }}
                onChange={(_, newValue: EnergyEntry | null) => {
                  setSelection(newValue ?? undefined);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: 300 }}
                    {...params}
                    label="Energy source"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                id="perc"
                size="small"
                sx={{ width: 90 }}
                placeholder="23"
                type="number"
                variant="outlined"
                disabled={totalEnergySourcePercentage >= 100}
                value={percentage || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value != undefined && event.target.value !== "") {
                    const value = Math.floor(+event.target.value);
                    if (value > 100 - totalEnergySourcePercentage) setPercentage(100 - totalEnergySourcePercentage);
                    else setPercentage(value);
                  }
                }}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                InputProps={{
                  inputProps: { max: 100 },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
              <IconButton
                disabled={disableAddEnergy}
                color="primary"
                onClick={() => {
                  if (selection && percentage) {
                    append({ name: selection.value, percentage });
                    setSelection(undefined);
                    setPercentage(undefined);
                  }
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
              {totalEnergySourcePercentage > 0 && (
                <MuiCircularPercentage variant="determinate" value={totalEnergySourcePercentage} />
              )}
              {energySources?.map((es, index) => (
                <Chip
                  key={`chip-es-${es.name}`}
                  variant="filled"
                  color="primary"
                  onDelete={() => {
                    remove(index);
                  }}
                  sx={{ maxWidth: 175, ml: 1, mt: 0.3 }}
                  clickable={false}
                  label={`${es.name} ${es.percentage}%`}
                ></Chip>
              ))}
            </Grid>
          </Grid>
          <Grid container item mt={2} spacing={2}>
            <Grid container item xs={12} md={6}>
              <TextField
                id="renewableEnergyPercentage"
                label={"[Optional] Renewable energy share sources in energy mix"}
                placeholder="5"
                {...register("renewableEnergyPercentage")}
                fullWidth
                type="number"
                variant="outlined"
                error={!!errors.renewableEnergyPercentage}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="averageAllInEnergyPriceUsdPerMWh"
                label={"[Optional] Energy price"}
                placeholder="5"
                {...register("averageAllInEnergyPriceUsdPerMWh")}
                fullWidth
                type="number"
                variant="outlined"
                error={!!errors.averageAllInEnergyPriceUsdPerMWh}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: <InputAdornment position="end">USD/MWh</InputAdornment>,
                }}
              />
              <Typography variant="caption" color="secondary">
                Average all-in energy price at self-operated sites
              </Typography>
            </Grid>
          </Grid>
        </UploadGridRow>

        <UploadGridRow>
          <Grid container item alignItems={"flex-start"} xs={12} md={12}>
            <Box width="100%">
              <Grid item xs>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={<Switch color="primary" {...register("hedgesEnergyPrice")} />}
                    label="Do you hedge your energy price?"
                    labelPlacement="end"
                    checked={hedgesEnergyPrice}
                  />
                </FormControl>
              </Grid>
              {hedgesEnergyPrice && (
                <Grid container item xs={12} md={12} spacing={2} mt={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="hedgingDescription"
                      label={"Description"}
                      {...register("hedgingDescription")}
                      fullWidth
                      type="number"
                      variant="outlined"
                      multiline
                      rows={5}
                      error={!!errors.hedgingDescription}
                      helperText={errors.hedgingDescription?.message && <Box>{errors.hedgingDescription.message}</Box>}
                      onWheel={(evt) => {
                        (evt.target as HTMLElement).blur(); // disable edit by scroll
                      }}
                    />
                    <Typography variant="caption" color="secondary">
                      Add a description of your energy hedging mechanisms (e.g. PPA incl. duration, capacity in MW)
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <Box width="100%" height={100}>
                      <MuiFileDragAndDropUploader
                        text={"Please upload a signed PPA"}
                        onFileUpload={(files: File[]) => dispatch({ kind: "add", files })}
                      />
                      <DisplayFileList
                        id={"signedppa"}
                        onDelete={(id: string) => dispatch({ kind: "remove", id })}
                        files={filesState.files}
                      ></DisplayFileList>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </UploadGridRow>

        <UploadGridRow>
          <Grid item xs={12} md={6}>
            <TextField
              id="curtailmentPrograms"
              label={"[Optional] Curtailment / demand response programs"}
              placeholder="[Optional] Curtailment / demand response programs"
              {...register("curtailmentPrograms")}
              fullWidth
              variant="outlined"
              multiline
              rows={5}
              error={!!errors.curtailmentPrograms}
            />
            <Typography variant="caption" color="secondary">
              Please explain any contractual curtailment rates and/or expected annual participation in demand response
              programs and associated monetary compensations (if applicable).
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="minimumPowerTakeOut"
              label={"[Optional] Minimum power take-out"}
              placeholder={"[Optional] Minimum power take-out"}
              {...register("minimumPowerTakeOut")}
              fullWidth
              variant="outlined"
              multiline
              rows={5}
              error={!!errors.minimumPowerTakeOut}
            />
            <Typography variant="caption" color="secondary">
              Please explain if you have any minimum power consumption obligations towards utility, grid operator, or
              other suppliers.
            </Typography>
          </Grid>
        </UploadGridRow>

        <Box mt={2} gap={2} display="flex" alignItems={"center"} justifyContent={"flex-end"}>
          <LoadingButton
            type="submit"
            disabled={disableSubmit}
            loading={isSavingEnergyProfile}
            variant="contained"
            sx={{ height: 40 }}
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};
