import CloseIcon from "@mui/icons-material/Close";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import HardwareIcon from "@mui/icons-material/Hardware";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import React, { useState } from "react";
import {
  MINER_ORGANIZATION_BASIC_INFO_ROUTE,
  MINER_ORGANIZATION_SYNTHETIC_OPPORTUNITY,
  ORGANIZATION_DETAILS_ROUTE,
} from "../../../app/constants";
import {
  AuthUserResponseDtoUserTypeEnum,
  GetOrganizationsParamsActionFirstEnum,
  GetOrganizationsParamsUserTypeEnum,
  OrganizationEntry,
  OrganizationEntryUserTypeEnum,
} from "../../../app/model/api";
import { useQueryAdminGetOrganizations } from "../../../app/query/useQueryGetAdmin";
import { MinerBasicInfo } from "../MinerDetails/MinerBasicInfo";

const formatDate = (input: string | Date | number) => {
  const date = new Date(input);
  return format(date, "dd/MM/yy HH:mm");
};

interface Column {
  id: "company" | "email" | "userType" | "onboardingStatus" | "numOfUsers" | "createdAt" | "actions";
  label: string;
  minWidth?: number;
  width?: number;
  align?: "right" | "center";
}

const columns: readonly Column[] = [
  { id: "createdAt", label: "Created at" },
  { id: "company", label: "Company name", minWidth: 140 },
  { id: "userType", label: "Type", minWidth: 200, align: "right" },
  {
    id: "onboardingStatus",
    label: "Onboarding status",
    minWidth: 170,
    align: "right",
  },
  { id: "email", label: "Onboarding user", minWidth: 170, align: "right" },
  { id: "actions", label: "Actions", align: "right" },
];

export const AdminOrganizations = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [actionFirst, setActionFirst] = React.useState<boolean>(false);
  const [userType, setUserType] = useState<
    AuthUserResponseDtoUserTypeEnum.LP | AuthUserResponseDtoUserTypeEnum.Miner | "All"
  >("All");

  const [selectedMinerId, setSelectedMinerId] = React.useState<string | undefined>();

  const getMinerUrl = (minerId: string) => {
    return MINER_ORGANIZATION_BASIC_INFO_ROUTE.replace(":id", minerId);
  };

  const getOrganizationDetailsUrl = (org: OrganizationEntry): string => {
    switch (org.userType) {
      case OrganizationEntryUserTypeEnum.Admin:
        return ""; // should not happen, handling for exhaustiveness
      case OrganizationEntryUserTypeEnum.LP:
        return ORGANIZATION_DETAILS_ROUTE.replace(":id", String(org.id));
      case OrganizationEntryUserTypeEnum.Miner:
        return getMinerUrl(org.id);
    }
  };

  const getCreateOpportunityScreenUrl = (minerId: string): string => {
    return MINER_ORGANIZATION_SYNTHETIC_OPPORTUNITY.replace(":id", minerId);
  };

  const handleActionFirst = () => {
    setActionFirst(!actionFirst);
    setPage(0);
  };

  const handleFilter = (
    event: React.MouseEvent<HTMLElement>,
    newValue: AuthUserResponseDtoUserTypeEnum.LP | AuthUserResponseDtoUserTypeEnum.Miner | "All"
  ) => {
    setUserType(
      newValue === AuthUserResponseDtoUserTypeEnum.LP
        ? AuthUserResponseDtoUserTypeEnum.LP
        : newValue === AuthUserResponseDtoUserTypeEnum.Miner
        ? AuthUserResponseDtoUserTypeEnum.Miner
        : "All"
    );
    setPage(0);
  };

  const { data, isFetching } = useQueryAdminGetOrganizations({
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    actionFirst: actionFirst ? GetOrganizationsParamsActionFirstEnum.Yes : GetOrganizationsParamsActionFirstEnum.No,
    userType:
      userType === "All"
        ? undefined
        : userType === AuthUserResponseDtoUserTypeEnum.LP
        ? GetOrganizationsParamsUserTypeEnum.LP
        : GetOrganizationsParamsUserTypeEnum.Miner,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getStatus = (input: OrganizationEntry["onboardingStatus"]) => {
    switch (input) {
      case "Entrypoint pending":
      case "KYC pending":
      case "KYM pending":
        return (
          <Tooltip title={`${input} - Requires review`}>
            <Chip variant="outlined" label={input} color="warning" />
          </Tooltip>
        );
      case "KYC Rejected":
      case "Entrypoint Rejected":
      case "KYM Rejected":
      case "Susppended":
        return <Chip variant="outlined" color="error" label={input} />;
      default:
        return <Chip variant="outlined" color="default" label={input} />;
    }
  };

  const handleCloseMinerDialog = () => {
    setSelectedMinerId(undefined);
  };

  return (
    <Box width={"100%"}>
      {selectedMinerId ? (
        <Dialog open={!!selectedMinerId} onClose={handleCloseMinerDialog} fullWidth={true} maxWidth="md">
          <AppBar color="default" sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleCloseMinerDialog} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Button
                target="_blank"
                href={getMinerUrl(selectedMinerId)}
                variant="text"
                sx={{
                  ml: 1,
                  height: 20,
                  padding: 0,
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                <Typography noWrap>Miner #{selectedMinerId}</Typography>
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Box display="flex">
              <MinerBasicInfo minerId={selectedMinerId} />
            </Box>
          </DialogContent>
        </Dialog>
      ) : null}
      <Stack alignItems={"center"} width={"100%"}>
        <Stack sx={{ background: "white", width: "100%", maxWidth: 1600, padding: 3 }}>
          <Stack direction={"row"} mb={2}>
            <ToggleButtonGroup size="small" value={userType} exclusive onChange={handleFilter} sx={{ mr: 2 }}>
              <ToggleButton size="small" value={AuthUserResponseDtoUserTypeEnum.Miner} sx={{ width: 100 }}>
                {AuthUserResponseDtoUserTypeEnum.Miner}
              </ToggleButton>
              <ToggleButton size="small" value={AuthUserResponseDtoUserTypeEnum.LP} sx={{ width: 100 }}>
                {AuthUserResponseDtoUserTypeEnum.LP}
              </ToggleButton>
              <ToggleButton size="small" value={"All"} sx={{ width: 100 }}>
                All
              </ToggleButton>
            </ToggleButtonGroup>
            <FormControlLabel
              value="start"
              control={<Switch size="small" checked={actionFirst} onChange={handleActionFirst} />}
              label="Show only organizations requiring action?"
              labelPlacement="end"
            />
          </Stack>
          <Paper sx={{ boxShadow: 5, pt: 1 }}>
            <TableContainer sx={{ maxHeight: "60vh" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "red" }}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} width={column.minWidth}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data?.rows.length ? (
                    data.rows.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          <TableCell align={"left"} sx={{ minWidth: 130 }}>
                            <Tooltip title={row.createdAt}>
                              <Typography noWrap>{formatDate(row.createdAt)}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 200 }}>
                            <Box display="flex" alignItems={"center"}>
                              <Button
                                href={getOrganizationDetailsUrl(row)}
                                variant="text"
                                size="small"
                                sx={{
                                  ml: 1,
                                  maxWidth: 150,
                                  padding: 0,
                                  "&:hover": { textDecoration: "underline" },
                                }}
                              >
                                <Typography noWrap>{row.company}</Typography>
                              </Button>
                              {row.userType === "Miner" ? (
                                <IconButton onClick={() => setSelectedMinerId(row.id)} color="info">
                                  <FileOpenIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                          <TableCell align={"right"} sx={{ minWidth: 90 }}>
                            {row.userType === "Miner" ? (
                              <Chip size="small" icon={<HardwareIcon fontSize="small" />} color="info" label="Miner" />
                            ) : (
                              <Chip
                                size="small"
                                icon={<CurrencyExchangeIcon fontSize="small" />}
                                color="success"
                                label="LP"
                              />
                            )}
                          </TableCell>
                          <TableCell align="right" sx={{ minWidth: 150 }}>
                            {getStatus(row.onboardingStatus)}
                          </TableCell>
                          <TableCell align={"right"} sx={{ minWidth: 250 }}>
                            <Tooltip title={row.email}>
                              <Typography noWrap>{row.email}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={"right"} sx={{ minWidth: 250, maxWidth: 250 }}>
                            {row.userType === "Miner" && row.onboardingStatus === "Fully onboarded" ? (
                              <Button
                                href={getCreateOpportunityScreenUrl(row.id)}
                                variant="text"
                                size="small"
                                sx={{
                                  ml: 1,
                                  height: 20,
                                  padding: 0,
                                  "&:hover": { textDecoration: "underline" },
                                }}
                              >
                                Create opportunity
                              </Button>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>

              {!isFetching && !data?.rows.length && (
                <Box width="100%" padding={2} display={"flex"}>
                  <Typography width={"100%"} variant="caption" color="silver" textAlign={"center"}>
                    No available data
                  </Typography>
                </Box>
              )}

              {isFetching && (
                <Box width="100%" pl={2} pr={2}>
                  <Skeleton width={"100%"} height={50}></Skeleton>
                  <Skeleton width={"100%"} height={50}></Skeleton>
                  <Skeleton width={"100%"} height={50}></Skeleton>
                  <Skeleton width={"100%"} height={50}></Skeleton>
                  <Skeleton width={"100%"} height={50}></Skeleton>
                </Box>
              )}
            </TableContainer>
            {data?.rows.length ? (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data?.count ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : (
              <></>
            )}
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
};
