import { Stack, Typography } from "@mui/material";
import { amountFormat } from "../../../app/helper/utils";

interface TableAmountCellProps {
  type: string;
  amountBtc: number;
  amountUsd: number;
}

export const TableAmountCell = ({ type, amountBtc, amountUsd }: TableAmountCellProps) => {
  return (
    <Stack>
      <Typography variant="subtitle1" color={type === "Withdrawal" ? "#FC7A53" : "#31DF8B"}>
        {`${type === "Withdrawal" ? "-" : "+"}${amountFormat(amountBtc, "BTC")}`} BTC
      </Typography>
      <Typography variant="caption">{`${type === "Withdrawal" ? "-" : "+"}${amountFormat(amountUsd)}`}</Typography>
    </Stack>
  );
};
