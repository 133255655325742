import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { addDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useState } from "react";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { ResAdminLoanDtoStatusEnum } from "../../../app/model/api";
import Loader from "../../../components/atoms/Loader";
import { ExplainerAccordion } from "../commons/ExplainerAccordion";
import { HorizontalInfoTable } from "../commons/HorizontalInfoTable";
import { LoanExpectedScheduleTable } from "../commons/LoanExpectedScheduleTable";
import { LoanTitle, getAdminLoanStatusChip } from "../commons/LoanTitle";
import { TruncatedCell } from "../commons/TruncatedCopyCell";
import { UnitSelector } from "../commons/UnitSelector";
import { VerticalInfoTable } from "../commons/VerticalInfoTable";
import { formatWithCommas, resAdminLoanDtoCalculationKindName, toFormattedBtc } from "../commons/utils";
import { useAdminCancelLoan, useAdminCompleteLoan } from "../loanMutations";
import { useAdminGetLoan, useAdminGetLoanBitgoBalance } from "../loanQueries";
import { GenerateDemoData } from "./GenerateDemoData";
import { UpdateLoanBasicDetails } from "./UpdateLoanBasicDetails";

export const AdminPageManageLoan = ({ id }: { id: string }) => {
  const [requestedAction, setRequestedAction] = useState<undefined | "cancel" | "complete">();
  const [destroyKey, setDestroyKey] = useState("initial");
  const [unit, setUnit] = useState<"BTC" | "SAT">("BTC");
  const { data, isFetching, refetch } = useAdminGetLoan(id);
  const {
    data: bitgodata,
    isFetching: isFetchingBitgoBalance,
    refetch: refetchBitgoBalance,
  } = useAdminGetLoanBitgoBalance(id);

  const { mutateAsync: cancelLoan, isLoading: isCancelling } = useAdminCancelLoan();
  const { mutateAsync: completeLoan, isLoading: isCompleting } = useAdminCompleteLoan();

  const isDoingSomething = isFetching || isFetchingBitgoBalance || isCancelling || isCompleting;

  const loan = data?.data;

  const triggerRefetch = () => {
    refetch();
    refetchBitgoBalance();
    setDestroyKey(v4());
  };

  const handleLoanUpdate = async () => {
    const operation = requestedAction === "cancel" ? cancelLoan : completeLoan;

    setRequestedAction(undefined);
    try {
      await operation(id);
      toast.success(requestedAction === "cancel" ? "Loan has been cancelled" : "Loan has been completed");
    } catch (err) {
      toast.error(
        "There was an issue performing the update. Please try again. The loan may also be in a state that does not allow it."
      );
    } finally {
      triggerRefetch();
    }
  };

  if (!loan) {
    return <Loader />;
  } else {
    return (
      <Box display="flex" justifyContent={"center"} width={"100%"} pt={3}>
        <Stack width="100%" maxWidth={1800} bgcolor={"white"} spacing={2}>
          <Box display="flex" width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
            <LoanTitle id={loan.id} statusChip={getAdminLoanStatusChip(loan.status)} isDemo={loan.isDemo} />
            <UnitSelector initial={unit} onChange={setUnit} />
          </Box>
          <ExplainerAccordion
            title="Loan status details"
            explainers={[
              "The statuses loans can go through are: Draft, Active, Under Delivered, Cancelled, Completed.",
              "A draft loan is the first state a loan is in, which allows all its parameters to be updated, except for the miner and LP. To change the participants you will have to create a new draft loan. The Draft state is the only state that allows changes to the loan parameters, and you can see the potential schedule for the loan by changing the different parameters.",
              "Once a start date is set and a the loan is marked as signed, the loan becomes Active. Once active, the application will start checking for deliveries from miners. Payment requests can also be made by admins to pay out LPs, return excesses to miners, and generally make payments to any valid address.",
              "The loan can become Under Delivered if miners do not deliver the expected amounts on schedule. This is checked automatically for loans and notifications are sent when it occurs. Otherwise, there is no other difference between Active and Under Delivered.",
              "Draft and Active loans can be manually Cancelled by admins. The Cancelled state is supposed to be used for premature/unsuccessful terminations of loans. This is a final state, but payment requests are still allowed.",
              "Draft and Active loans can also be manually Completed by admins. The Completed state is supposed to be used to mark a loan being successfully terminated. This is a final state, but payment requests are still allowed.",
              <Box key={v4()} mt={5} />,
              "Demo loans are a special case of loans which are not available in production, only in test environments. Demo loans are for the purpose of being able to generate a loan with data for demos or testing the UI. A demo loan allows the admin to generate fake miner deliveries either over delivering, under delivering or randomly delivered.",
              "Payment requests made for demo loans are immediately marked as confirmed and are not attempted.",
            ]}
          />
          {isFetching && <Skeleton width="100%" height={"30vh"} variant="rounded" />}
          {!isFetching && (
            <Stack spacing={2}>
              <Box display="flex" gap={2} width="auto">
                {(loan.status === "Active" || loan.status === "UnderDelivered") && (
                  <LoadingButton
                    variant="contained"
                    color="warning"
                    onClick={() => setRequestedAction("cancel")}
                    disabled={isDoingSomething}
                    loading={isCancelling || isFetching}
                    sx={{
                      width: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Cancel loan
                  </LoadingButton>
                )}
                {(loan.status === "Active" || loan.status === "UnderDelivered") && (
                  <LoadingButton
                    variant="contained"
                    color="success"
                    onClick={() => setRequestedAction("complete")}
                    disabled={isDoingSomething}
                    loading={isCompleting || isFetching}
                    sx={{
                      width: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Complete loan
                  </LoadingButton>
                )}
              </Box>
              {loan?.isDemo && !isDoingSomething ? <GenerateDemoData loan={loan} onUpdate={triggerRefetch} /> : null}

              <Box>
                <HorizontalInfoTable
                  title="Loan details"
                  entries={[
                    {
                      name: "Created at",
                      value: formatInTimeZone(loan.createdAt, "UTC", "dd MMM yyyy"),
                    },
                    {
                      name: "Status",
                      value: getAdminLoanStatusChip(loan.status),
                    },
                    { name: "Miner name", value: loan.minerName },
                    { name: "LP name", value: loan.lpName },
                    { name: "Duration (days)", value: loan.durationDays.toString() },
                    {
                      name: "Start date",
                      value: loan.startedAt ? formatInTimeZone(loan.startedAt, "UTC", "dd MMM yyyy") : "",
                    },
                    {
                      name: "End date",
                      value: loan.startedAt
                        ? formatInTimeZone(addDays(new Date(loan.startedAt), +loan.durationDays), "UTC", "dd MMM yyyy")
                        : "",
                    },
                  ]}
                />
              </Box>
              <Grid container item xs={12}>
                <Grid container item xs={12} sm={12} md={6} pr={1}>
                  <VerticalInfoTable
                    title={"Loan model"}
                    key={"loan-model-" + unit}
                    entries={[
                      {
                        name: "Principal amount",
                        value:
                          unit === "SAT"
                            ? formatWithCommas(loan.principalAmountSatoshi)
                            : toFormattedBtc(loan.principalAmountSatoshi),
                      },
                      {
                        name: "Start date",
                        value: loan.startedAt ? formatInTimeZone(loan.startedAt, "UTC", "dd MMM yyyy") : "",
                      },
                      { name: "Duration (days)", value: loan.durationDays.toString() },
                      { name: "Days per year", value: loan.daysPerYear.toString() },
                      { name: "Type", value: resAdminLoanDtoCalculationKindName(loan.calculationKind) },
                      { name: "Miner interest", value: loan.minerAnnualInterestRatePercent.toString() + "%" },
                      { name: "LP interest", value: loan.lpAnnualInterestRatePercent.toString() + "%" },
                    ]}
                  />
                </Grid>
                <Grid container item xs={12} sm={12} md={6}>
                  <VerticalInfoTable
                    title={"Reward wallet info"}
                    key={"Reward-wallet-" + unit}
                    entries={[
                      { name: "Bitgo wallet id", value: loan.rewardBitgoWalletId ?? "-" },
                      {
                        name: "Address",
                        value: loan.rewardAddress ? (
                          <TruncatedCell fullText={loan.rewardAddress}>{loan.rewardAddress}</TruncatedCell>
                        ) : (
                          "-"
                        ),
                      },
                      {
                        name: "Bitgo balance",
                        value:
                          unit === "SAT"
                            ? formatWithCommas(bitgodata?.data?.balance ?? 0)
                            : toFormattedBtc(bitgodata?.data?.balance ?? 0),
                      },
                      {
                        name: "Bitgo confirmed balance",
                        value:
                          unit === "SAT"
                            ? formatWithCommas(bitgodata?.data?.confirmedBalanceSatoshi ?? 0)
                            : toFormattedBtc(bitgodata?.data?.confirmedBalanceSatoshi ?? 0),
                      },
                      {
                        name: "Bitgo spendable balance",
                        value:
                          unit === "SAT"
                            ? formatWithCommas(bitgodata?.data?.spendableBalanceSatoshi ?? 0)
                            : toFormattedBtc(bitgodata?.data?.spendableBalanceSatoshi ?? 0),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              {!isDoingSomething && (
                <Stack key={destroyKey} width={"100%"} spacing={2}>
                  {loan.status === ResAdminLoanDtoStatusEnum.Draft && (
                    <UpdateLoanBasicDetails loan={loan} onUpdate={triggerRefetch} />
                  )}
                  <ExplainerAccordion
                    title={"Details"}
                    explainers={[
                      "The expected schedule shows what the total expected amount is supposed to be at a certain date, what is the expected month installment by the miner, the month amount owed to the LP, and the total delivered by the miner for each date. The total deliveries column shows the miner deliveries up to the respective date.",
                      "The schedule is calculated based on the main configuration parameters of the loan: the start date, duration, principal amount and interest rates.",
                    ]}
                  />
                  <Typography variant="h6" gutterBottom>
                    Miner payment schedule
                  </Typography>
                  <LoanExpectedScheduleTable
                    unit={unit}
                    height={"50vh"}
                    expected={loan.expectedPayments.map((exp) => ({
                      date: exp.date,
                      expected: exp.expectedFromMinerSatoshis,
                      expectedSoFar: exp.totalExpectedFromMinerSoFar,
                    }))}
                    actuals={loan.minerDeliveries}
                  />
                  <Typography variant="h6" gutterBottom>
                    LP payout schedule
                  </Typography>
                  <LoanExpectedScheduleTable
                    unit={unit}
                    height={"50vh"}
                    expected={loan.expectedPayments.map((exp) => ({
                      date: exp.date,
                      expected: exp.forLPSatoshis,
                      expectedSoFar: exp.totalForLPSoFar,
                    }))}
                    actuals={loan.outgoingPayments.filter((x) => x.destination === "LP")}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </Stack>

        <Dialog open={!!requestedAction} onClose={() => setRequestedAction(undefined)}>
          <DialogTitle
            id="alert-dialog-title"
            variant="h5"
          >{`Are you sure you want to ${requestedAction} this loan?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {requestedAction === "cancel" ? (
                <Stack spacing={2}>
                  <Typography variant="body1" color="black">
                    Cancelling the loan means the loan is interrupted by an external event (such as the contract being
                    prematurely terminated).
                  </Typography>
                  <Typography variant="body1" color="black">
                    The loan will no longer be actively checked for under delivery or any other status.
                  </Typography>
                </Stack>
              ) : null}
              {requestedAction === "complete" ? (
                <Stack spacing={2}>
                  <Typography variant="body1" color="black">
                    Completing the loan means the loan is marked as successfully terminated.
                  </Typography>
                  <Typography variant="body1" color="black">
                    The loan will no longer be actively checked for under delivery or any other status.
                  </Typography>
                </Stack>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLoanUpdate}>Yes</Button>
            <Button onClick={() => setRequestedAction(undefined)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
};
