import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Link, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { subSeconds } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { compact, sortBy } from "lodash";
import { ResLoanPaymentRequestDto } from "../../../app/model/api";
import { ButtonCopyToClipboard } from "./ButtonCopyToClipboard";
import { TruncatedCell } from "./TruncatedCopyCell";
import { formatWithCommas, getAddressUrl, getTransactionUrl, toFormattedBtc } from "./utils";

export const LoanPaymentRequestsTable = ({
  title,
  unit,
  outgoingPayments,
  height,
  includeExecutionDetails,
  explainer,
  onDelete,
}: {
  title: string;
  unit: "SAT" | "BTC";
  outgoingPayments: ResLoanPaymentRequestDto[];
  height: string | number;
  includeExecutionDetails?: boolean;
  explainer?: JSX.Element;
  onDelete?: (id: string) => void;
}) => {
  const COLUMNS: GridColDef<ResLoanPaymentRequestDto & { amountBtc: number; isDeleteable: boolean }>[] = compact([
    {
      field: "createdAt",
      headerName: "Created on",
      sortable: true,
      flex: 1,
      renderCell: (params) => formatInTimeZone(params.row.createdAt, "UTC", "dd MMM yyyy"),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        if (params.row.isFailed) return "Failed";
        else if (params.row.confirmedAt) return "Confirmed";
        else if (params.row.executedAt) return "Waiting for confirmation";
        else return "Waiting for execution";
      },
    },
    // we need to do it this way (different columns for sat/btc) to have consistent values on the CSV export
    unit === "SAT"
      ? {
          field: "amountSatoshi",
          headerName: "Amount (SAT)",
          sortable: true,
          flex: 1,
          align: "right",
          headerAlign: "right",
          renderCell: (params) => formatWithCommas(params.row.amountSatoshi),
        }
      : {
          field: "amountBtc",
          headerName: "Amount (BTC)",
          sortable: true,
          flex: 1,
          align: "right",
          headerAlign: "right",
          renderCell: (params) => params.row.amountBtc,
        },
    {
      field: "destination",
      headerName: "Destination type",
      sortable: true,
      flex: 1,
    },
    {
      field: "toAddress",
      headerName: "Recipient address",
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <TruncatedCell fullText={params.row.toAddress}>
          <Link target="_blank" href={getAddressUrl(params.row.toAddress)}>
            {params.row.toAddress}
          </Link>
        </TruncatedCell>
      ),
    },
    {
      field: "transactionHash",
      headerName: "Transaction hash",
      sortable: true,
      flex: 1,
      renderCell: (params) =>
        params.row.transactionHash ? (
          <>
            <Link target="_blank" href={getTransactionUrl(params.row.transactionHash)}>
              {params.row.transactionHash}
            </Link>
            <ButtonCopyToClipboard text={params.row.transactionHash} />
          </>
        ) : (
          "-"
        ),
    },
    includeExecutionDetails
      ? {
          field: "executeAfter",
          headerName: "Execute after",
          sortable: true,
          flex: 1,
          renderCell: (params) => formatInTimeZone(params.row.executeAfter, "UTC", "dd MMM yyyy HH:mm:ss"),
        }
      : undefined,
    includeExecutionDetails
      ? {
          field: "executedAt",
          headerName: "Executed at",
          sortable: true,
          flex: 1,
          renderCell: (params) =>
            params.row.executedAt ? formatInTimeZone(params.row.executedAt, "UTC", "dd MMM yyyy HH:mm:ss") : "-",
        }
      : undefined,
    {
      field: "confirmedAt",
      headerName: "Confirmed at",
      sortable: true,
      flex: 1,
      renderCell: (params) =>
        params.row.confirmedAt ? formatInTimeZone(params.row.confirmedAt, "UTC", "dd MMM yyyy HH:mm:ss") : "-",
    },
    onDelete
      ? {
          field: "actions",
          headerName: "Actions",
          sortable: false,
          disableColumnMenu: true,
          align: "center",
          headerAlign: "center",
          width: 100,
          renderCell: (params) =>
            !!onDelete && params.row.isDeleteable ? (
              <IconButton size="small" onClick={() => onDelete(params.row.id)} color="error">
                <DeleteIcon fontSize="small" />
              </IconButton>
            ) : (
              "-"
            ),
        }
      : undefined,
  ]);

  const rows = sortBy(
    outgoingPayments.map((x) => ({
      ...x,
      amountBtc: +toFormattedBtc(x.amountSatoshi),
      isDeleteable:
        (!!onDelete && !x.confirmedAt && !x.executedAt && subSeconds(new Date(x.executeAfter), 10) > new Date()) ??
        false,
    })) ?? [],
    (x) => -new Date(x.createdAt).getTime()
  );

  return (
    <Box pt={2} gap={2}>
      <Typography variant="h6">{title}</Typography>
      {explainer}
      <Box sx={{ height }} width={"100%"}>
        <DataGridPro
          rows={rows}
          rowCount={rows.length}
          columns={COLUMNS}
          pagination
          paginationMode="client"
          sortingMode="client"
          disableColumnFilter={false}
          pageSizeOptions={[25, 50, 100]}
          sx={{
            bgcolor: "white",
            boxShadow: 3,
            padding: 1,
            maxHeight: height,
            width: "100%",
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                height={"100%"}
                sx={{ minHeight: "60px" }}
              >
                <Typography>No payments found.</Typography>
              </Stack>
            ),
          }}
        ></DataGridPro>
      </Box>
    </Box>
  );
};
