import { Box, Chip, Typography } from "@mui/material";
import { ReactNode } from "react";
import { ResAdminLoanDtoStatusEnum, ResLpLoanDtoStatusEnum, ResMinerLoanDtoStatusEnum } from "../../../app/model/api";
import { checkExhaustive } from "../../Admin/MinerDetails/Opportunity/simulation/simulationTimelineItems";

export const getAdminLoanStatusChip = (status: ResAdminLoanDtoStatusEnum) => {
  switch (status) {
    case ResAdminLoanDtoStatusEnum.Active:
      return <Chip color="info" label={status} />;
    case ResAdminLoanDtoStatusEnum.Cancelled:
      return <Chip color="warning" label={status} />;
    case ResAdminLoanDtoStatusEnum.Completed:
      return <Chip color="success" label={status} />;
    case ResAdminLoanDtoStatusEnum.Draft:
      return <Chip color="default" label={status} />;
    case ResAdminLoanDtoStatusEnum.UnderDelivered:
      return <Chip color="error" label={"Under delivered"} />;
    case ResAdminLoanDtoStatusEnum.Ready:
      return <Chip color="info" label={status} />;
    default:
      checkExhaustive(status);
  }
};

export const getLpLoanStatusChip = (status: ResLpLoanDtoStatusEnum) => {
  switch (status) {
    case ResLpLoanDtoStatusEnum.Active:
      return <Chip color="info" label={status} />;
    case ResLpLoanDtoStatusEnum.Cancelled:
      return <Chip color="warning" label={status} />;
    case ResLpLoanDtoStatusEnum.Completed:
      return <Chip color="success" label={status} />;
    case ResLpLoanDtoStatusEnum.Draft:
      return <Chip color="default" label={status} />;
    case ResLpLoanDtoStatusEnum.UnderDelivered:
      return <Chip color="error" label={"Under delivered"} />;
    case ResLpLoanDtoStatusEnum.Ready:
      return <Chip color="info" label={status} />;
    default:
      checkExhaustive(status);
  }
};

export const getMinerLoanStatusChip = (status: ResMinerLoanDtoStatusEnum) => {
  switch (status) {
    case ResMinerLoanDtoStatusEnum.Active:
      return <Chip color="info" label={status} />;
    case ResMinerLoanDtoStatusEnum.Cancelled:
      return <Chip color="warning" label={status} />;
    case ResMinerLoanDtoStatusEnum.Completed:
      return <Chip color="success" label={status} />;
    case ResMinerLoanDtoStatusEnum.Draft:
      return <Chip color="default" label={status} />;
    case ResMinerLoanDtoStatusEnum.UnderDelivered:
      return <Chip color="error" label={"Under delivered"} />;
    case ResMinerLoanDtoStatusEnum.Ready:
      return <Chip color="info" label={status} />;
    default:
      checkExhaustive(status);
  }
};

export const LoanTitle = ({ id, statusChip, isDemo }: { id: string; statusChip: ReactNode; isDemo: boolean }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {isDemo ? <Chip label="Demo loan" variant="filled" color="primary" /> : null}
      <Typography variant="h5" sx={{ textDecoration: "underline" }}>
        Loan #{id}
      </Typography>
      {statusChip}
    </Box>
  );
};
