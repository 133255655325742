import HeroBox from "../../atoms/HeroBox";

import { amountFormat, amountFormatSatoshisToBTC, roundToSignificantDigitsDiv1000 } from "../../../app/helper/utils";
import { useQueryGetMinerOpportunitiesSummary } from "../../../app/query/useQueryGetUser";

import heroReservedBck from "../../../assets/images/hero-bck-1.png";
import heroBalanceBck from "../../../assets/images/hero-bck-2.png";
import heroFeesBck from "../../../assets/images/hero-bck-3.png";

import Loader from "../../atoms/Loader";
import "./style.scss";

const HeroMinerOpportunities = () => {
  const { data, isFetching } = useQueryGetMinerOpportunitiesSummary();

  return (
    <div className="hero !bg-miner-wallet">
      <div className="container-custom mx-auto !py-16">
        <div className="grid grid-cols-3 gap-4 pb-5">
          <HeroBox title="Allocated hashrate" backgroundImage={heroBalanceBck}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="crypto-value">
                {roundToSignificantDigitsDiv1000(data?.allocatedHashRate ?? 0, 1)} PH/s
              </div>
            )}
          </HeroBox>
          <HeroBox title="Current collateral locked" backgroundImage={heroFeesBck}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <>
                <div className="crypto-value">{amountFormatSatoshisToBTC(data?.currentCollateralLocked?.btc || 0)}</div>
                <sup>BTC</sup>
                <div className="fiat-value">{amountFormat(data?.currentCollateralLocked?.usd ?? 0)}</div>
              </>
            )}
          </HeroBox>
          <HeroBox title="All Time Liquidity received" backgroundImage={heroReservedBck}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <>
                <div className="crypto-value">
                  {amountFormatSatoshisToBTC(data?.allTimeLiquidityReceived?.btc || 0)}
                </div>
                <sup>BTC</sup>
                <div className="fiat-value">{amountFormat(data?.allTimeLiquidityReceived?.usd ?? 0)}</div>
              </>
            )}
          </HeroBox>
        </div>
      </div>
    </div>
  );
};

export default HeroMinerOpportunities;
