import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import {
  MarkAsReadDto,
  RegisterLpDto,
  RegisterMinerDto,
  RejectOrAcceptProposalDto,
  WithdrawalAddressDto,
} from "../model/api";

interface Notifications {
  notifications: string[];
}

interface InitiateOpportunity {
  amountSatoshis: string;
  opportunityId: string;
}

const changePassword = async () => {
  return await apig.user.postUserPassword();
};

const createWhitelistAddress = async (data: WithdrawalAddressDto) => {
  return await apig.user.putUserAddressToWhitelist(data);
};

const removeWhitelist = async (id: string) => {
  return await apig.user.removeWhitelistAddress({ id });
};

const markNotificationAsRead = async (dto: MarkAsReadDto) => {
  return await apig.notification.markNotificationAsRead(dto);
};

const initiateOpportunity = async (data: InitiateOpportunity) => {
  return await apig.opportunity.initiateOpportunityFunding(data);
};

const approveOrRejectWhitelistProposal = async (data: RejectOrAcceptProposalDto) => {
  return await apig.user.rejectOrApproveProposal(data);
};

const useMutationRegisterLP = () => {
  return useMutation((data: RegisterLpDto) => apig.entrypoint.registerLp(data));
};

const useMutationRegisterMiner = () => {
  return useMutation((data: RegisterMinerDto) => apig.entrypoint.registerMiner(data));
};

const useMutationChangePassword = () => {
  return useMutation(() => changePassword());
};

const useMutationWhitelist = () => {
  return useMutation((data: WithdrawalAddressDto) => createWhitelistAddress(data));
};

const useMutationRemoveWhitelist = () => {
  return useMutation((id: string) => removeWhitelist(id));
};

const useMutationUserNotification = () => {
  return useMutation((notifications: Notifications) => markNotificationAsRead(notifications));
};

const useMutationInitiateOpportunity = () => {
  return useMutation((data: InitiateOpportunity) => initiateOpportunity(data));
};

const useMutationApproveOrRejectWhitelistProposal = () => {
  return useMutation((data: RejectOrAcceptProposalDto) => approveOrRejectWhitelistProposal(data));
};

export {
  useMutationApproveOrRejectWhitelistProposal,
  useMutationChangePassword,
  useMutationInitiateOpportunity,
  useMutationRegisterLP,
  useMutationRegisterMiner,
  useMutationRemoveWhitelist,
  useMutationUserNotification,
  useMutationWhitelist,
};
