import { useParams } from "react-router-dom";

import { amountFormatSatoshisToBTC, roundToSignificantDigitsDiv1000 } from "../../../app/helper/utils";
import { useGetQueryAdminOpportunitySummary } from "../../../app/query/useGetQueryOpportunity";

import { Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { StyledTableRow } from "../../../pages/Admin";
import { TableAmountCell } from "../../atoms/TableAmountCell";

export const HeroOpportunityDetails = () => {
  const { id } = useParams();
  const { data, isFetching } = useGetQueryAdminOpportunitySummary(String(id));

  return isFetching ? (
    <Skeleton width="100%" variant="rounded" height={80} />
  ) : (
    <Grid container item sm={12} pt={1}>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <StyledTableRow key={"th-op-info-0"}>
              <TableCell size="small" align="center">
                Expected return
              </TableCell>
              <TableCell size="small" align="center">
                Liquidity received
              </TableCell>
              <TableCell size="small" align="center">
                Current hashrate allocated
              </TableCell>
              <TableCell size="small" align="center">
                Maximum capacity
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <TableRow key={"row-dates-info-1"}>
              <TableCell size="small" align="center">
                {`${data?.expectedReturn || 0}%`}
              </TableCell>
              <TableCell size="small" align="center">
                <TableAmountCell
                  type="Deposit"
                  amountBtc={+amountFormatSatoshisToBTC(data?.liquidityReceived?.btc || 0)}
                  amountUsd={data?.liquidityReceived?.usd || 0}
                />
              </TableCell>
              <TableCell size="small" align="center">
                {`${roundToSignificantDigitsDiv1000(data?.hashrateAllocated?.currentHashRate || 0)} PH/s`}
              </TableCell>
              <TableCell size="small" align="center">
                {`${roundToSignificantDigitsDiv1000(data?.hashrateAllocated?.maxCapacity || 0)} PH/s`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
