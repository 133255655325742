import { useQueryClient } from "@tanstack/react-query";
import { ReactNode, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  ERROR_DEFAULT_MESSAGE,
  INVITE_ORGANIZATION_USER_SCHEMA,
  ROUTE_ENTERPRISE_SETTINGS,
} from "../../../app/constants";
import { isCustomError } from "../../../app/helper/errors";
import { getError } from "../../../app/helper/utils";
import {
  AccessApprovalDto,
  AccessApprovalDtoVerdictEnum,
  AccessProposalDto,
  AuthUserResponseDto,
  CreateProposalDtoRoleEnum,
  InviteOrganizationUserDto,
  OrganizationUserDto,
  OrganizationUserDtoRolesEnum,
  RejectOrAcceptProposalDtoActionTypeEnum,
  RemoveRoleParamsRoleEnum,
} from "../../../app/model/api";
import { Role } from "../../../app/model/permission.type";
import {
  useQueryGetAccessProposal,
  useQueryGetManagerCount,
  useQueryGetOrganizationUsers,
} from "../../../app/query/useGetQueryOrganization";
import {
  useMutationCreateProposal,
  useMutationInviteOrganizationUser,
  useMutationRejectOrApproveProposal,
  useMutationRemoveOrganizationUser,
  useMutationRemoveRole,
} from "../../../app/query/useMutationOrganizationUser";
import { ReactComponent as CheckIcon } from "../../../assets/images/check-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/close-icon.svg";
import { ReactComponent as UserRemove } from "../../../assets/images/user-remove-icon.svg";
import Button from "../../../components/atoms/Button";
import Checkbox from "../../../components/atoms/Checkbox";
import Input from "../../../components/atoms/Input";
import Loader from "../../../components/atoms/Loader";
import SectionTitle from "../../../components/atoms/SectionTitle";
import Pagination from "../../../components/molecules/Pagination";
import Form from "../../../components/organisms/Form";
import Modal from "../../../components/organisms/Modal";
import Table from "../../../components/organisms/Table";
import "./style.scss";

enum UserActionType {
  REMOVE_ROLE = "remove_role",
  REMOVE_USER = "remove_user",
  ADD_ROLE = "add_role",
}
interface ModalData {
  title: string;
  description: ReactNode;
  payload?:
    | {
        userId: string;
        role: RemoveRoleParamsRoleEnum;
        userActionType: UserActionType.REMOVE_ROLE;
      }
    | {
        userId: string;
        userActionType: UserActionType.REMOVE_USER;
      }
    | {
        userId: string;
        role: CreateProposalDtoRoleEnum;
        userActionType: UserActionType.ADD_ROLE;
      };
}

interface ApprovalModalData {
  open: boolean;
  payload: {
    actionType: RejectOrAcceptProposalDtoActionTypeEnum;
    accessProposalId: string;
  };
}

interface ErrorsType {
  [key: string]: string | null;
}

const initModalData = { title: "", description: "" };
const initApprovalModalData = {
  open: false,
  payload: {
    actionType: RejectOrAcceptProposalDtoActionTypeEnum.Reject,
    accessProposalId: "",
  },
};

const roleEnumToCreateProposalRole = (role: OrganizationUserDtoRolesEnum): CreateProposalDtoRoleEnum => {
  switch (role) {
    case OrganizationUserDtoRolesEnum.AccessManager:
      return CreateProposalDtoRoleEnum.AccessManager;
    case OrganizationUserDtoRolesEnum.WalletManager:
      return CreateProposalDtoRoleEnum.WalletManager;
    case OrganizationUserDtoRolesEnum.OpportunityManager:
      return CreateProposalDtoRoleEnum.OpportunityManager;
  }
};

const roleEnumToRemoveRoleRole = (role: OrganizationUserDtoRolesEnum): RemoveRoleParamsRoleEnum => {
  switch (role) {
    case OrganizationUserDtoRolesEnum.AccessManager:
      return RemoveRoleParamsRoleEnum.AccessManager;
    case OrganizationUserDtoRolesEnum.WalletManager:
      return RemoveRoleParamsRoleEnum.WalletManager;
    case OrganizationUserDtoRolesEnum.OpportunityManager:
      return RemoveRoleParamsRoleEnum.OpportunityManager;
  }
};

export const EnterpriseMembers = () => {
  const queryClient = useQueryClient();
  const loggedUser = queryClient.getQueryState<AuthUserResponseDto>(["user"]);

  const [isConfirmationModal, setIsConfirmationModal] = useState<boolean>(false);
  const [isInviteModal, setIsInviteModal] = useState<boolean>(false);
  const [approvalModal, setApprovalModal] = useState<ApprovalModalData>(initApprovalModalData);
  const [modalData, setModalData] = useState<ModalData>(initModalData);
  const [errors, setErrors] = useState<ErrorsType>({});
  const [pageUserTable, setUserTablePage] = useState<number>(1);
  const [pageAccessProposalTable, setAccessProposalPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  const {
    data: organizationUsers,
    isFetching: isFetchingOrganizationUsers,
    refetch,
  } = useQueryGetOrganizationUsers({
    offset: (pageUserTable - 1) * perPage,
    limit: perPage,
  });
  const {
    data: accessProposals,
    isFetching: isFetchingAccessProposal,
    refetch: refetchAccessProposal,
  } = useQueryGetAccessProposal({
    offset: (pageAccessProposalTable - 1) * perPage,
    limit: perPage,
  });
  const { data: totalManagers } = useQueryGetManagerCount();
  const { mutateAsync: inviteUser, isLoading: isInvitingUser } = useMutationInviteOrganizationUser();
  const { mutateAsync: removeUser, isLoading: isRemovingUser } = useMutationRemoveOrganizationUser();
  const { mutateAsync: createProposal } = useMutationCreateProposal();
  const { mutateAsync: rejectOrApproveProposal } = useMutationRejectOrApproveProposal();
  const { mutateAsync: removeRole } = useMutationRemoveRole();

  const requiredAccessApprovers = loggedUser?.data?.enterpriseSettings?.requiredAccessApprovers || 0;
  const requiredWhitelistApprovers = loggedUser?.data?.enterpriseSettings?.requiredWhitelistApprovers || 0;

  const handleAccessChange = (
    role: OrganizationUserDtoRolesEnum,
    value: string | number | boolean,
    user: OrganizationUserDto
  ) => {
    setIsConfirmationModal(true);

    const isOnlyAccessManager = requiredAccessApprovers <= 1;

    const description = !value ? (
      `Removing the ${role} role from ${user.firstName} ${user.lastName}. The user will no longer be able to manage your enterprise’s ${role}`
    ) : (
      <>
        {!isOnlyAccessManager ? (
          <>
            <p>
              Granting the {role} role to {user.firstName} {user.lastName}.
            </p>
            <br></br>
            <p>{requiredAccessApprovers - 1} other Access Managers in your enterprise must approve this proposal.</p>
          </>
        ) : (
          <>
            <p>
              Granting the {role} role to {user.firstName} {user.lastName}.
            </p>
            <br></br>
            <p>
              <span className="text-yellow-500">Warning!</span>{" "}
              {totalManagers?.accessManagerCount === 1 && isOnlyAccessManager
                ? "You are the only access manager in your enterprise"
                : "Your enterprise only requires one approver for access changes"}
              , so these changes are applied without another approver after a 24h delay. For security reasons, we
              strongly recommend adding more Access Managers and increasing the number of approvals required for access
              changes
            </p>
          </>
        )}
      </>
    );

    setModalData({
      title: `Confirm access ${!value ? "removal" : "change"}`,
      description,
      payload: value
        ? {
            userId: user.id,
            role: roleEnumToCreateProposalRole(role),
            userActionType: UserActionType.ADD_ROLE,
          }
        : {
            userId: user.id,
            role: roleEnumToRemoveRoleRole(role),
            userActionType: UserActionType.REMOVE_ROLE,
          },
    });
  };

  const getTooltipContent = ({
    user,
    roleType,
    isPermissionAction,
    proposalAlreadyExists,
    isLastManager,
  }: {
    user: OrganizationUserDto;
    isPermissionAction?: boolean;
    roleType: string;
    proposalAlreadyExists?: boolean;
    isLastManager?: boolean;
  }): string => {
    let content: string = "";

    if (user.id === loggedUser?.data?.id && !isLastManager) {
      content = `You cannot remove your own ${roleType} role.`;
    } else if (isLastManager && !proposalAlreadyExists) {
      content = `Removing this ${
        isPermissionAction ? "permission" : "user"
      } would leave your enterprise without a ${roleType}. At least one user of each role is required.`;
    } else if ((totalManagers?.accessManagerCount || 0) <= 2 && !proposalAlreadyExists) {
      content = `Removing this ${
        isPermissionAction ? "permission" : "user"
      } would leave your enterprise with too few ${roleType}s to approve changes. Please first update the required number of ${roleType}s in your enterprise settings`;
    } else if (proposalAlreadyExists) {
      content = "This particular role proposal is already documented in the access proposal table.";
    }

    return content;
  };

  const handleConfirmAction = async (payload: {
    actionType: RejectOrAcceptProposalDtoActionTypeEnum;
    accessProposalId: string;
  }) => {
    const { actionType, accessProposalId } = payload;

    try {
      await rejectOrApproveProposal({ accessProposalId, actionType });
      refetch();
      refetchAccessProposal();
      setApprovalModal(initApprovalModalData);
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const handleInviteUser = async (data: InviteOrganizationUserDto) => {
    INVITE_ORGANIZATION_USER_SCHEMA.validate(data, { abortEarly: false })
      .then(async (valid) => {
        try {
          await inviteUser(valid);
          refetch();
          refetchAccessProposal();
          setIsInviteModal(false);
          setErrors({});
        } catch (error: unknown) {
          if (isCustomError(error)) toast.error(error.error.message);
          else toast.error(ERROR_DEFAULT_MESSAGE);
        }
      })
      .catch((error) => {
        const errors = error.inner.reduce((prev: any, current: any) => {
          return { ...prev, [current.path]: current.message };
        }, {});

        setErrors(errors);
        toast.error(error?.error?.result?.message || ERROR_DEFAULT_MESSAGE);
      });
  };

  const handleRemove = (user: OrganizationUserDto) => {
    setIsConfirmationModal(true);
    setModalData({
      title: "Confirm access removal",
      description: `Removing ${user.firstName} ${user.lastName} from your enterprise. The user will no longer be able to log into the platform.`,
      payload: { userId: user.id, userActionType: UserActionType.REMOVE_USER },
    });
  };

  const handleConfirm = async () => {
    setIsConfirmationModal(false);

    try {
      if (modalData.payload?.userId) {
        if (modalData.payload?.userActionType === UserActionType.REMOVE_USER) {
          await removeUser(modalData.payload.userId);
        } else {
          if (!modalData.payload?.role) {
            setErrors({ role: "Role is required" });
            return;
          }

          if (modalData.payload?.userActionType === UserActionType.ADD_ROLE) {
            await createProposal({ role: modalData.payload.role, userId: modalData.payload.userId });
          }

          if (modalData.payload?.userActionType === UserActionType.REMOVE_ROLE) {
            await removeRole({ role: modalData.payload.role, userId: modalData.payload.userId });
          }
        }
      }
      refetch();
      refetchAccessProposal();
      setModalData(initModalData);
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const getCurrentApprovals = (approvals: AccessApprovalDto[]): number => {
    return approvals.length === 0
      ? 0
      : approvals.filter((item: AccessApprovalDto) => item.verdict === AccessApprovalDtoVerdictEnum.Approved).length;
  };

  const isUserAlreadyApproveProposal = (approvals: AccessApprovalDto[]): boolean => {
    return approvals.some((item) => item.user?.id === loggedUser?.data?.id);
  };

  const proposalAlreadyExists = (role: OrganizationUserDtoRolesEnum, userId: string): boolean => {
    return !!accessProposals?.rows?.some((item: AccessProposalDto) => item.role === role && item.user.id === userId);
  };

  const isLastManager = (role: OrganizationUserDtoRolesEnum) => {
    const usersWithRole = organizationUsers?.rows.filter(
      (user: OrganizationUserDto) => user.roles?.some((el) => el === role)
    )?.length;

    return usersWithRole === 1;
  };

  const isRoleChecked = (userRoles: OrganizationUserDtoRolesEnum[], targetRole: OrganizationUserDtoRolesEnum) => {
    return userRoles?.some((el) => el === targetRole);
  };

  const canRemoveUser = (user: OrganizationUserDto) => {
    // At least one user with each role (enforced when removing roles or removing users)
    const accessManagerTotal =
      (totalManagers?.accessManagerCount ?? 0) -
      (user.roles?.some((item) => item === OrganizationUserDtoRolesEnum.AccessManager) ? 1 : 0);
    const accessWalletTotal =
      (totalManagers?.walletManagerCount ?? 0) -
      (user.roles?.some((item) => item === OrganizationUserDtoRolesEnum.WalletManager) ? 1 : 0);
    const accessOpportunityTotal =
      (totalManagers?.opportunityManagerCount ?? 0) -
      (user.roles?.some((item) => item === OrganizationUserDtoRolesEnum.OpportunityManager) ? 1 : 0);
    OrganizationUserDtoRolesEnum.OpportunityManager;

    // At least x Access Manager users, where x is the “Required access change approvers” setting
    const insufficientAccessApprovers = requiredAccessApprovers > accessManagerTotal;
    // At least y Wallet Manager users, where x is the “Required wallet whitelist approvers” setting
    const insufficientWalletApprovers = requiredWhitelistApprovers > accessWalletTotal;

    const isAnyRoleManagerEmpty = accessManagerTotal === 0 || accessWalletTotal === 0 || accessOpportunityTotal === 0;

    return (
      user.id !== loggedUser?.data?.id ||
      !isAnyRoleManagerEmpty ||
      !insufficientAccessApprovers ||
      !insufficientWalletApprovers
    );
  };

  const paginationAccessProposalPages = accessProposals?.count ? Math.ceil(accessProposals?.count / perPage) : 0;
  const paginationUserPages = organizationUsers?.count ? Math.ceil(organizationUsers?.count / perPage) : 0;

  return (
    <div className="enterprise-member-wrapper">
      <div className="container-custom mx-auto">
        <div className="py-[14px] font-pp-neue-machina text-[#667085]">
          <p>
            Manage the access of users in your enterprise. Adding new permissions will require approval from other
            access managers.
          </p>
          <p>Note: a user without any of the below roles will be a readonly user / auditor.</p>

          <div className="mt-2">
            <h5 className="font-bold">Access Manager</h5>
            <ol className="pl-2 my-2 list-inside list-disc">
              <li>Invite new users to the organization</li>
              <li>Propose access changes</li>
              <li>Approve or reject access change proposals</li>
            </ol>
            <p>
              Note: the number of approvals required for access changes is determined by your{" "}
              <Link className="text-purple" to={ROUTE_ENTERPRISE_SETTINGS}>
                enterprise settings
              </Link>
            </p>
          </div>
          <div className="mt-2">
            <h5 className="font-bold">Wallet Manager</h5>
            <ol className="pl-2 my-2 list-inside list-disc">
              <li>Propose new whitelisted addresses</li>
              <li>Approve new whitelisted addresses</li>
              <li>Remove whitelisted addresses</li>
              <li>Withdraw to whitelisted addresses</li>
            </ol>
            <p>
              Note: the number of approvals required for whitelist addresses is determined by your{" "}
              <Link className="text-purple" to={ROUTE_ENTERPRISE_SETTINGS}>
                enterprise settings
              </Link>
            </p>
          </div>
          <div className="mt-2">
            <h5 className="font-bold">Opportunity Manager</h5>
            <ol className="pl-2 my-2 list-inside list-disc">
              <li>For LPs, fund a new opportunity</li>
              <li>For miners, top up opportunity collateral</li>
            </ol>
          </div>
        </div>
        <div className="py-10">
          <Button
            className="w-auto text-white bg-purple mb-6"
            label="Invite user"
            onClick={() => setIsInviteModal(true)}
          />

          {accessProposals && accessProposals?.rows?.length > 0 ? (
            <div>
              <SectionTitle title="Pending Access Changes" />
              <Table>
                <Table.Head>
                  <Table.TR>
                    <Table.TH>Proposal</Table.TH>
                    <Table.TH>Status</Table.TH>
                    <Table.TH>Proposed By</Table.TH>
                    <Table.TH />
                  </Table.TR>
                </Table.Head>
                <Table.Body>
                  {!isFetchingAccessProposal &&
                    accessProposals?.rows?.map((proposal: AccessProposalDto) => {
                      return (
                        <Table.TR key={proposal?.id}>
                          <Table.TD>
                            {`Grant "${proposal.role}" permission to ${proposal.user.firstName} ${proposal.user.lastName}`}
                          </Table.TD>
                          <Table.TD>
                            <p>
                              {getCurrentApprovals(proposal.accessApproval)}/{requiredAccessApprovers} approvals
                            </p>
                            {getCurrentApprovals(proposal.accessApproval) === requiredAccessApprovers ? (
                              <p>24h hold</p>
                            ) : null}
                          </Table.TD>
                          <Table.TD>
                            {proposal.proposer?.firstName} {proposal.proposer?.lastName}
                          </Table.TD>
                          <Table.TD>
                            <div className="flex gap-3 justify-end">
                              <div className="inline-block w-auto">
                                <Button
                                  backgroundColor="#8f49fd"
                                  className="w-[30px] p-0"
                                  label=""
                                  notAllowed={isUserAlreadyApproveProposal(proposal.accessApproval)}
                                  icon={<CheckIcon />}
                                  onClick={() =>
                                    setApprovalModal({
                                      open: true,
                                      payload: {
                                        actionType: RejectOrAcceptProposalDtoActionTypeEnum.Approve,
                                        accessProposalId: proposal.id,
                                      },
                                    })
                                  }
                                />
                              </div>
                              <div className="inline-block w-auto">
                                <Button
                                  backgroundColor="#8f49fd"
                                  className="w-[30px] p-0"
                                  label=""
                                  icon={<CloseIcon />}
                                  onClick={() =>
                                    setApprovalModal({
                                      open: true,
                                      payload: {
                                        actionType: RejectOrAcceptProposalDtoActionTypeEnum.Reject,
                                        accessProposalId: proposal.id,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </Table.TD>
                        </Table.TR>
                      );
                    })}
                </Table.Body>
              </Table>
              {paginationAccessProposalPages > 1 && (
                <Pagination
                  page={pageAccessProposalTable}
                  paginationPages={paginationAccessProposalPages}
                  onPrev={() => setAccessProposalPage((prev: number) => prev - 1)}
                  onNext={() => setAccessProposalPage((prev: number) => prev + 1)}
                />
              )}
            </div>
          ) : null}

          <div className="user-table">
            <SectionTitle title="Users and Roles" />
            <Table>
              <Table.Head>
                <Table.TR>
                  <Table.TH>User</Table.TH>
                  <Table.TH className="!text-center">Access Manager</Table.TH>
                  <Table.TH className="!text-center">Wallet Manager</Table.TH>
                  <Table.TH className="!text-center">Opportunity Manager</Table.TH>
                  <Table.TH />
                </Table.TR>
              </Table.Head>
              <Table.Body>
                {!isFetchingOrganizationUsers &&
                  !isRemovingUser &&
                  organizationUsers?.rows?.map((user) => {
                    return (
                      <Table.TR key={user.id}>
                        <Table.TD>
                          <div className="leading-4">{`${user.firstName} ${user.lastName}`}</div>
                        </Table.TD>
                        <Table.TD className="text-center">
                          <Checkbox
                            name={`isAccessManager_${user.id}`}
                            label={""}
                            disabled={
                              user.id === loggedUser?.data?.id ||
                              proposalAlreadyExists(OrganizationUserDtoRolesEnum.AccessManager, user.id)
                            }
                            tooltip={{
                              content: getTooltipContent({
                                user,
                                roleType: Role.ACCESS_MANAGER,
                                isPermissionAction: true,
                                proposalAlreadyExists: proposalAlreadyExists(
                                  OrganizationUserDtoRolesEnum.AccessManager,
                                  user.id
                                ),
                              }),
                              arrowPosition: "center",
                            }}
                            checked={isRoleChecked(user.roles, OrganizationUserDtoRolesEnum.AccessManager)}
                            onChange={(_, value) =>
                              user.id !== loggedUser?.data?.id
                                ? handleAccessChange(OrganizationUserDtoRolesEnum.AccessManager, value, user)
                                : null
                            }
                          />
                        </Table.TD>
                        <Table.TD className="text-center">
                          <Checkbox
                            name={`isWalletManager_${user.id}`}
                            label={""}
                            disabled={
                              proposalAlreadyExists(OrganizationUserDtoRolesEnum.WalletManager, user.id) ||
                              isRoleChecked(user.roles, OrganizationUserDtoRolesEnum.WalletManager)
                                ? isLastManager(OrganizationUserDtoRolesEnum.WalletManager)
                                : false
                            }
                            tooltip={{
                              content: getTooltipContent({
                                user,
                                roleType: Role.WALLET_MANAGER,
                                isPermissionAction: true,
                                proposalAlreadyExists: proposalAlreadyExists(
                                  OrganizationUserDtoRolesEnum.WalletManager,
                                  user.id
                                ),
                                ...(isRoleChecked(user.roles, OrganizationUserDtoRolesEnum.WalletManager)
                                  ? { isLastManager: isLastManager(OrganizationUserDtoRolesEnum.WalletManager) }
                                  : null),
                              }),
                              arrowPosition: "center",
                            }}
                            checked={isRoleChecked(user.roles, OrganizationUserDtoRolesEnum.WalletManager)}
                            onChange={(_, value) =>
                              !proposalAlreadyExists(OrganizationUserDtoRolesEnum.WalletManager, user.id)
                                ? handleAccessChange(OrganizationUserDtoRolesEnum.WalletManager, value, user)
                                : null
                            }
                          />
                        </Table.TD>
                        <Table.TD className="text-center">
                          <Checkbox
                            name={`isOpportunityManager_${user.id}`}
                            label={""}
                            disabled={
                              proposalAlreadyExists(OrganizationUserDtoRolesEnum.OpportunityManager, user.id) ||
                              isRoleChecked(user.roles, OrganizationUserDtoRolesEnum.OpportunityManager)
                                ? isLastManager(OrganizationUserDtoRolesEnum.OpportunityManager)
                                : false
                            }
                            tooltip={{
                              content: getTooltipContent({
                                user,
                                roleType: OrganizationUserDtoRolesEnum.OpportunityManager,
                                isPermissionAction: true,
                                proposalAlreadyExists: proposalAlreadyExists(
                                  OrganizationUserDtoRolesEnum.OpportunityManager,
                                  user.id
                                ),
                                ...(isRoleChecked(user.roles, OrganizationUserDtoRolesEnum.OpportunityManager)
                                  ? { isLastManager: isLastManager(OrganizationUserDtoRolesEnum.OpportunityManager) }
                                  : null),
                              }),
                              arrowPosition: "center",
                            }}
                            checked={isRoleChecked(user.roles, OrganizationUserDtoRolesEnum.OpportunityManager)}
                            onChange={(_, value) =>
                              !proposalAlreadyExists(OrganizationUserDtoRolesEnum.OpportunityManager, user.id)
                                ? handleAccessChange(OrganizationUserDtoRolesEnum.OpportunityManager, value, user)
                                : null
                            }
                          />
                        </Table.TD>
                        <Table.TD>
                          <div className="inline-block w-auto">
                            <Button
                              label=""
                              className="w-auto text-error"
                              notAllowed={!canRemoveUser(user)}
                              tooltip={{
                                content: "You cannot remove yourself.",
                                arrowPosition: "center",
                              }}
                              onClick={() => (canRemoveUser(user) ? handleRemove(user) : null)}
                              icon={<UserRemove width={22} height={22} />}
                            />
                          </div>
                        </Table.TD>
                      </Table.TR>
                    );
                  })}
                {organizationUsers?.rows?.length === 0 && !isFetchingOrganizationUsers && (
                  <Table.TR>
                    <Table.TD colSpan={5} className="relative text-center !h-[150px]">
                      No results.
                    </Table.TD>
                  </Table.TR>
                )}
                {(isRemovingUser || isFetchingOrganizationUsers) && (
                  <Table.Loader>
                    <Table.TD colSpan={5} className="relative !h-[865px]">
                      <Loader />
                    </Table.TD>
                  </Table.Loader>
                )}
              </Table.Body>
            </Table>
            {paginationUserPages > 1 && (
              <Pagination
                page={pageUserTable}
                paginationPages={paginationUserPages}
                onPrev={() => setUserTablePage((prev: number) => prev - 1)}
                onNext={() => setUserTablePage((prev: number) => prev + 1)}
              />
            )}
          </div>
        </div>
      </div>

      <Modal open={approvalModal.open}>
        <div className="flex items-center gap-[10px] mb-8">
          <h1 className="font-pp-neue-machina font-semibold text-[30px]">Access proposal</h1>
        </div>
        <p className="font-pp-neue-machina">{`Are you sure you want to ${approvalModal.payload.actionType} the proposal?`}</p>
        <div className="flex gap-4 mt-10">
          <Button
            label="Cancel"
            backgroundColor="#F9F5FF"
            color="#8F49FD"
            className="py-[8px] text-[14px] font-inter font-medium"
            onClick={() => setApprovalModal(initApprovalModalData)}
          />
          <Button
            label="Confirm"
            backgroundColor="#8F49FD"
            color="#ffffff"
            className="py-[8px] text-[14px] font-inter font-medium"
            onClick={() => handleConfirmAction(approvalModal.payload)}
          />
        </div>
      </Modal>
      <Modal open={isConfirmationModal}>
        <div className="flex items-center gap-[10px] mb-8">
          <h1 className="font-pp-neue-machina font-semibold text-[30px]">{modalData.title}</h1>
        </div>
        <div className="font-pp-neue-machina">{modalData.description}</div>
        <div className="flex gap-4 mt-10">
          <Button
            label="Cancel"
            backgroundColor="#F9F5FF"
            color="#8F49FD"
            className="py-[8px] text-[14px] font-inter font-medium"
            onClick={() => setIsConfirmationModal(false)}
          />
          <Button
            label="Confirm"
            backgroundColor="#8F49FD"
            color="#ffffff"
            className="py-[8px] text-[14px] font-inter font-medium"
            onClick={() => handleConfirm()}
          />
        </div>
      </Modal>
      <Modal open={isInviteModal} onClose={() => setIsInviteModal(false)}>
        <div className="flex items-center gap-[10px] mb-8">
          <h1 className="font-pp-neue-machina font-semibold text-[30px]">Invite user</h1>
        </div>
        <div>
          <Form
            onSubmit={(data) => handleInviteUser(data)}
            onChange={(name, value) => {
              setErrors((prev: ErrorsType) => {
                if (value) {
                  return { ...prev, [name]: null };
                }

                return prev;
              });
            }}
          >
            <Input
              label="First Name"
              className="mb-6"
              placeholder="First Name"
              name="firstName"
              error={getError(errors, "firstName")}
            />
            <Input
              label="Last Name"
              className="mb-6"
              placeholder="Last Name"
              name="lastName"
              error={getError(errors, "lastName")}
            />
            <Input label="Email" className="mb-6" placeholder="Email" name="email" error={getError(errors, "email")} />
            <div className="enterprise-member-checkbox-holer flex flex-col gap-4 pl-4 mb-6">
              <Checkbox name="isAccessManager" label="Access Manager" />
              <Checkbox name="isWalletManager" label="Wallet Manager" />
              <Checkbox name="isOpportunityManager" label="Opportunity Manager" />
            </div>
            <Button type="submit" disabled={isInvitingUser} className="bg-purple text-white" label="Submit" />
          </Form>
        </div>
      </Modal>
    </div>
  );
};
