import DemoIcon from "@mui/icons-material/BuildCircle";
import { LoadingButton } from "@mui/lab";
import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import Decimal from "decimal.js";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MINER_ORGANIZATION_PHYSICAL_OPPORTUNITY,
  MINER_ORGANIZATION_SYNTHETIC_OPPORTUNITY,
  ROUTES_LOANS,
  ROUTE_ADMIN_OPPORTUNITY_DETAILS,
} from "../../app/constants";
import { amountFormatSatoshisToBTC, roundToSignificantDigits } from "../../app/helper/utils";
import {
  GenericOpportunityBasic,
  GenericOpportunityBasicTypeEnum,
  GetOrganizationsParamsActionFirstEnum,
  GetOrganizationsParamsUserTypeEnum,
} from "../../app/model/api";
import { useQueryAdminGetOrganizations, useQueryGetAdminAllOpportunities } from "../../app/query/useQueryGetAdmin";

const getCreateSyntheticOpportunityScreenUrl = (minerId: string): string => {
  return MINER_ORGANIZATION_SYNTHETIC_OPPORTUNITY.replace(":id", minerId);
};

const getCreatePhysicalOpportunityScreenUrl = (minerId: string): string => {
  return MINER_ORGANIZATION_PHYSICAL_OPPORTUNITY.replace(":id", minerId);
};

type LiquidityReceivedParam = {
  current?: number;
  total?: number;
};

const getPercentage = (liquidityReceived: LiquidityReceivedParam | undefined) => {
  const percent = new Decimal(liquidityReceived?.current || 0)
    .mul(100)
    .div(liquidityReceived?.total || 0)
    .toNumber();

  return parseFloat(percent.toFixed(2));
};

const COLUMNS: readonly GridColDef<GenericOpportunityBasic>[] = [
  {
    field: "idNum",
    sortable: false,
    headerName: "Id",
    disableColumnMenu: true,
    flex: 1,
    renderCell: (entry) => {
      const isLoan = entry.row.type === GenericOpportunityBasicTypeEnum.Loan;
      const detailsRoute = isLoan ? ROUTES_LOANS.Admin.LoanDetails : ROUTE_ADMIN_OPPORTUNITY_DETAILS;

      return (
        <Link to={detailsRoute.replace(":id", String(entry.id))} style={{ width: "100%", height: "100%" }}>
          <Box display="flex" alignItems="center" height="100%" gap={1}>
            <Typography className="text-purple">#{entry.row.idNum}</Typography>
            <Chip
              label={`${entry.row.type}`}
              size="small"
              color={
                entry.row.type === GenericOpportunityBasicTypeEnum.PhysicalStreaming
                  ? "primary"
                  : entry.row.type === GenericOpportunityBasicTypeEnum.SyntheticStreaming
                  ? "secondary"
                  : entry.row.type === GenericOpportunityBasicTypeEnum.Loan
                  ? "info"
                  : "default"
              }
            />
            {entry.row.isDemo ? (
              <Tooltip title="Demo opportunity">
                <DemoIcon fontSize="small" color="warning" />
              </Tooltip>
            ) : null}
          </Box>
        </Link>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => (
      <Box display="flex" alignItems="center" height="100%">
        <Typography>{params.value}</Typography>
      </Box>
    ),
  },
  {
    field: "minerName",
    headerName: "Miner",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => (
      <Box display="flex" alignItems="center" height="100%">
        <Typography>{params.value}</Typography>
      </Box>
    ),
  },
  {
    field: "remainingDuration",
    headerName: "Remaining duration",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => (
      <Box display="flex" alignItems="center" height="100%">
        <Typography>{params.value ?? "-"}</Typography>
      </Box>
    ),
  },
  {
    field: "fundingDeadline",
    headerName: "Funding deadline",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => (
      <Box display="flex" alignItems="center" height="100%">
        <Typography>{params.value ?? "-"}</Typography>
      </Box>
    ),
  },
  {
    field: "totalHashrate",
    headerName: "Total hashrate",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      const isLoan = params.row.type === GenericOpportunityBasicTypeEnum.Loan;
      return (
        <Box display="flex" alignItems="center" height="100%">
          <Typography>{isLoan ? "-" : `${roundToSignificantDigits(+(params.value ?? 0))} PH/s`}</Typography>
        </Box>
      );
    },
  },
  {
    field: "liquidityReceived",
    headerName: "Liquidity received",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      const isLoan = params.row.type === GenericOpportunityBasicTypeEnum.Loan;
      return (
        <Box display="flex" alignItems="center" height="100%">
          <Typography>
            {isLoan
              ? "-"
              : `${getPercentage(params.value)}% (
              ${params.value?.current ? amountFormatSatoshisToBTC(params.value.current) : 0} /
              ${params.value?.total ? amountFormatSatoshisToBTC(params.value.total) : 0}
              BTC )`}
          </Typography>
        </Box>
      );
    },
  },
];

type RowPagination = {
  readonly page: number;
  readonly pageSize: number;
};

export const AdminAllOpportunitiesPage = () => {
  const navigate = useNavigate();
  const [selectedMinerId, setSelectedMinerId] = useState<string>("");
  const [paginationModel, setPaginationModel] = useState<RowPagination>({
    page: 0,
    pageSize: 25,
  });

  const { data: miners, isFetching: isFetchingMiners } = useQueryAdminGetOrganizations({
    actionFirst: GetOrganizationsParamsActionFirstEnum.No,
    userType: GetOrganizationsParamsUserTypeEnum.Miner,
  });

  const viableMiners = miners?.rows
    .filter((x) => x.onboardingStatus === "Fully onboarded")
    .map((x) => ({ id: x.id, name: x.company ? `${x.company} (${x.email})` : x.email }));

  const { data, isFetching } = useQueryGetAdminAllOpportunities({});

  return (
    <Box display="flex" justifyContent={"center"}>
      <Box width="100%" maxWidth={1800} bgcolor={"white"} pl={2} pr={2} pb={2} pt={2}>
        {/* TODO: Add filters */}
        {/* <Grid container item xs={12}>
          <Grid container item xs={12} sm={12} md={6} alignItems={"center"} gap={2} pl={2}>
            <FormControl sx={{ width: 250 }}>
              <InputLabel size="small" id="select-status">
                Status
              </InputLabel>
              <Select
                labelId="select-status"
                label={"Status"}
                id="select-status"
                value={status}
                size="small"
                onChange={(newValue) => {
                  if (newValue.target.value === "All") setStatus("All");
                  else {
                    setStatus(newValue.target.value as OpportunityAnyTypeStatus);
                  }

                  setPaginationModel({
                    page: 0,
                    pageSize: paginationModel.pageSize,
                  });
                }}
              >
                {ADMIN_OPPORTUNITIES_STATUS.map((action) => (
                  <MenuItem key={"key-select-status" + action.value} value={action.value}>
                    {action.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid> */}
        <Box sx={{ height: "80vh", display: "flex", flexDirection: "column", pt: 2 }}>
          <DataGridPro
            rows={data?.rows ?? []}
            columns={COLUMNS}
            loading={isFetching}
            pagination
            paginationMode="client"
            sortingMode="client"
            disableColumnFilter={true}
            pageSizeOptions={[25, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sx={{
              bgcolor: "white",
              boxShadow: 3,
              padding: 1,
              maxHeight: "80vh",
            }}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  sx={{ minHeight: "60px" }}
                >
                  <Typography>No transactions found.</Typography>
                </Stack>
              ),
            }}
          />
        </Box>
        <Grid container item xs={12} alignItems={"center"} justifyContent={"flex-end"} pt={2}>
          <Box display="flex" gap={2}>
            {!!viableMiners?.length && (
              <FormControl sx={{ width: 350 }}>
                <InputLabel size="small" id="select-minerid">
                  Select a miner to create an opportunity
                </InputLabel>
                <Select
                  defaultValue=""
                  labelId="select-minerid"
                  label={"Select a miner to create an opportunity"}
                  id="select-minerid"
                  size="small"
                  value={selectedMinerId}
                  onChange={(newValue) => {
                    setSelectedMinerId(newValue.target.value);
                  }}
                >
                  {viableMiners?.map((action) => (
                    <MenuItem key={"key-select-minerid" + action.id} value={action.id}>
                      {action.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <LoadingButton
              size="small"
              variant="contained"
              disabled={!viableMiners?.length || !selectedMinerId}
              loading={isFetchingMiners}
              onClick={() => {
                if (selectedMinerId) navigate(getCreateSyntheticOpportunityScreenUrl(selectedMinerId));
              }}
            >
              New synthetic opportunity
            </LoadingButton>

            <LoadingButton
              size="small"
              variant="contained"
              disabled={!viableMiners?.length || !selectedMinerId}
              loading={isFetchingMiners}
              onClick={() => {
                if (selectedMinerId) navigate(getCreatePhysicalOpportunityScreenUrl(selectedMinerId));
              }}
            >
              New physical opportunity
            </LoadingButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
