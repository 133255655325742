import { Box, Container, Paper, Skeleton, TableSortLabel, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import React from "react";
import {
  GetComputedHashpricesParamsOrderByEnum,
  GetComputedHashpricesParamsOrderDirectionEnum,
} from "../../../app/model/api";
import { useGetAdminComputedHashprices } from "../../../app/query/useQueryGetAdmin";

interface Column {
  id: string;
  label: string;
  orderField?: GetComputedHashpricesParamsOrderByEnum | undefined;
  minWidth?: number;
  width?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "createdAt", label: "Created at", orderField: GetComputedHashpricesParamsOrderByEnum.CreatedAt },
  {
    id: "totalSubsidySatoshis",
    label: "Total subsidy (satoshi)",
    minWidth: 140,
    orderField: GetComputedHashpricesParamsOrderByEnum.TotalSubsidySatoshis,
  },
  {
    id: "fppsHashpriceSatoshisPerTh",
    label: "Hashprice (SAT/TH)",
    orderField: GetComputedHashpricesParamsOrderByEnum.FppsHashpriceSatoshisPerTh,
  },
  {
    id: "effectiveDifficulty",
    label: "Effective difficulty",
    orderField: GetComputedHashpricesParamsOrderByEnum.EffectiveDifficulty,
  },
  { id: "blockCount", label: "Block count", orderField: GetComputedHashpricesParamsOrderByEnum.BlockCount },
  { id: "day", label: "Day", orderField: GetComputedHashpricesParamsOrderByEnum.Day },
];

const formatDate = (input: string | Date | number) => {
  const date = new Date(input);
  return format(date, "dd/MM/yy HH:mm");
};

export const AdminComputedHashprices = () => {
  const [orderBy, setOrderBy] = React.useState<GetComputedHashpricesParamsOrderByEnum>(
    GetComputedHashpricesParamsOrderByEnum.Day
  );
  const [orderDirection, setOrderDirection] = React.useState<GetComputedHashpricesParamsOrderDirectionEnum>(
    GetComputedHashpricesParamsOrderDirectionEnum.Desc
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const { data, isFetching } = useGetAdminComputedHashprices({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    orderBy: GetComputedHashpricesParamsOrderByEnum.Day,
    orderDirection: GetComputedHashpricesParamsOrderDirectionEnum.Desc,
  });
  const totalCount = data?.data.count;
  const rows = data?.data.rows;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box width={"100%"}>
      <Container sx={{ background: "white", maxWidth: 1200, pt: 3, pb: 3 }}>
        <Paper sx={{ boxShadow: 5, pt: 1 }}>
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: "red" }}>
                  {columns.map((column) =>
                    column.orderField ? (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sortDirection={orderBy === column.orderField ? orderDirection : undefined}
                      >
                        <TableSortLabel
                          active={orderBy === column.orderField}
                          direction={orderBy === column.orderField ? orderDirection : undefined}
                          onClick={() => {
                            if (column.orderField) {
                              setOrderBy(column.orderField);
                              setOrderDirection(
                                orderDirection === GetComputedHashpricesParamsOrderDirectionEnum.Asc
                                  ? GetComputedHashpricesParamsOrderDirectionEnum.Desc
                                  : GetComputedHashpricesParamsOrderDirectionEnum.Asc
                              );
                            }
                          }}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length
                  ? rows.map((row) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.day + row.day}>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Tooltip title={row.createdAt}>
                              <Typography noWrap>{formatDate(row.createdAt)}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Typography noWrap>{row.totalSubsidySatoshis}</Typography>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Typography noWrap>{row.fppsHashpriceSatoshisPerTh}</Typography>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Typography noWrap>{row.effectiveDifficulty}</Typography>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 100, maxWidth: 100 }}>
                            <Typography noWrap>{row.blockCount}</Typography>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 200, maxWidth: 200 }}>
                            <Typography noWrap>{row.day}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>

            {!isFetching && !rows?.length && (
              <Box width="100%" padding={2} display={"flex"}>
                <Typography width={"100%"} variant="caption" color="silver" textAlign={"center"}>
                  No available data
                </Typography>
              </Box>
            )}

            {isFetching && (
              <Box width="100%" pl={2} pr={2}>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
              </Box>
            )}
          </TableContainer>
          {rows?.length && totalCount ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </Paper>
      </Container>
    </Box>
  );
};
