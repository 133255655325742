import { useState } from "react";

import { HeroOpportunities } from "../../components/organisms/HeroOpportunities";

import {
  ADMIN_OPPORTUNITIES_STATUS,
  MINER_ORGANIZATION_SYNTHETIC_OPPORTUNITY,
  ROUTE_ADMIN_OPPORTUNITY_DETAILS,
} from "../../app/constants";

import DemoIcon from "@mui/icons-material/BuildCircle";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import Decimal from "decimal.js";
import { Link, useNavigate } from "react-router-dom";
import { amountFormatSatoshisToBTC, roundToSignificantDigits } from "../../app/helper/utils";
import {
  GetOrganizationsParamsActionFirstEnum,
  GetOrganizationsParamsUserTypeEnum,
  OpportunityStatus,
  StreamingBasic,
  YesOrNoChoice,
} from "../../app/model/api";
import {
  useQueryAdminGetOrganizations,
  useQueryGetAdminSyntheticStreamingOpportunities,
} from "../../app/query/useQueryGetAdmin";

const getCreateOpportunityScreenUrl = (minerId: string): string => {
  return MINER_ORGANIZATION_SYNTHETIC_OPPORTUNITY.replace(":id", minerId);
};

type LiquidityReceivedParam = {
  current?: number;
  total?: number;
};

const getPercentage = (liquidityReceived: LiquidityReceivedParam | undefined) => {
  const percent = new Decimal(liquidityReceived?.current || 0)
    .mul(100)
    .div(liquidityReceived?.total || 0)
    .toNumber();

  return parseFloat(percent.toFixed(2));
};

const COLUMNS: readonly GridColDef<StreamingBasic>[] = [
  {
    field: "idNum",
    sortable: false,
    headerName: "Id",
    disableColumnMenu: true,
    flex: 1,
    display: "flex",
    renderCell: (entry) => {
      return (
        <Box display="flex" alignItems={"center"} gap={1}>
          <Link className="text-purple" to={ROUTE_ADMIN_OPPORTUNITY_DETAILS.replace(":id", String(entry.id))}>
            #{entry.row.idNum}
          </Link>
          {entry.row.isDemo ? (
            <Tooltip title="Demo opportunity">
              <DemoIcon fontSize="small" sx={{ mt: -0.7 }} color="warning" />
            </Tooltip>
          ) : null}
        </Box>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: "flex",
  },
  {
    field: "miner.company",
    headerName: "Miner",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: "flex",
    renderCell: (entry) => {
      return <Typography>{entry.row.miner?.company}</Typography>;
    },
  },
  {
    field: "remainingDuration",
    headerName: "Remaining duration",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: "flex",
    renderCell: (entry) => {
      return <Typography>{entry.row.remainingDuration ?? "-"}</Typography>;
    },
  },
  {
    field: "fundingDeadline",
    headerName: "Next tranche funding deadline",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: "flex",
    renderCell: (entry) => {
      return <Typography>{entry.row.fundingDeadline ?? "-"}</Typography>;
    },
  },
  {
    field: "currentHashrate",
    headerName: "Current hashrate",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: "flex",
    renderCell: (entry) => {
      return <Typography>{`${roundToSignificantDigits(+(entry.row.currentHashrate ?? 0))} PH/s`}</Typography>;
    },
  },
  {
    field: "liquidityReceived",
    headerName: "Liquidity received",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: "flex",
    renderCell: (entry) => {
      return (
        <Typography>
          {getPercentage(entry.row.liquidityReceived)}% (
          {entry.row.liquidityReceived?.current ? amountFormatSatoshisToBTC(entry.row.liquidityReceived?.current) : 0}/
          {entry.row.liquidityReceived?.total ? amountFormatSatoshisToBTC(entry.row.liquidityReceived?.total) : 0}
          BTC )
        </Typography>
      );
    },
  },
] as const;
type RowPagination = {
  readonly page: number;
  readonly pageSize: number;
};

export const AdminOpportunitiesPage = () => {
  const navigate = useNavigate();
  const [selectedMinerId, setSelectedMinerId] = useState<string>("");
  const [status, setStatus] = useState<OpportunityStatus | "All">("All");
  const [hideSettled, setHideSettled] = useState<YesOrNoChoice>(YesOrNoChoice.No);
  const [paginationModel, setPaginationModel] = useState<RowPagination>({
    page: 0,
    pageSize: 25,
  });

  const { data: miners, isFetching: isFetchingMiners } = useQueryAdminGetOrganizations({
    actionFirst: GetOrganizationsParamsActionFirstEnum.No,
    userType: GetOrganizationsParamsUserTypeEnum.Miner,
  });

  const viableMiners = miners?.rows
    .filter((x) => x.onboardingStatus === "Fully onboarded")
    .map((x) => ({ id: x.id, name: x.company ? `${x.company} (${x.email})` : x.email }));

  const { data, isFetching } = useQueryGetAdminSyntheticStreamingOpportunities({
    offset: paginationModel.pageSize * paginationModel.page,
    limit: paginationModel.pageSize,
    status: status === "All" ? undefined : status,
    hideSettled,
  });

  const handleSettledAction = (value: string | number | boolean) => {
    setHideSettled(value ? YesOrNoChoice.Yes : YesOrNoChoice.No);
  };

  const handlePaginationChange = (input: RowPagination) => {
    if (input.pageSize !== paginationModel.pageSize) {
      setPaginationModel({
        page: 0,
        pageSize: input.pageSize,
      });
    } else if (input.page !== paginationModel.page) {
      setPaginationModel(input);
    }
  };

  return (
    <Box display="flex" justifyContent={"center"}>
      <Box width="100%" maxWidth={1800} bgcolor={"white"} pl={2} pr={2} pb={2}>
        <HeroOpportunities />
        <Grid container item xs={12}>
          <Grid container item xs={12} sm={12} md={6} alignItems={"center"} gap={2} pl={2}>
            <FormControlLabel
              label="Hide settled"
              control={
                <Checkbox
                  checked={hideSettled === YesOrNoChoice.Yes}
                  onChange={(_e, value) => handleSettledAction(value)}
                />
              }
            />
            <FormControl sx={{ width: 250 }}>
              <InputLabel size="small" id="select-status">
                Status
              </InputLabel>
              <Select
                labelId="select-status"
                label={"Status"}
                id="select-status"
                value={status}
                size="small"
                onChange={(newValue) => {
                  if (newValue.target.value === "All") setStatus("All");
                  else {
                    setStatus(newValue.target.value as OpportunityStatus);
                  }

                  setPaginationModel({
                    page: 0,
                    pageSize: paginationModel.pageSize,
                  });
                }}
              >
                {ADMIN_OPPORTUNITIES_STATUS.map((action) => (
                  <MenuItem key={"key-select-status" + action.value} value={action.value}>
                    {action.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ height: "50vh", display: "flex", flexDirection: "column", pt: 2 }}>
          <DataGridPro
            rows={data?.rows ?? []}
            rowCount={data?.count ?? 0}
            columns={COLUMNS}
            loading={isFetching}
            pagination
            paginationMode="server"
            sortingMode="server"
            disableColumnFilter={true}
            pageSizeOptions={[25, 50, 100]}
            paginationModel={paginationModel}
            initialState={{
              pagination: { paginationModel },
            }}
            onPaginationModelChange={(input) => {
              handlePaginationChange({
                page: input.page,
                pageSize: input.pageSize,
              });
            }}
            sx={{
              bgcolor: "white",
              boxShadow: 3,
              padding: 1,
              maxHeight: "50vh",
            }}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  sx={{ minHeight: "60px" }}
                >
                  <Typography>No transactions found.</Typography>
                </Stack>
              ),
            }}
          ></DataGridPro>
        </Box>
        <Grid container item xs={12} alignItems={"center"} justifyContent={"flex-end"} pt={2}>
          <Box display="flex" gap={2}>
            {!!viableMiners?.length && (
              <FormControl sx={{ width: 350 }}>
                <InputLabel size="small" id="select-minerid">
                  Select a miner to create an opportunity
                </InputLabel>
                <Select
                  defaultValue=""
                  labelId="select-minerid"
                  label={"Select a miner to create an opportunity"}
                  id="select-minerid"
                  size="small"
                  value={selectedMinerId}
                  onChange={(newValue) => {
                    setSelectedMinerId(newValue.target.value);
                  }}
                >
                  {viableMiners?.map((action) => (
                    <MenuItem key={"key-select-minerid" + action.id} value={action.id}>
                      {action.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <LoadingButton
              size="small"
              variant="contained"
              disabled={!viableMiners?.length || !selectedMinerId}
              loading={isFetchingMiners}
              sx={{ height: 35, width: 185 }}
              onClick={() => {
                if (selectedMinerId) navigate(getCreateOpportunityScreenUrl(selectedMinerId));
              }}
            >
              Create new opportunity
            </LoadingButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
