import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useState } from "react";
import toast from "react-hot-toast";
import { ERROR_DEFAULT_MESSAGE } from "../../app/constants";
import { AuthUserResponseDto, AuthUserResponseDtoUserTypeEnum } from "../../app/model/api";
import { useMutationChangePassword } from "../../app/query/useMutationUser";
import { ReactComponent as KeyIcon } from "../../assets/images/key-icon.svg";
import { ReactComponent as PasswordIcon } from "../../assets/images/password.svg";
import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import SectionTitle from "../../components/atoms/SectionTitle";
import Modal from "../../components/organisms/Modal";

import "./style.scss";

const Account = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);
  const { mutateAsync, isLoading } = useMutationChangePassword();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleChangePassword = async () => {
    try {
      await mutateAsync();
      setShowModal(true);
    } catch (error) {
      toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  return (
    <div className="account-page-wrapper ">
      <div
        className={classNames("hero-account", {
          "bg-hero-account-admin": user?.data?.userType === AuthUserResponseDtoUserTypeEnum.Admin,
          "bg-hero-account-lp": user?.data?.userType === AuthUserResponseDtoUserTypeEnum.LP,
          "bg-hero-account-miner": user?.data?.userType === AuthUserResponseDtoUserTypeEnum.Miner,
        })}
      ></div>
      <SectionTitle title="Account Details">
        <div className="ml-auto flex gap-5">
          <Button
            icon={<PasswordIcon />}
            label="Change Password"
            backgroundColor="#fff"
            disabled={isLoading}
            className="!text-[#8f49fd] w-[220px]"
            onClick={() => handleChangePassword()}
          />
        </div>
      </SectionTitle>
      <div className="container-custom mx-auto">
        <div className="mt-16 mb-12">
          <div className="grid grid-flow-col auto-cols-max gap-5 mb-12">
            <div className="w-[280px]">
              <h4>General Information</h4>
            </div>
            <div className="w-[512px]">
              <Input
                label="FIRST NAME"
                className=" mb-6 w-full"
                value={user?.data?.firstName}
                name="firstName"
                disabled
              />
              <Input label="LAST NAME" className=" mb-6 w-full" value={user?.data?.lastName} name="lastName" disabled />
              <Input
                label="E-MAIL ADDRESS"
                className=" mb-6 w-full"
                value={user?.data?.email}
                name="email"
                disabled
                isTypeEmail
              />
            </div>
          </div>
          {user?.data?.userType !== AuthUserResponseDtoUserTypeEnum.Admin && (
            <div className="grid grid-flow-col auto-cols-max gap-5">
              <div className="w-[280px]">
                <h4>Company</h4>
              </div>
              <div className="w-[512px]">
                <Input
                  label="COMPANY NAME"
                  className="mb-6 w-full"
                  value={user?.data?.company}
                  name="company"
                  disabled
                />
                <Input
                  label="ROLE AT COMPANY"
                  className="mb-6 w-full"
                  value={user?.data?.companyRole}
                  name="companyRole"
                  disabled
                />
                <Input
                  label="HOMEPAGE URL"
                  className="mb-6 w-full"
                  value={user?.data?.homepageUrl ?? ""}
                  name="homepageUrl"
                  disabled
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="flex items-center gap-[10px] mb-8">
          <KeyIcon width={30} className="text-purple" />
          <h1 className="font-pp-neue-machina font-semibold text-[30px]">Change password request</h1>
        </div>
        <p className="font-pp-neue-machina">
          You will receive an email with a link to change your password. Be sure to check your spam folder if you don't
          see an email.
        </p>
      </Modal>
    </div>
  );
};

export default Account;
