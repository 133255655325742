import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { sumBy } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { allowDemoHandling } from "../../../../../app/config/auth0/config";
import { SATOSHIS_TO_BTC } from "../../../../../app/constants";
import {
  GetOrganizationsParamsActionFirstEnum,
  GetOrganizationsParamsUserTypeEnum,
} from "../../../../../app/model/api";
import { useMutationProcessDemoOpportunity } from "../../../../../app/query/useMutationAdmin";
import {
  useGetAdminDemoExcessReward,
  useGetAdminDemoOpportunityCollaterals,
  useGetAdminDemoOpportunityFundings,
  useGetAdminDemoOpportunityRewards,
  useGetAdminOpportunityDetails,
  useQueryAdminGetOrganizations,
} from "../../../../../app/query/useQueryGetAdmin";
import { StyledTableRow } from "../../../commons";
import { ConfirmationModal } from "../AdminOpportunityEdit";
import { SimulationBuilder } from "./SimulationBuilder";
import { SimulationEvent } from "./simulationTimelineItems";

export const AdminOpportunitySimulator = ({ opportunityId }: { opportunityId: string }) => {
  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);
  const [modalPayload, setModalPaylod] = React.useState<
    | undefined
    | {
        events: SimulationEvent[];
      }
  >(undefined);
  const { mutateAsync: processDemoEvents, isLoading: isUpdatingStatus } = useMutationProcessDemoOpportunity();

  const { data, isFetching, refetch } = useGetAdminOpportunityDetails(opportunityId);

  const { data: lps, isFetching: isFetchingLps } = useQueryAdminGetOrganizations({
    offset: 0,
    limit: 50,
    actionFirst: GetOrganizationsParamsActionFirstEnum.No,
    userType: GetOrganizationsParamsUserTypeEnum.LP,
  });

  const {
    data: opfundings,
    isFetching: isFetchingFundings,
    refetch: refetchFundings,
  } = useGetAdminDemoOpportunityFundings(opportunityId);

  const {
    data: opRewards,
    isFetching: isFetchingRewards,
    refetch: refetchRewards,
  } = useGetAdminDemoOpportunityRewards(opportunityId);

  const {
    data: opCollaterals,
    isFetching: isFetchingCollaterals,
    refetch: refetchCollaterals,
  } = useGetAdminDemoOpportunityCollaterals(opportunityId);

  const {
    data: excessReward,
    isFetching: isFetchingExcessReward,
    refetch: refetchExcessReward,
  } = useGetAdminDemoExcessReward(opportunityId);

  const fundings =
    opfundings?.data.entries.map((x) => ({
      id: x.id,
      amountSatoshi: x.amountSatoshi,
      lpId: x.lpId,
      lpName: `${x.companyName} (${x.email})`,
      date: new Date(x.fundingDate),
    })) ?? [];
  const fundingSoFar = sumBy(fundings, (x) => x.amountSatoshi) / SATOSHIS_TO_BTC.toNumber();

  const isLoading =
    isFetching ||
    isFetchingLps ||
    isFetchingFundings ||
    isFetchingRewards ||
    isFetchingCollaterals ||
    isFetchingExcessReward;

  const onRefetch = () => {
    refetch();
    refetchFundings();
    refetchRewards();
    refetchCollaterals();
    refetchExcessReward();

    // force the component to be rebuilt
    setDestroyKey(!destroyKey);
  };

  const opportunityPayload = data?.data;

  const handleProcessDemoOpportunity = async (events: SimulationEvent[]) => {
    try {
      await processDemoEvents({
        opportunityId,
        payload: { events: events },
      });
      onRefetch();
    } catch (error) {
      toast.error("There has been an error, please refresh and try again.");
    }
  };

  const handleClose = (events: SimulationEvent[] | undefined) => {
    setModalPaylod(undefined);
    if (events?.length) {
      handleProcessDemoOpportunity(events);
    }
  };

  return isLoading || !opportunityPayload ? (
    <Stack>
      <Skeleton width="100%" height={100}></Skeleton>
      <Skeleton width="100%" height={200}></Skeleton>
    </Stack>
  ) : !opportunityPayload.isDemo || !allowDemoHandling ? (
    <Alert variant="filled" color="warning">
      Simulator is only available for demo opportunities.
    </Alert>
  ) : (
    <Stack width={"100%"} key={destroyKey.toString()} mt={1} spacing={4} pb={4}>
      <Stack width={"100%"} mt={1} spacing={1}>
        <Typography variant="h6">Current state (before non-committed events)</Typography>

        <Grid container item xs={12} mt={2}>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <StyledTableRow key={"th-op-info-0"}>
                  <TableCell size="small" align="center">
                    Status
                  </TableCell>
                  <TableCell size="small" align="center">
                    Collateral status
                  </TableCell>
                  <TableCell size="small" align="center">
                    Creation date
                  </TableCell>
                  <TableCell size="small" align="center">
                    Publish date
                  </TableCell>
                  <TableCell size="small" align="center">
                    Active since
                  </TableCell>
                  <TableCell size="small" align="center">
                    End date
                  </TableCell>
                  <TableCell size="small" align="center">
                    Funding
                  </TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <TableRow key={"row-dates-info-1"}>
                  <TableCell size="small" align="center">
                    {opportunityPayload.status}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {opportunityPayload.collateralStatus}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {format(new Date(opportunityPayload.creationDate), "dd MMM yyyy")}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {opportunityPayload.publishDate
                      ? format(new Date(opportunityPayload.publishDate), "dd MMM yyyy")
                      : "-"}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {opportunityPayload.activeFromDate
                      ? format(new Date(opportunityPayload.activeFromDate), "dd MMM yyyy")
                      : "-"}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {opportunityPayload.endDate ? format(new Date(opportunityPayload.endDate), "dd MMM yyyy") : "-"}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {fundingSoFar} of {opportunityPayload.maxFundingSatoshi / SATOSHIS_TO_BTC.toNumber()} BTC
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Stack>
      <Stack width={"100%"} mt={1} spacing={1}>
        <Typography variant="h6">Simulator</Typography>
        <Typography variant="caption">Select and add multiple events to the queue.</Typography>
        <Alert color="warning" sx={{ width: "fit-content" }}>
          The simulator does not perform an exhaustive handling of opportunities, it only covers basic events to
          demonstrate the lifecycle of an opportunity.
        </Alert>
        <Grid container item xs={12}>
          {opportunityPayload && !!lps?.rows.length && (
            <SimulationBuilder
              opportunityId={opportunityId}
              creationDate={opportunityPayload.creationDate}
              updatedAt={opportunityPayload.updatedAt}
              publishDate={opportunityPayload.publishDate}
              activeFromDate={opportunityPayload.activeFromDate}
              status={opportunityPayload.status}
              collateralStatus={opportunityPayload.collateralStatus}
              neededFundingBTC={opportunityPayload.maxFundingSatoshi / SATOSHIS_TO_BTC.toNumber()}
              onSubmit={(events) => setModalPaylod({ events })}
              lps={lps.rows ?? []}
              fundings={fundings}
              rewards={opRewards?.data.entries ?? []}
              collaterals={opCollaterals?.data.entries ?? []}
              excessRewardRelease={excessReward?.data.value}
            />
          )}
        </Grid>
      </Stack>
      {modalPayload ? (
        <ConfirmationModal
          question={"Are you sure you want to update the opportunity with these events?"}
          handleApprove={() => handleClose(modalPayload.events)}
          handleCancel={() => handleClose(undefined)}
        ></ConfirmationModal>
      ) : null}
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isUpdatingStatus}>
        <Box display="flex" alignItems="center" gap={2} bgcolor={"white"} padding={2} borderRadius={3}>
          <CircularProgress />
          <Typography>Depending on how many days we have to simulate, this may take a minute.</Typography>
        </Box>
      </Backdrop>
    </Stack>
  );
};
