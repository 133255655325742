import { yupResolver } from "@hookform/resolvers/yup";
import WarningIcon from "@mui/icons-material/Warning";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Box,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import {
  ReqCreatePaymentRequest,
  ReqCreatePaymentRequestDestinationEnum,
  ResAdminLoanDto,
} from "../../../app/model/api";
import { ExplainerAccordion } from "../commons/ExplainerAccordion";
import { DUST_SATOSHIS, formatWithCommas, toBtc } from "../commons/utils";
import { useAdminCreatePaymentRequest } from "../loanMutations";

const DESTINATIONS: ReqCreatePaymentRequestDestinationEnum[] = [
  ReqCreatePaymentRequestDestinationEnum.Admin,
  ReqCreatePaymentRequestDestinationEnum.LP,
  ReqCreatePaymentRequestDestinationEnum.Miner,
];

interface FormData {
  readonly amountSatoshi: number;
  readonly address: string;
  readonly executionDelaySeconds: number;
  readonly destination: ReqCreatePaymentRequest["destination"];
}

const ERR_SATOSHIS = "This has to be an integer above 2730.";
const ERR_DUST = "BitGo does not allow payments below 2730 satoshis.";
const ERR_DELAY = "This has to be at least 60 seconds.";

const SchemaPaymentRequest = yup
  .object({})
  .shape({
    amountSatoshi: yup
      .number()
      .typeError(ERR_SATOSHIS)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(DUST_SATOSHIS, ERR_DUST)
      .required(ERR_SATOSHIS),
    executionDelaySeconds: yup
      .number()
      .typeError(ERR_DELAY)
      .positive(ERR_DELAY)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(60, ERR_DELAY)
      .required(ERR_DELAY),
    address: yup.string().required(),
    destination: yup.string().oneOf(DESTINATIONS).required(),
  })
  .required();

export const MakePaymentRequest = ({ loan, onUpdate }: { loan: ResAdminLoanDto; onUpdate: () => void }) => {
  const { mutateAsync, isLoading: isSaving } = useAdminCreatePaymentRequest();

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(SchemaPaymentRequest),
    defaultValues: {
      amountSatoshi: 0,
      address: "",
      executionDelaySeconds: 600,
      destination: undefined,
    },
  });
  const amountField = watch("amountSatoshi");
  const amountSatoshiValue = isNaN(Number(amountField)) ? 0 : Number(amountField);
  const satoshi = formatWithCommas(amountSatoshiValue);
  const btc = toBtc(amountSatoshiValue);
  const conversion = `${satoshi} SAT = ${btc} BTC`;

  const handleUpdate = async (data: FormData) => {
    try {
      const payload: ReqCreatePaymentRequest = {
        loanId: loan.id,
        ...data,
      };
      const resp = await mutateAsync(payload);
      if (resp.data.kind === "success") {
        toast.success("Payment request created.");
        onUpdate();
      } else if (resp.data.kind === "insufficient-balance")
        toast.error("Insufficient balance to create the payment request.");
      else if (resp.data.kind === "invalid-address") toast.error("Invalid address.");
      else toast.error("There was an error creating the payment request.");
    } catch (err) {
      console.error(err);
      toast.error("There was an error creating the payment request.");
    }
  };

  return loan.status === "Draft" ? null : (
    <Box width={"100%"}>
      <form onSubmit={handleSubmit(handleUpdate)} encType="multipart/form-data">
        <Stack minWidth={250} width={"100%"} alignItems={"flex-start"} spacing={1}>
          <Typography variant="h6">Create payment request</Typography>
          <ExplainerAccordion
            title={"More details"}
            explainers={[
              "Payment requests are intended to be payouts to miners, LPs or ourselves. However, a payment can be made to any valid address and is performed through the Bitgo api. Please always use only known and whitelisted addresses. You can find the addresses which are associated with the miner and the LP in this section or in the Audit section.",
              "Payments to LPs are supposed to be the reward distribution mechanism. Payments to Miners are intended to be used for any reward excess return. Payment requests are also intended to be used for any manual adjustments deemed necesary and valid.",
              <Alert key="v4" color="warning" variant="outlined" icon={<WarningIcon />}>
                When creating a payment request you can provide a delay for execution, meaning the actual payment will
                not be executed until that time has passed. This has two purposes, safety (you can delete a payment
                while it is in the waiting time) and convenience (you can schedule a payment in advance).
              </Alert>,
            ]}
          />
          <Stack minWidth={250} width={"100%"} spacing={2} alignItems={"flex-start"} boxShadow={3} padding={2}>
            <TextField
              id="amountSatoshi"
              label={"Amount (SAT)"}
              placeholder="Duration"
              {...register("amountSatoshi")}
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.amountSatoshi}
              helperText={errors.amountSatoshi ? <Box>{errors.amountSatoshi.message}</Box> : conversion}
              onWheel={(evt) => {
                (evt.target as HTMLElement).blur(); // disable edit by scroll
              }}
              type="number"
              InputProps={{
                inputProps: {
                  step: "1",
                },
                endAdornment: <InputAdornment position="end">satoshi</InputAdornment>,
              }}
            />
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                id="destination"
                options={DESTINATIONS}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Destination"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    {...register("destination")}
                    error={!!errors.destination}
                    helperText={<>{errors.destination && <Box>{errors.destination.message}</Box>}</>}
                  />
                )}
              />
            </FormControl>
            <TextField
              id="address"
              label={"To address"}
              placeholder="To address"
              {...register("address")}
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.address}
              helperText={errors.address && <Box>{errors.address.message}</Box>}
              onWheel={(evt) => {
                (evt.target as HTMLElement).blur(); // disable edit by scroll
              }}
            />
            <TextField
              id="executionDelaySeconds"
              label={"Execution delay"}
              placeholder="Execution delay"
              {...register("executionDelaySeconds")}
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.executionDelaySeconds}
              helperText={
                errors.executionDelaySeconds ? (
                  <Box>{errors.executionDelaySeconds.message}</Box>
                ) : (
                  <Typography variant="caption">Allows delaying the payment request. Min=60s.</Typography>
                )
              }
              onWheel={(evt) => {
                (evt.target as HTMLElement).blur(); // disable edit by scroll
              }}
              type="number"
              InputProps={{
                inputProps: {
                  step: "1",
                },
                endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
              }}
            />
            <Tooltip title={isDirty ? "" : "Please fill in the form to save."}>
              <span>
                <LoadingButton size="small" type="submit" loading={isSaving} disabled={!isDirty} variant="contained">
                  Save
                </LoadingButton>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};
