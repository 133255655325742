import { Api } from "../../model/api";

let token: string;

export const apig = new Api<string>({
  baseApiParams: {
    secure: true,
  },
  baseUrl: import.meta.env.VITE_API_URL,
  securityWorker: (accessToken) => (accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {}),
});

export const setToken = (newToken: string) => {
  token = newToken;
  apig.setSecurityData(token);
};

export const getToken = () => {
  return token;
};
