import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";

interface QueryProps {
  offset?: number;
  limit?: number;
}

const getOrganizationUsers = async (query: QueryProps) => {
  return (await apig.user.getOrganizationUsers(query))?.data;
};

const getManagerCount = async () => {
  return (await apig.user.getAccessManagerCount())?.data;
};

const getManagerProposals = async (query: QueryProps) => {
  return (await apig.user.getManagerProposals(query))?.data;
};

const useQueryGetOrganizationUsers = (query: QueryProps) => {
  return useQuery(["organization_users", query], () => getOrganizationUsers(query), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

const useQueryGetManagerCount = () => {
  return useQuery(["total_access_managers"], () => getManagerCount(), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

const useQueryGetAccessProposal = (query: QueryProps) => {
  return useQuery(["access_access_proposal", query], () => getManagerProposals(query), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

export { useQueryGetOrganizationUsers, useQueryGetManagerCount, useQueryGetAccessProposal };
