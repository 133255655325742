import { apig } from "../config/query/clientgen";
import { useMutation } from "@tanstack/react-query";
import { ReqCreatePhysicalOpportunityDto, ReqUpdatePhysicalOpportunityDto } from "../model/api";

export const useMutationCreatePhysicalOpportunity = () => {
  return useMutation((data: ReqCreatePhysicalOpportunityDto) => apig.physicalStreaming.createDraftOpportunity(data));
};

export const useMutationUpdatePhysicalOpportunity = () => {
  return useMutation((data: ReqUpdatePhysicalOpportunityDto & { opportunityId: string }) =>
    apig.physicalStreaming.updateDraftOpportunity(data.opportunityId, data)
  );
};
