import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../../components/atoms/Loader";
import {
  ENTRY_LP_PROGRESS_BAR,
  ENTRY_MINER_PROGRESS_BAR,
  HOME_ROUTE,
  KYC_GENERAL_ROUTE,
  KYC_LEGAL_ROUTE,
  KYC_ROUTE,
  THANK_YOU_ROUTE,
} from "../constants";
import {
  AuthUserResponseDto,
  AuthUserResponseDtoOnboardingStatusEnum,
  AuthUserResponseDtoUserTypeEnum,
} from "../model/api";
import { ProtectedRouteProps } from "../model/route.type";

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);

  if (user?.data?.onboardingStatus) {
    const myUser = user.data;
    const myUserType = myUser.userType;
    const myOnboardingStatus = myUser.onboardingStatus;

    if (myOnboardingStatus !== AuthUserResponseDtoOnboardingStatusEnum.FullyOnboarded) {
      if (
        myOnboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.EntrypointSubmitted &&
        location.pathname !== KYC_GENERAL_ROUTE
      ) {
        return <Navigate to={KYC_GENERAL_ROUTE} replace />;
      } else if (
        myOnboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYCSubmittedCompany &&
        location.pathname !== KYC_LEGAL_ROUTE
      ) {
        return <Navigate to={KYC_LEGAL_ROUTE} replace />;
      } else if (
        myOnboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYCPending &&
        location.pathname !== THANK_YOU_ROUTE
      ) {
        return (
          <Navigate
            to={THANK_YOU_ROUTE}
            state={{
              title: "KYC/AML procedure still in progress",
              content:
                "Thank you for submitting KYC/AML form. We are still in process of reviewing your submission. Block Green team will notify you via email about next steps.",
              progressBar:
                myUserType === AuthUserResponseDtoUserTypeEnum.Miner ? ENTRY_MINER_PROGRESS_BAR : ENTRY_LP_PROGRESS_BAR,
              currentStep: 2,
            }}
            replace
          />
        );
      } else if (
        myOnboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYMPending &&
        location.pathname !== THANK_YOU_ROUTE
      ) {
        return (
          <Navigate
            to={THANK_YOU_ROUTE}
            state={{
              title: "KYM procedure still in progress",
              content:
                "Thank you for submitting KYM Form. We are still in process of reviewing your submission. Block Green team will notify you via email about next steps.",
              progressBar: ENTRY_MINER_PROGRESS_BAR,
              currentStep: 3,
            }}
            replace
          />
        );
      }
    } else if (KYC_ROUTE.includes(location.pathname)) {
      return <Navigate to={HOME_ROUTE} replace />;
    }
  }

  if (window.Cypress && localStorage.getItem("auth0Cypress")) {
    return children;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return children;
};

export default withAuthenticationRequired(ProtectedRoute, {
  onRedirecting: () => <Loader />,
});
