import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import { Alert, AlertTitle, Badge, Box, Paper, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { diffDaysAndHours } from "../../../app/helper/utils";
import { NotificationResponseDto, NotificationResponseDtoPriorityEnum } from "../../../app/model/api";

export const NavBarNotificationContainer = ({ notifications }: { notifications: NotificationResponseDto[] }) => {
  return (
    <Box
      maxHeight={"50vh"}
      overflow={"auto"}
      sx={{
        background: "#eee6f0",
      }}
      maxWidth={400}
    >
      {notifications.length ? (
        notifications.map((x) => <NavBarNotification notification={x} key={"notif-" + x.id} />)
      ) : (
        <Stack padding={3} alignItems={"center"}>
          <MarkunreadMailboxOutlinedIcon />
          <Typography variant="caption" mt={2}>
            You don't have any notifications
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

const NavBarNotification = ({ notification }: { notification: NotificationResponseDto }) => {
  const createdBefore = notification.createdAt && diffDaysAndHours(notification.createdAt);

  const days = createdBefore && createdBefore?.days ? `${createdBefore.days} days` : "";
  const hours = createdBefore && createdBefore?.hours ? `${createdBefore.hours} hours` : "";
  const timeDiff = (days + " " + hours).trim();

  const getSeverity = () => {
    switch (notification.priority) {
      case NotificationResponseDtoPriorityEnum.High:
        return "error";
      case NotificationResponseDtoPriorityEnum.Medium:
        return "warning";
      case NotificationResponseDtoPriorityEnum.Low:
        return "info";
    }
  };

  const getTitle = () => {
    if (notification.link)
      return (
        <Link to={notification.link} target="_blank" rel="noreferrer">
          <Typography
            variant={"body2"}
            fontWeight={"fontWeightBold"}
            sx={{ textDecoration: "underline", "&:hover": { textDecoration: "underline", color: "purple" } }}
          >
            {notification.title}
          </Typography>
        </Link>
      );
    else
      return (
        <Typography variant={"body2"} fontWeight={"fontWeightBold"}>
          {notification.title}
        </Typography>
      );
  };

  const getTitleWithBadge = () => {
    if (notification.readAt) return getTitle();
    else
      return (
        <Badge
          color="primary"
          variant="dot"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {getTitle()}
        </Badge>
      );
  };

  return (
    <>
      <Paper elevation={3} sx={{ margin: 1 }}>
        <Alert severity={getSeverity()}>
          <AlertTitle>
            {getTitleWithBadge()} {timeDiff && <Typography variant="caption">{timeDiff} ago</Typography>}
          </AlertTitle>
          <Box width={300}>{notification.description}</Box>
        </Alert>
      </Paper>
    </>
  );
};
