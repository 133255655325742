import { useQueryClient } from "@tanstack/react-query";
import { AuthUserResponseDto } from "../../../app/model/api";
import Input from "../../../components/atoms/Input";

export const EnterpriseSettings = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);
  const requiredAccessApprovers = user?.data?.enterpriseSettings?.requiredAccessApprovers || 1;
  const requiredWhitelistApprovers = user?.data?.enterpriseSettings?.requiredWhitelistApprovers || 1;

  return (
    <div>
      <div className="container-custom mx-auto">
        <div className="py-[14px] font-pp-neue-machina text-[#667085]">
          <p>
            These settings control the number of approvals required for changes in your enterprise. Please contact Block
            Green Support to edit these values.
          </p>
        </div>

        <div>
          <div className="flex gap-4">
            <div>
              <h3 className="m-0 uppercase text-sm tracking-wide font-pp-neue-machina font-bold">
                Required access change approvers
              </h3>
              <p className="text-light-gray">
                Required number of users who must approve permission changes. Users must have the Access Manager role.
              </p>
              <div className="mt-4">
                <Input
                  disabled
                  name="requiredAccessApprovers"
                  tooltip={{ content: "Contact support@block.green to edit these values", arrowPosition: "center" }}
                  value={requiredAccessApprovers}
                />
              </div>
            </div>
            <div>
              <h3 className="m-0 uppercase text-sm tracking-wide font-pp-neue-machina font-bold">
                Required wallet whitelist approvers
              </h3>
              <p className="text-light-gray">
                Required number of users who must approve new withdrawal addresses. Users must have the Wallet Manager
                role.
              </p>
              <div className="mt-4">
                <Input
                  disabled
                  name="requiredWhitelistApprovers"
                  tooltip={{ content: "Contact support@block.green to edit these values", arrowPosition: "center" }}
                  value={requiredWhitelistApprovers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
