import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TableCell from "@mui/material/TableCell";
import React from "react";
import toast from "react-hot-toast";
import { APPROVE_USER_MESSAGE, ERROR_DEFAULT_MESSAGE, REJECT_USER_MESSAGE, isApiError } from "../../../app/constants";
import {
  useMutationAdminKym2ApproveMinerKyc,
  useMutationApproveMinerOnboarding,
  useMutationRejectMinerOnboarding,
} from "../../../app/query/useMutationAdmin";
import { useQueryGetMinerByMinerId } from "../../../app/query/useQueryGetAdmin";
import { MuiFullscreenLoadingBackdrop } from "../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";
import { StyledTableRow } from "../commons";
import { checkExhaustive } from "../../../app/helper/utils";
import { PrimaryContactDtoPrimaryContactKindEnum } from "../../../app/model/api";

const buildValidExternalUrl = (url: string | null | undefined, text?: string): JSX.Element | null => {
  const trimmed = url?.trim();
  if (!trimmed) return <>N/A</>;
  else {
    const validUrl = trimmed.startsWith("http") ? trimmed : `https://${trimmed}`;
    return (
      <Button
        target="_blank"
        href={validUrl}
        variant="text"
        size="small"
        sx={{
          padding: 0,
          "&:hover": { textDecoration: "underline" },
        }}
      >
        <Typography variant="body2" noWrap>
          {text ?? trimmed}
        </Typography>
      </Button>
    );
  }
};

export const MinerBasicInfo = ({ minerId }: { minerId: string }): JSX.Element => {
  const { data, isFetching, error, refetch } = useQueryGetMinerByMinerId(minerId);
  const { mutateAsync: approveMinerKyc, isLoading: isApprovingKyc } = useMutationAdminKym2ApproveMinerKyc();

  const { mutateAsync: approveMinerOnboarding, isLoading: isApprovingMinerOnboarding } =
    useMutationApproveMinerOnboarding();
  const { mutateAsync: rejectMinerOnboarding, isLoading: isRejectingMinerOnboarding } =
    useMutationRejectMinerOnboarding();

  const isSaving = isApprovingMinerOnboarding || isRejectingMinerOnboarding || isApprovingKyc;

  const minerPayload = data?.data;

  const reviewNeeded =
    minerPayload &&
    (minerPayload.basicInfo.onboardingStatus === "Entrypoint pending" ||
      minerPayload.basicInfo.onboardingStatus === "KYC pending");

  const getStatusCard = () => {
    if (!minerPayload?.basicInfo.onboardingStatus) return null;

    switch (minerPayload?.basicInfo.onboardingStatus) {
      case "Entrypoint pending":
      case "KYC pending":
        return (
          <Chip size="medium" variant="filled" color={"warning"} label={minerPayload.basicInfo.onboardingStatus} />
        );
      case "Entrypoint Rejected":
      case "KYC Rejected":
        return <Chip size="medium" variant="filled" color={"error"} label={minerPayload.basicInfo.onboardingStatus} />;
      case "Fully onboarded":
        return (
          <Chip size="medium" variant="filled" color={"success"} label={minerPayload.basicInfo.onboardingStatus} />
        );
      default:
        return <Chip label={minerPayload.basicInfo.onboardingStatus} />;
    }
  };

  const [openConfirmation, setOpenConfirmation] = React.useState<undefined | "approve-miner" | "reject-miner">();

  const handleAskForConfirmation = (action: "approve-miner" | "reject-miner") => {
    setOpenConfirmation(action);
  };

  const handleKycApproval = async () => {
    if (minerId) {
      try {
        const result = await approveMinerKyc(minerId);

        switch (result.data.kind) {
          case "Success": {
            toast.success(APPROVE_USER_MESSAGE);
            return;
          }
          case "WalletIssue": {
            toast.error("The miner has been approved but there was an issue generating a wallet for them.");
            return;
          }
        }
      } catch (error) {
        if (isApiError(error)) toast.error("There has been an issue with the operation: " + error.error.message);
        else toast.error(ERROR_DEFAULT_MESSAGE);
      }
    }
  };

  const handleOnboardingApproval = async () => {
    if (minerId) {
      try {
        await approveMinerOnboarding(minerId);
        toast.success(APPROVE_USER_MESSAGE);
      } catch (error) {
        if (isApiError(error)) toast.error("There has been an issue with the operation: " + error.error.message);
        else toast.error(ERROR_DEFAULT_MESSAGE);
      }
    }
  };

  const handleRejection = async () => {
    if (minerId) {
      try {
        await rejectMinerOnboarding(minerId);
        toast.success(REJECT_USER_MESSAGE);
      } catch (error) {
        if (isApiError(error)) toast.error("There has been an issue with the operation: " + error.error.message);
        else toast.error(ERROR_DEFAULT_MESSAGE);
      }
    }
  };

  const handleClose = async (actionApproved: boolean) => {
    if (!actionApproved || !minerPayload) {
      setOpenConfirmation(undefined);
    } else {
      setOpenConfirmation(undefined);

      if (openConfirmation === "approve-miner") {
        if (minerPayload.basicInfo.onboardingStatus === "Entrypoint pending") {
          await handleOnboardingApproval();
        } else if (minerPayload.basicInfo.onboardingStatus === "KYC pending") {
          await handleKycApproval();
        } else {
          toast.success("No action needed for this status.");
        }
      } else {
        await handleRejection();
      }
      refetch();
    }
  };

  const getPrimaryContact = (): JSX.Element => {
    if (!minerPayload?.basicInfo.primaryContact?.primaryContactKind) return <CloseIcon color="error" />;
    switch (minerPayload.basicInfo.primaryContact.primaryContactKind) {
      case undefined:
        return <CloseIcon color="error" />;
      case PrimaryContactDtoPrimaryContactKindEnum.Notsure:
        return (
          <Typography variant="body2" noWrap>
            Not sure
          </Typography>
        );
      case PrimaryContactDtoPrimaryContactKindEnum.SignupUser:
        return (
          <Typography variant="body2" noWrap>
            Same as onboarding user
          </Typography>
        );
      case PrimaryContactDtoPrimaryContactKindEnum.AnotherPerson:
        return (
          <>
            <Typography variant="body2" noWrap>{`${
              minerPayload?.basicInfo.primaryContact.reference?.firstName ?? "N/A"
            } ${minerPayload?.basicInfo.primaryContact.reference?.lastName ?? "N/A"}`}</Typography>
            <Typography variant="body2" noWrap>
              {minerPayload?.basicInfo.primaryContact.reference?.email ?? "N/A"}
            </Typography>
            <Typography variant="body2" noWrap>
              {minerPayload?.basicInfo.onboardingUser.cellphone ?? "N/A"}
            </Typography>
          </>
        );
      default:
        checkExhaustive(minerPayload.basicInfo.primaryContact.primaryContactKind);
    }
  };

  const companyRows: { title: string; value: JSX.Element | null }[] = [
    {
      title: "Company",
      value: (
        <Tooltip title={minerPayload?.basicInfo?.company ?? "-"}>
          <Typography gutterBottom variant="body2" noWrap>
            {minerPayload?.basicInfo?.company ?? "N/A"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      title: "Country",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {minerPayload?.basicInfo?.location ?? "-"}
        </Typography>
      ),
    },
    {
      title: "Homepage URL",
      value: buildValidExternalUrl(minerPayload?.basicInfo.homepageUrl),
    },
    {
      title: "Publicly listed",
      value: minerPayload?.basicInfo.isPubliclyListed ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
    },
    {
      title: "Annual report url",
      value: buildValidExternalUrl(minerPayload?.basicInfo.annualReport),
    },
    {
      title: "Onboarding person",
      value: (
        <>
          <Typography
            variant="body2"
            noWrap
          >{`${minerPayload?.basicInfo.onboardingUser.firstName} ${minerPayload?.basicInfo.onboardingUser.lastName}`}</Typography>
          <Typography variant="body2" noWrap>
            {minerPayload?.basicInfo.onboardingUser.email}
          </Typography>
          <Typography variant="body2" noWrap>
            {minerPayload?.basicInfo.onboardingUser.cellphone}
          </Typography>
        </>
      ),
    },
    {
      title: "Primary contact type",
      value: getPrimaryContact(),
    },
    {
      title: "Does primary contact have signatory powers?",
      value: (
        <>
          <Typography variant="body2" noWrap>
            {minerPayload?.basicInfo.primaryContact?.hasPowerOfAttorney == undefined ? (
              <CloseIcon color="error" />
            ) : minerPayload?.basicInfo.primaryContact?.hasPowerOfAttorney ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>
        </>
      ),
    },
  ];

  const minerRows: { title: string; value: JSX.Element | null }[] = [
    {
      title: "Self-mining installed capacity",
      value: <Typography variant="body1">{`${minerPayload?.minerInfo?.miningCapacity} PH/s` ?? "N/A"}</Typography>,
    },
    {
      title: "Machines encumbered",
      value: minerPayload?.minerInfo.isEncumbered ? (
        <>
          {minerPayload.minerInfo.percentEncumbrance == undefined
            ? ""
            : `${minerPayload.minerInfo.percentEncumbrance}%`}
          <CheckIcon color="success" />{" "}
        </>
      ) : (
        <CloseIcon color="error" />
      ),
    },
    {
      title: "Energy costs",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {minerPayload?.minerInfo?.energyCostsFixed ? "Fixed" : "Variable"}
        </Typography>
      ),
    },
    {
      title: "Primary energy source",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {minerPayload?.minerInfo?.primaryEnergySource ?? "N/A"}
        </Typography>
      ),
    },
    {
      title: "Average all in energy price",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {minerPayload?.minerInfo?.averageAllInEnergyPrice === undefined
            ? "N/A"
            : `${minerPayload?.minerInfo?.averageAllInEnergyPrice.toFixed(2)} ${minerPayload?.minerInfo
                ?.averageAllInEnergyPriceUnit}`}
        </Typography>
      ),
    },
  ];

  const walletRows: { title: string; value: JSX.Element | null }[] =
    minerPayload?.walletDetails?.walletAddress &&
    minerPayload?.walletDetails?.bitgoWalletUrl &&
    minerPayload?.walletDetails.bitgoWalletId
      ? [
          {
            title: "BitGo wallet ID",
            value: buildValidExternalUrl(
              minerPayload.walletDetails.bitgoWalletUrl,
              minerPayload.walletDetails.bitgoWalletId
            ),
          },
          {
            title: "Wallet deposit address",
            value: (
              <Typography gutterBottom variant="body2" noWrap>
                {minerPayload.walletDetails.walletAddress}
              </Typography>
            ),
          },
        ]
      : [];

  if (isFetching) return <Skeleton height={150} width="100%"></Skeleton>;
  else if (error || !minerPayload)
    return (
      <Alert severity="warning">There has been an issue loading the data. Please refresh the page and try again.</Alert>
    );
  else {
    return (
      <Box width={"100%"}>
        <MuiFullscreenLoadingBackdrop isOpen={isSaving} />
        <Dialog open={!!openConfirmation} onClose={() => handleClose(false)}>
          <DialogTitle id="alert-dialog-title">{"Confirm action"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to {openConfirmation === "approve-miner" ? "approve" : "reject"} this miner?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(true)}>Yes</Button>
            <Button onClick={() => handleClose(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container item>
          <Grid container item xs={12} md={6}>
            <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
              <CardContent>
                <Box>
                  <Box display="flex" width={"100%"} alignItems={"center"} justifyContent={"flex-start"} gap={1}>
                    {getStatusCard()}
                    {reviewNeeded ? (
                      <>
                        <LoadingButton
                          onClick={() => handleAskForConfirmation("approve-miner")}
                          disabled={!reviewNeeded || isFetching}
                          loading={isSaving}
                          variant="contained"
                          color="success"
                          sx={{ height: 30, width: 90 }}
                        >
                          Approve
                        </LoadingButton>
                        <LoadingButton
                          onClick={() => handleAskForConfirmation("reject-miner")}
                          disabled={!reviewNeeded || isFetching}
                          loading={isSaving}
                          variant="contained"
                          color="error"
                          sx={{ height: 30, width: 90 }}
                        >
                          Reject
                        </LoadingButton>
                      </>
                    ) : null}
                  </Box>
                </Box>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableBody>
                      {companyRows.map((row, index) => (
                        <StyledTableRow key={"row-company-info" + index}>
                          <TableCell align="left" width={175}>
                            <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                              {row.title}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{row.value}</TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Box>
              <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
                <CardContent>
                  <Typography variant="h6" noWrap>
                    Mining info
                  </Typography>
                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                      <TableBody>
                        {minerRows.map((row, index) => (
                          <StyledTableRow key={"row-company-info" + index}>
                            <TableCell align="left" width={300}>
                              <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                                {row.title}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{row.value}</TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
              {!!walletRows.length && (
                <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
                  <CardContent>
                    <Typography variant="h6" noWrap>
                      Wallet info
                    </Typography>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                      <Table>
                        <TableBody>
                          {walletRows.map((row, index) => (
                            <StyledTableRow key={"row-company-info" + index}>
                              <TableCell align="left" width={175}>
                                <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                                  {row.title}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">{row.value}</TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
};
