import { Box, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useInterval } from "../../../app/hooks/useInterval";
import { useGetMinerProxyHashrate } from "../../../app/query/useQueryGetMiner";

export const HashrateMonitor: React.FC = () => {
  const { data, refetch } = useGetMinerProxyHashrate();

  useInterval(() => {
    refetch();
  }, 5000);

  const currentHashrate = data?.currentHashratePhPerSecond ?? 0;

  return (
    <Card>
      <CardHeader
        title="Live Hashrate"
        action={
          <Box sx={{ display: "flex", alignItems: "center", mr: 2, mt: 1 }}>
            <CircularProgress size={20} />
          </Box>
        }
      />
      <CardContent>
        {currentHashrate === 0 ? (
          <Typography variant="body1">
            No hashrate detected. Please update your workers to use the above mining address.
          </Typography>
        ) : (
          <Typography variant="h5">{currentHashrate.toFixed(2)} PH/s</Typography>
        )}
      </CardContent>
    </Card>
  );
};
