import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Checkbox from "../../components/atoms/Checkbox";
import Loader from "../../components/atoms/Loader";
import ProgressPercentBar from "../../components/atoms/ProgressPercentBar";
import SectionTitle from "../../components/atoms/SectionTitle";
import Select from "../../components/atoms/Select";
import LineChart from "../../components/molecules/LineChart";
import Pagination from "../../components/molecules/Pagination";
import HeroPortfolio from "../../components/organisms/HeroPortfolio";
import Table from "../../components/organisms/Table";

import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";
import Decimal from "decimal.js";
import {
  HIDE_SETTLED_OPPORTUNITIES_MESSAGE,
  MONTHS_OPTIONS,
  OPPORTUNITIES_DETAILS_LP_ROUTE,
  YEAR_OPTIONS,
} from "../../app/constants";
import {
  getChartFilterMonths,
  roundToSignificantDigits,
  roundToSignificantDigitsDiv1000,
} from "../../app/helper/utils";
import {
  GetLpOpprotunitiesFundedParamsEndDateEnum,
  GetLpOpprotunitiesFundedParamsHideSettledEnum,
  Sort,
} from "../../app/model/api";
import { useQueryGetLPPortfolioChart, useQuerygetLpOpportunitiesFunded } from "../../app/query/useQueryGetLP";
import { useQueryGetMiners } from "../../app/query/useQueryGetUser";

type HashPowerParam = {
  current?: number;
  maximum?: number;
};

export const LpPortfolio = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [hideSettled, setHideSettled] = useState<GetLpOpprotunitiesFundedParamsHideSettledEnum>(
    GetLpOpprotunitiesFundedParamsHideSettledEnum.No
  );
  const [year, setYear] = useState<string>("all");
  const [month, setMonth] = useState<string>("");
  const [selectedMiner, setSelectedMiner] = useState<string>("");
  const [selectedOpportunityFilterMiner, setSelectedOpportunityFilterMiner] = useState<string>("All");
  const [directionEndDate, setDirectionEndDate] = useState<Sort>(Sort.Asc);
  const { data: chartData, isFetching: isChartDataFetching } = useQueryGetLPPortfolioChart({
    year: year,
    minerId: selectedMiner,
    month: month === "Current" ? new Date().toLocaleString("default", { month: "long" }) : month,
  });

  const { data: miners } = useQueryGetMiners();
  const { data: opportunities, isFetching: isFetchingOpportunities } = useQuerygetLpOpportunitiesFunded({
    offset: (page - 1) * perPage,
    limit: perPage,
    endDate:
      directionEndDate === Sort.Asc
        ? GetLpOpprotunitiesFundedParamsEndDateEnum.Asc
        : GetLpOpprotunitiesFundedParamsEndDateEnum.Desc,
    minerId: selectedOpportunityFilterMiner === "All" ? undefined : selectedOpportunityFilterMiner,
    hideSettled,
  });

  const handleSettledAction = (name: string, value: string | number | boolean) => {
    setHideSettled(
      value ? GetLpOpprotunitiesFundedParamsHideSettledEnum.Yes : GetLpOpprotunitiesFundedParamsHideSettledEnum.No
    );
    setPage(1);
  };

  const handleYear = (value: string) => {
    setYear(value);
    setMonth("");
  };

  const paginationPages = opportunities?.count ? Math.ceil(opportunities?.count / perPage) : 0;

  const minerOptions: { value: string; label: string }[] =
    miners?.miners
      .filter((miner) => opportunities?.rows?.some((opp) => opp.minerId === miner.id))
      .map((item) => ({ value: item.id, label: `${item.company}` })) || [];

  const formattedMinerOption = [{ value: "All", label: "All" }, ...minerOptions];

  const getPercentage = (hashPower: HashPowerParam | undefined) => {
    const percent = Number(
      roundToSignificantDigits(
        new Decimal(hashPower?.current || 0)
          .mul(100)
          .div(hashPower?.maximum || 0)
          .toNumber()
      )
    );

    return parseFloat(percent.toFixed(2));
  };

  const allDataPointsHaveZeroY = chartData?.data?.every((chart) => {
    return chart.data.every((dataPoint) => {
      return dataPoint.y === 0;
    });
  });

  const isValidChartData = chartData?.data && Array.isArray(chartData.data) && chartData.data.length > 0;

  return (
    <>
      <HeroPortfolio />
      {isValidChartData && !allDataPointsHaveZeroY ? (
        <>
          <SectionTitle title="My Portfolio" />
          <div className="container-custom mx-auto !py-14">
            <div className="flex gap-4 ml-auto w-[450px]">
              <Select
                name="miner"
                label="Filter by Miner"
                className="grow"
                options={formattedMinerOption}
                value={formattedMinerOption.find((item) => item.value === selectedMiner)}
                onChange={(name, value) => setSelectedMiner(value as string)}
              />
              <Select
                name="year"
                label="Filter by Year"
                className="grow"
                options={YEAR_OPTIONS}
                value={YEAR_OPTIONS.find((item: { value: string; label: string }) => item.value === year)}
                onChange={(name, value) => handleYear(value as string)}
              />
              <Select
                name="month"
                label="Filter by Month"
                className="grow"
                disabled={year === "all" || chartData?.data?.length === 0}
                options={getChartFilterMonths(Number(year) === new Date().getFullYear())}
                value={MONTHS_OPTIONS.find((item: { value: string; label: string }) => item.value === month)}
                onChange={(name, value) => (year !== "all" ? setMonth(value as string) : {})}
              />
            </div>

            {isValidChartData ? (
              <div className="mt-6">
                <LineChart
                  xTitle="Rewards Delivered (BTC)"
                  colors={["hwb(272deg 56% 6%)", "hwb(358deg 51% 8%)", "rgb(76 206 162)"]}
                  data={chartData}
                />
              </div>
            ) : null}

            {isChartDataFetching ? (
              <div className="relative h-[420px] mt-6">
                <Loader className="!absolute" />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <SectionTitle title="Opportunities funded" />
      <div className="container-custom mx-auto !pt-14 !pb-16">
        <div className="flex justify-between items-center">
          <FormControl sx={{ width: 250 }}>
            <InputLabel id="select-opminer">Miner</InputLabel>
            <MuiSelect
              labelId="select-opminer"
              label={"Miner"}
              id="select-opminer"
              value={selectedOpportunityFilterMiner}
              size="small"
              onChange={(newValue) => {
                setSelectedOpportunityFilterMiner(newValue.target.value);
                setPage(1);
              }}
            >
              {formattedMinerOption.map((miner) => (
                <MenuItem key={"key-select-status" + miner.value} value={miner.value}>
                  {miner.label}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
          <Checkbox
            name="hideSettled"
            label={HIDE_SETTLED_OPPORTUNITIES_MESSAGE}
            checked={hideSettled === GetLpOpprotunitiesFundedParamsHideSettledEnum.Yes}
            onChange={(name, value) => handleSettledAction(name, value)}
          />
        </div>
        <Table>
          <Table.Head>
            <Table.TR>
              <Table.TH>Opportunity ID </Table.TH>
              <Table.TH>Miner Name</Table.TH>
              <Table.TH onSort={(direction) => setDirectionEndDate(direction)}>End date</Table.TH>
              <Table.TH className="!text-end">Hashrate</Table.TH>
            </Table.TR>
          </Table.Head>
          <Table.Body>
            {opportunities &&
              opportunities?.rows?.map((item) => {
                return (
                  <Table.TR key={item.id}>
                    <Table.TD
                      className="w-[180px]"
                      onClick={() =>
                        navigate(OPPORTUNITIES_DETAILS_LP_ROUTE.replace(":id", String(item.opportunityId)))
                      }
                    >
                      <span className="text-light-purple">#{item.opportunityIdNum}</span>
                    </Table.TD>
                    <Table.TD className="w-[456px]">{item?.companyName || ""}</Table.TD>
                    <Table.TD className="w-[285px]">
                      <div className="flex flex-col items-center">
                        {item.fullyFundedDate ? (
                          <>
                            <span className="date-value text-dark">{moment(item.fullyFundedDate).format("LL")}</span>
                            <span className="time-value text-[13px] text-[#6d7282] tracking-wide">
                              {moment(item.fullyFundedDate).format("LT")}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </Table.TD>
                    <Table.TD className="w-[285px]">
                      <div className="flex justify-end gap-2 font-bold text-dark mb-2">
                        <div className="flex items-center gap-1">
                          {roundToSignificantDigitsDiv1000(item?.fundedHaspowerTh || 0)}
                          <sup className="font-normal">PH/s</sup>
                        </div>
                        /
                        <div className="flex items-center gap-1">
                          {roundToSignificantDigitsDiv1000(item.fullHashrateThPerSecond || 0)}
                          <sup className="font-normal">PH/s</sup>
                        </div>
                      </div>
                      <ProgressPercentBar
                        value={getPercentage({
                          current: item?.fundedHaspowerTh || 0,
                          maximum: item.fullHashrateThPerSecond || 0,
                        })}
                      />
                    </Table.TD>
                  </Table.TR>
                );
              })}

            {opportunities && opportunities?.rows?.length === 0 && !isFetchingOpportunities && (
              <Table.TR>
                <Table.TD colSpan={5} className="relative text-center !h-[150px]">
                  No results.
                </Table.TD>
              </Table.TR>
            )}

            {isFetchingOpportunities && (
              <Table.Loader>
                <Table.TD colSpan={5} className="relative !h-[865px]">
                  <Loader />
                </Table.TD>
              </Table.Loader>
            )}
          </Table.Body>
        </Table>
        {paginationPages > 1 && (
          <Pagination
            page={page}
            paginationPages={paginationPages}
            onPrev={() => setPage((prev: number) => prev - 1)}
            onNext={() => setPage((prev: number) => prev + 1)}
          />
        )}
      </div>
    </>
  );
};
