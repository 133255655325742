import React from "react";
import { Card, CardContent, CardHeader, Typography, Box, Stack } from "@mui/material";
import { useGetMinerProxyMiningAddress } from "../../../app/query/useQueryGetMiner";
import { ButtonCopyToClipboard } from "../../Loans/commons/ButtonCopyToClipboard";

export const ProxyConfiguration: React.FC = () => {
  const { data: proxyDetails } = useGetMinerProxyMiningAddress();

  return (
    <Card sx={{ mb: 4 }}>
      <CardHeader
        title="Proxy Configuration"
        subheader="Mine to this address to start using the proxy. Excess hashrate will be sent to the pool configured above."
      />
      <CardContent>
        <Box sx={{ p: 1, bgcolor: "background.paper", borderRadius: 1 }}>
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
              <Box component="span" sx={{ fontWeight: "bold", minWidth: 140 }}>
                Proxy Address:
              </Box>
              {proxyDetails?.proxyMiningAddress}
              {proxyDetails?.proxyMiningAddress && <ButtonCopyToClipboard text={proxyDetails.proxyMiningAddress} />}
            </Typography>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
