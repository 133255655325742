import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { addDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/atoms/Loader";
import { HorizontalInfoTable } from "../commons/HorizontalInfoTable";
import { LoanExpectedScheduleTable } from "../commons/LoanExpectedScheduleTable";
import { LoanPaymentRequestsTable } from "../commons/LoanPaymentRequestsTable";
import { LoanTitle, getLpLoanStatusChip } from "../commons/LoanTitle";
import { UnitSelector } from "../commons/UnitSelector";
import { formatWithCommas, toFormattedBtc } from "../commons/utils";
import { useLpGetLpLoan } from "../loanQueries";

export const LpPageLoanDetails = () => {
  const { id } = useParams();
  const { data, isFetching } = useLpGetLpLoan(id ?? "");
  const [unit, setUnit] = useState<"BTC" | "SAT">("BTC");

  const loan = data?.data;

  if (!loan) {
    return <Loader />;
  } else {
    return (
      <Box display="flex" justifyContent={"center"} width={"100%"} padding={3} bgcolor={"white"}>
        <Stack width="100%" maxWidth={1600} spacing={2}>
          <Box display="flex" width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
            <LoanTitle id={loan.id} statusChip={getLpLoanStatusChip(loan.status)} isDemo={loan.isDemo} />
            <UnitSelector initial={unit} onChange={setUnit} />
          </Box>
          {isFetching && <Skeleton width="100%" height={"30vh"} variant="rounded" />}
          {!isFetching && (
            <Stack spacing={2}>
              <Typography variant="h6" noWrap>
                Loan details
              </Typography>
              <HorizontalInfoTable
                title=""
                entries={[
                  { name: "Created at", value: formatInTimeZone(loan.createdAt, "UTC", "dd MMM yyyy") },
                  { name: "Status", value: getLpLoanStatusChip(loan.status) },
                  { name: "Duration (days)", value: loan.durationDays.toString() },
                  {
                    name: "Start date",
                    value: loan.startedAt ? formatInTimeZone(loan.startedAt, "UTC", "dd MMM yyyy") : "",
                  },
                  {
                    name: "End date",
                    value: loan.startedAt
                      ? formatInTimeZone(addDays(new Date(loan.startedAt), loan.durationDays), "UTC", "dd MMM yyyy")
                      : "",
                  },
                  {
                    name: `Principal amount (${unit})`,
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.principalAmountSatoshi)
                        : toFormattedBtc(loan.principalAmountSatoshi),
                  },
                  { name: "Annualised interest", value: loan.lpAnnualInterestRatePercent.toString() + "%" },
                ]}
              />
              <Typography variant="h6" gutterBottom>
                Payment schedule
              </Typography>
              <LoanExpectedScheduleTable
                unit={unit}
                height={"50vh"}
                expected={loan.expectedPayments.map((exp) => ({
                  date: exp.date,
                  expected: exp.forLPSatoshis,
                  expectedSoFar: exp.totalForLPSoFar,
                }))}
                actuals={loan.paymentsToLp}
              />
              <LoanPaymentRequestsTable
                unit={unit}
                title="Actual payments"
                includeExecutionDetails={false}
                outgoingPayments={loan.paymentsToLp}
                height="50vh"
              />
            </Stack>
          )}
        </Stack>
      </Box>
    );
  }
};
