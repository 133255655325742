import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { KYC_HEADER, KYC_ROUTE, KYM_HEADER } from "../../app/constants";
import { AuthUserResponseDto, AuthUserResponseDtoOnboardingStatusEnum } from "../../app/model/api";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import KYCHeader from "../../components/molecules/KYCHeader";

import "./style.scss";

interface Step {
  name: string;
  active: boolean;
  completed: boolean;
}

const KYCLayout = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);
  const [steps, setSteps] = useState<Step[]>([]);
  const [isSiteExist, setIsSiteExist] = useState<boolean>(false);

  useEffect(() => {
    if (!user?.data?.onboardingStatus) return;
    const header = [
      AuthUserResponseDtoOnboardingStatusEnum.EntrypointSubmitted,
      AuthUserResponseDtoOnboardingStatusEnum.KYCSubmittedCompany,
    ].includes(user?.data?.onboardingStatus)
      ? [
          {
            ...KYC_HEADER[0],
            active: user?.data?.onboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.EntrypointSubmitted,
            completed: user?.data?.onboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYCSubmittedCompany,
          },
          {
            ...KYC_HEADER[1],
            active: user?.data?.onboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYCSubmittedCompany,
          },
        ]
      : [
          {
            ...KYM_HEADER[0],
            active: user?.data?.onboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYCSubmittedRepresentative,
            completed: user?.data?.onboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYMSubmittedGeneral,
          },
          {
            ...KYM_HEADER[1],
            active: user?.data?.onboardingStatus === AuthUserResponseDtoOnboardingStatusEnum.KYMSubmittedGeneral,
          },
        ];

    setSteps(header);
  }, [location.pathname, user?.data?.onboardingStatus]);

  return (
    <div>
      <KYCHeader steps={steps} />
      <div
        className={classNames("p-5  xl:pt-8", {
          "kym-site-holder": isSiteExist,
          "xl:pb-12": !isSiteExist,
          "entryform-miner-bck": KYC_ROUTE.includes(location.pathname),
        })}
      >
        <div className="entity-form-layout p-5 md:p-10 xl:mx-auto">
          <div className="flex flex-col justify-center items-center mb-6">
            <div>
              <Logo />
            </div>
            <h1 className="mt-6">Welcome</h1>
            <p>Please enter your details.</p>
          </div>
          <main>
            <Outlet context={{ setIsSiteExist }} />
          </main>
        </div>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default KYCLayout;
