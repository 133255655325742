import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Skeleton, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import ReactJson, { OnCopyProps } from "react-json-view";
import { useMutationDumpMinerData } from "../../../../app/query/useMutationAdmin";
import { useGetAdminMinerSummary } from "../../../../app/query/useQueryGetAdmin";
import { MuiFullscreenLoadingBackdrop } from "../../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";
import { SyntheticStreamingForm } from "./SyntheticStreamingForm";
import { MinerRating } from "./MinerRating";

export const CreateSyntheticOpportunity = ({ minerId }: { minerId: string }) => {
  const [dump, setDump] = React.useState<unknown | undefined>(undefined);
  const [isRatingOpen, setIsRatingOpen] = React.useState<boolean>(true);
  const handleChange = () => {
    setIsRatingOpen(!isRatingOpen);
  };
  const { mutateAsync: generateDump, isLoading: isGettingDump } = useMutationDumpMinerData();

  const { data, isFetching, error, refetch } = useGetAdminMinerSummary(minerId ?? "");

  const minerPayload = data?.data;

  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);

  const onRefetch = () => {
    setDestroyKey(!destroyKey); // force the component to be rebuilt
    refetch();
  };

  const hasRating =
    minerPayload?.sustainabilityRate && minerPayload.financialHealthRate && minerPayload.operationalHealthRate;
  const isFullyOnboarded = minerPayload?.onboardingStatus === "Fully onboarded";

  React.useEffect(() => {
    if (hasRating) {
      setIsRatingOpen(false);
    }
  }, [data, hasRating]);

  const handleDump = async () => {
    try {
      const result = await generateDump(minerId ?? "");
      if (result.data.json) {
        const payload = JSON.parse(result.data.json);
        setDump(payload);
      } else setDump("No data available.");
    } catch (err) {
      setDump("There was an issue getting the data.");
    }
  };
  const handleCopy = (copy: OnCopyProps) => {
    navigator.clipboard.writeText(JSON.stringify(copy.src, null, "\t"));
  };

  return isFetching || !minerPayload ? (
    <Stack>
      <Skeleton width={"100%"} height={75} />
      <Skeleton width={"100%"} height={200} />
    </Stack>
  ) : error || !minerId ? (
    <Alert severity="warning" variant="filled">
      There has been an issue loading the miner data. Please refresh the page to try again.
    </Alert>
  ) : !isFullyOnboarded ? (
    <Alert severity="warning" variant="filled">
      Miner must be fully onboarded before being able to edit opportunities.
    </Alert>
  ) : (
    <Stack>
      <Box mb={2} width={"100%"} display={"flex"} justifyContent={"flex-end"}>
        <MuiFullscreenLoadingBackdrop isOpen={isGettingDump} />
        <Button variant="contained" onClick={handleDump}>
          Get miner data dump
        </Button>
        <Dialog open={!!dump} onClose={() => setDump("")}>
          <DialogTitle id="alert-dialog-title">Miner dump</DialogTitle>
          <DialogContent>
            <ReactJson
              onEdit={() => {
                return false;
              }}
              enableClipboard={handleCopy}
              src={dump ?? {}}
            />
          </DialogContent>
        </Dialog>
      </Box>
      <Accordion
        expanded={isRatingOpen}
        onChange={() => handleChange()}
        sx={{ width: "100%", boxShadow: 4, flexDirection: "row-reverse" }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography variant="subtitle1">Miner health rating</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MinerRating
            key={destroyKey.toString()}
            minerId={minerId}
            miner={minerPayload}
            triggerRefetch={onRefetch}
          ></MinerRating>{" "}
        </AccordionDetails>
      </Accordion>

      {!hasRating && (
        <Alert severity="warning" variant="filled">
          You must submit the miner rating before being able to create an opportunity.
        </Alert>
      )}
      {hasRating && (
        <Box width="100%" mt={4}>
          <SyntheticStreamingForm
            key={destroyKey.toString()}
            minerId={minerId}
            triggerRefetch={onRefetch}
          ></SyntheticStreamingForm>
        </Box>
      )}
    </Stack>
  );
};
