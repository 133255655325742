import { QueryState, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutationUserNotification } from "../../../app/query/useMutationUser";
import { useQueryGetUserNotifications } from "../../../app/query/useQueryGetUser";
import { NavBarMenuItem } from "../../../app/types";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { ACCOUNT_ROUTE, ROUTE_ENTERPRISE_SETTINGS } from "../../../app/constants";
import { useLogout } from "../../../app/hooks/auth-helpers";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import profileIcon from "../../../assets/images/profile.png";
import { NavBarNotificationContainer } from "./NavBarNotificationContainer";
import { AuthUserResponseDto } from "../../../app/model/api";

interface NavBarProps {
  menuList: Array<NavBarMenuItem>;
}

interface Props {
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    target: window,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export const MuiNavBar = ({ menuList }: NavBarProps) => {
  const { logout } = useLogout();
  const queryClient = useQueryClient();

  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);
  if (!user?.data) {
    logout();
    return <></>;
  } else {
    return <MuiNavBarInner menuList={menuList} user={user} logout={logout} />;
  }
};

const MuiNavBarInner = ({
  menuList,
  user,
  logout,
}: {
  menuList: NavBarMenuItem[];
  user: QueryState<AuthUserResponseDto, undefined>;
  logout: () => void;
}) => {
  const [anchorElAvatarMenu, setAnchorElAvatarMenu] = React.useState<null | HTMLElement>(null);
  const [anchorElNotifications, setAnchorElNotifications] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const [submenuAnchors, setSubmenuAnchors] = React.useState<{ [index: string]: null | HTMLElement }>({});
  const handleSubmenu = (index: string, event: React.MouseEvent<HTMLElement>) => {
    setSubmenuAnchors({
      ...submenuAnchors,
      [index]: event.currentTarget,
    });
  };
  const handleCloseSubmenu = (index: string) => {
    setSubmenuAnchors({
      ...submenuAnchors,
      [index]: null,
    });
  };

  const handleAvatarMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAvatarMenu(event.currentTarget);
  };

  const handleCloseAvatarMenu = () => {
    setAnchorElAvatarMenu(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const username = `${user?.data?.firstName} ${user?.data?.lastName}`;
  const company = user?.data?.company;

  const navigate = useNavigate();
  const location = useLocation();

  const { data: notifications, refetch } = useQueryGetUserNotifications();
  const { mutateAsync } = useMutationUserNotification();

  const newNotificationCount = notifications?.filter((el) => !el.readAt).length ?? 0;

  const handleClickNotifications = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNotifications(event.currentTarget);

    const toUpdate = notifications?.filter((item) => !item.readAt).map(({ id }) => id);

    if (toUpdate?.length) {
      try {
        await mutateAsync({ notifications: toUpdate });
        refetch();
      } catch (error) {
        console.log("-");
      }
    }
  };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };

  const popoverId = anchorElNotifications ? "popover-notifications" : undefined;

  const sortedNotifications =
    notifications?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) || [];

  return (
    <HideOnScroll>
      <AppBar position="sticky" color="default" sx={{ minHeight: 30 }}>
        <Toolbar disableGutters sx={{ pl: 1, minHeight: 30 }}>
          <Box pl={1}>
            <Logo width={35} />
          </Box>
          <Box sx={{ flexGrow: 1, ml: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu icon button"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {menuList.map((item) => {
                return (
                  <MenuItem
                    key={"key-mobile-mi-" + item.name}
                    onClick={() => {
                      navigate(item.path);
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography color={item.path === location.pathname ? "primary" : "default"}>{item.name}</Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>

          <Grid container justifyContent="flex-end" alignItems={"center"}>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "flex" } }} alignItems={"center"}>
              {menuList.map((item) => {
                const isSelected =
                  item.path === location.pathname || item.children?.some((x) => x.path === location.pathname);
                return (
                  <>
                    <MenuItem
                      sx={{ ml: 3, padding: 0 }}
                      key={"key-main-mi" + item.name}
                      onClick={
                        item.children?.length ? (evt) => handleSubmenu(item.name, evt) : () => navigate(item.path)
                      }
                      aria-owns={submenuAnchors[item.name] ? "menu-submenu-" + item.name : undefined}
                      aria-haspopup={item.children?.length ? "true" : "false"}
                    >
                      <Box display="flex" alignItems={"center"}>
                        {!isSelected && <Box mr={1}>{item.icon}</Box>}
                        {isSelected && <Box mr={1}>{item.activeIcon}</Box>}
                        <Typography color={isSelected ? "primary" : "default"}>{item.name}</Typography>
                      </Box>
                    </MenuItem>
                    {item.children?.length && (
                      <Menu
                        MenuListProps={{ onMouseLeave: () => handleCloseSubmenu(item.name) }}
                        id={"menu-submenu-" + item.name}
                        key={"key-menu-submenu-" + item.name}
                        anchorEl={submenuAnchors[item.name]}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        open={!!submenuAnchors[item.name]}
                        onClose={() => handleCloseSubmenu(item.name)}
                      >
                        {item.children.map((child) => {
                          return (
                            <MenuItem
                              sx={{ minWidth: 150 }}
                              key={"key-mi-" + child.name}
                              onClick={() => {
                                navigate(child.path);
                                handleCloseSubmenu(item.name);
                              }}
                            >
                              <Typography color={child.path === location.pathname ? "primary" : "default"}>
                                {child.name}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    )}
                  </>
                );
              })}
            </Box>
            <Link to="https://block-green-helpdesk.webflow.io/" target="blank" referrerPolicy="no-referrer">
              <IconButton color="inherit">
                <HelpOutlineIcon fontSize="medium" />
              </IconButton>
            </Link>
            <IconButton aria-describedby={popoverId} color="inherit" onClick={handleClickNotifications}>
              <Badge
                color="primary"
                badgeContent={newNotificationCount}
                variant="dot"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <NotificationsIcon fontSize="medium"></NotificationsIcon>
              </Badge>
            </IconButton>
            <Popover
              id={popoverId}
              open={!!anchorElNotifications}
              anchorEl={anchorElNotifications}
              onClose={handleCloseNotifications}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <NavBarNotificationContainer notifications={sortedNotifications} />
            </Popover>
            <Box ml={2} maxWidth={150} sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              <Tooltip title={username}>
                <Typography variant="body2" fontWeight={"fontWeightBold"} noWrap textAlign={"end"}>
                  {username}
                </Typography>
              </Tooltip>
              {company && (
                <Typography variant="caption" noWrap textAlign={"end"}>
                  {company}
                </Typography>
              )}
            </Box>
            <IconButton onClick={handleAvatarMenu} size="small">
              <Avatar alt="Account" src={profileIcon} variant="circular" sx={{ width: 30, height: 30 }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElAvatarMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{ mt: "47px" }}
              open={Boolean(anchorElAvatarMenu)}
              onClose={handleCloseAvatarMenu}
            >
              {[
                <MenuItem
                  key="mi-avatarmenu-act"
                  sx={{ width: 200 }}
                  onClick={() => {
                    navigate(ACCOUNT_ROUTE);
                    handleCloseAvatarMenu();
                  }}
                >
                  <Typography variant="body2">Account</Typography>
                </MenuItem>,
                <MenuItem
                  key="mi-avatarmenu-enterprise"
                  sx={{ width: 200 }}
                  onClick={() => {
                    navigate(ROUTE_ENTERPRISE_SETTINGS);
                    handleCloseAvatarMenu();
                  }}
                >
                  <Typography variant="body2">Enterprise</Typography>
                </MenuItem>,
                <Divider key="mi-avatar-div" />,
                <MenuItem key="signout" sx={{ width: 200 }} onClick={logout}>
                  <Typography variant="body2">Sign out</Typography>
                </MenuItem>,
              ]}
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};
