import { Button, Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useGetMinerPoolCredentials } from "../../../app/query/useQueryGetMiner";
import { PoolConfigurationForm } from "./PoolConfigurationForm";
import { ReadOnlyPoolConfig } from "./ReadOnlyPoolConfig";

export const PoolConfiguration: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { data: existingCredentials, isFetching, refetch } = useGetMinerPoolCredentials();

  return (
    <Card sx={{ mb: 4 }}>
      <CardHeader
        title="Default Pool Configuration"
        subheader="Hashrate not committed to a streaming agreement will be sent to this pool."
        action={!isEditing && <Button onClick={() => setIsEditing(true)}>Edit Configuration</Button>}
      />
      <CardContent>
        {isFetching ? (
          <Skeleton variant="rectangular" height={118} />
        ) : !!existingCredentials?.credentials && !isEditing ? (
          <ReadOnlyPoolConfig config={existingCredentials?.credentials} />
        ) : (
          <PoolConfigurationForm
            initialValues={existingCredentials?.credentials}
            onSuccess={() => {
              setIsEditing(false);
              refetch();
            }}
            onCancel={!!existingCredentials?.credentials && (() => setIsEditing(false))}
          />
        )}
      </CardContent>
    </Card>
  );
};
