import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { UpdateOpportunityFunding } from "../model/api";

const updateFunding = async (payload: UpdateOpportunityFunding) => {
  return await apig.opportunity.updateFunding(payload);
};

const useMutationFunding = () => {
  return useMutation((payload: UpdateOpportunityFunding) => updateFunding(payload));
};

export { useMutationFunding };
