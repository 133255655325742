import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { NAVIGATION_ADMIN_MENU, NAVIGATION_LP_MENU, NAVIGATION_MINER_MENU } from "../../app/constants";
import { AuthUserResponseDto, AuthUserResponseDtoUserTypeEnum } from "../../app/model/api";
import { MuiNavBar } from "../../components/organisms/NavBar/MuiNavBar";
import "./style.scss";

interface MainLayoutProps {
  isErrorPage?: boolean;
  children?: JSX.Element[] | JSX.Element;
}

const MainLayout = ({ children, isErrorPage = false }: MainLayoutProps) => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);

  const userType = user?.data?.userType;
  const userRoles = user?.data?.roles?.map(({ role }) => role);

  const getMenuList = (type: AuthUserResponseDtoUserTypeEnum | undefined) => {
    if (type === AuthUserResponseDtoUserTypeEnum.LP) {
      return NAVIGATION_LP_MENU.filter((item) => !item.role || item.role.some((el) => userRoles?.includes(el)));
    } else if (type === AuthUserResponseDtoUserTypeEnum.Miner) {
      return NAVIGATION_MINER_MENU.filter((item) => !item.role || item.role.some((el) => userRoles?.includes(el)));
    } else if (type === AuthUserResponseDtoUserTypeEnum.Admin) {
      return NAVIGATION_ADMIN_MENU;
    }

    return [];
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <MuiNavBar menuList={getMenuList(userType)}></MuiNavBar>

        {/* <NavBar menuList={getMenuList(userType)} /> */}
        <main>{isErrorPage ? children : <Outlet />}</main>
        <ScrollRestoration />
      </Box>
    </div>
  );
};

export default MainLayout;
