import { Typography } from "@mui/material";
import { addDays, addHours, format, subDays } from "date-fns";
import { compact, max, sortBy } from "lodash";
import { v4 } from "uuid";
import { SATOSHIS_TO_BTC } from "../../../../../app/constants";
import {
  ResDemoCollateral,
  ResDemoCollateralTransactionTypeEnum,
  ResDemoExcessRewardTransaction,
  ResDemoReward,
} from "../../../../../app/model/api";
import { TimelineItemDefinition, formatSatoshi, isInEndState } from "../AdminOpportunityTimeline.utils";

export type OpportunityState = {
  opportunityId: string;
  status:
    | "Awaiting Collateral"
    | "Awaiting Funding"
    | "Draft"
    | "Active"
    | "Defaulted"
    | "Cancelled"
    | "Settled"
    | "Closed";
  collateralStatus: "Sufficient" | "Under sufficient";
  creationDate: Date | string | number;
  updatedAt: Date | string | number;
  publishDate: Date | string | number | null | undefined;
  activeFromDate: Date | string | number | null | undefined;
  neededFundingBTC: number;
  fundings: {
    amountSatoshi: number;
    lpId: string;
    lpName: string;
    date: Date;
  }[];
  rewards: ResDemoReward[];
  collaterals: ResDemoCollateral[];
  excessRewardRelease: ResDemoExcessRewardTransaction | undefined;
};

// available when the opportunity is in draft mode
export type PublishActionContext = {
  readonly kind: "publish";
  readonly minDate: Date;
};

// doesn't do anything by itself, but simulates the passage of time with no inputs from admin/lp/miner for auto checks which
// will change the state of the opportunity
type MinerUnderDeliveryDayContext = {
  readonly kind: "miner-under-delivery-day";
};

// doesn't do anything by itself, but simulates the passage of time with no inputs from admin/lp/miner for auto checks which
// will change the state of the opportunity
export type PassTimeWithDeliveryAsExpectedContext = {
  readonly kind: "pass-time-with-delivery-as-expected";
  readonly minDate: Date;
};

export type AddFundingActionContext = {
  readonly kind: "add-funding";
  readonly minDate: Date;
  readonly maxAmountSatoshi: number;
};

type AddNeededCollateralContext = {
  readonly kind: "add-needed-collateral";
};

export type SimulationActionContext =
  | PublishActionContext
  | AddFundingActionContext
  | AddNeededCollateralContext
  | MinerUnderDeliveryDayContext
  | PassTimeWithDeliveryAsExpectedContext;

export type PublishOpportunityEvent = {
  readonly kind: PublishActionContext["kind"];
  readonly publishDate: string | Date | number;
};

export type AddFundingEvent = {
  readonly kind: AddFundingActionContext["kind"];
  readonly lpId: string;
  readonly lpName: string;
  readonly fundingDate: string | Date | number;
  readonly amountSatoshi: number;
};

export type MinerUnderDeliveryDayEvent = {
  readonly kind: MinerUnderDeliveryDayContext["kind"];
  readonly percentage: number;
};

export type PassTimeWithDeliveryAsExpectedEvent = {
  readonly kind: PassTimeWithDeliveryAsExpectedContext["kind"];
  readonly until: string | Date | number;
};

export type AddNeededCollateralEvent = {
  readonly kind: AddNeededCollateralContext["kind"];
};

export type SimulationEvent =
  | PublishOpportunityEvent
  | AddFundingEvent
  | MinerUnderDeliveryDayEvent
  | PassTimeWithDeliveryAsExpectedEvent
  | AddNeededCollateralEvent;

export const getEventLabel = (input: SimulationEvent["kind"]) => {
  switch (input) {
    case "publish":
      return "Publish opportunity";
    case "add-funding":
      return "Add LP funding";
    case "add-needed-collateral":
      return "Add needed miner collateral";
    case "miner-under-delivery-day":
      return "Reward under delivery day";
    case "pass-time-with-delivery-as-expected":
      return "Pass time with sufficient delivery";
  }
};

export const displayEvent = (input: SimulationEvent) => {
  switch (input.kind) {
    case "publish":
      return "Publish opportunity: " + format(new Date(input.publishDate), "dd MMM yyyy");
    case "miner-under-delivery-day":
      return "A day where the miner under delivers.";
    case "pass-time-with-delivery-as-expected":
      return `Pass time with delivery from miners as expected until ${format(new Date(input.until), "dd MMM yyyy")}`;
    case "add-funding":
      return `Add ${input.amountSatoshi / SATOSHIS_TO_BTC.toNumber()} BTC funding from ${input.lpName}`;
    case "add-needed-collateral":
      return "Add needed collateral from miner";
    default:
      checkExhaustive(input);
  }
};

export const getAvailableSimulationAction = (
  state: OpportunityState,
  blockNewEvents: boolean
): SimulationActionContext[] => {
  const timeline = getSimulationTimelineItems(state);

  if (blockNewEvents || timeline.lastUpdate > subDays(new Date(), 1))
    return []; // if we reach the present avoid any more actions
  else if (state.status === "Draft")
    return [
      {
        kind: "publish",
        minDate: timeline.lastUpdate,
      },
    ];
  else if (state.status === "Awaiting Funding")
    return [
      {
        kind: "add-funding",
        minDate: timeline.lastUpdate,
        maxAmountSatoshi: state.neededFundingBTC * SATOSHIS_TO_BTC.toNumber(),
      },
    ];
  else if (state.status === "Awaiting Collateral")
    return [
      {
        kind: "add-needed-collateral",
      },
    ];
  else if (state.status === "Active") {
    if (state.collateralStatus === "Sufficient")
      return [
        {
          kind: "miner-under-delivery-day",
        },
        {
          kind: "pass-time-with-delivery-as-expected",
          minDate: addDays(timeline.lastUpdate, 1),
        },
      ];
    else
      return [
        {
          kind: "add-needed-collateral",
        },
        {
          kind: "pass-time-with-delivery-as-expected",
          minDate: addDays(timeline.lastUpdate, 1),
        },
      ];
  } else return [];
};

export const buildSimulatedOpportunityState = (
  state: OpportunityState,
  events: SimulationEvent[]
): OpportunityState => {
  return events.reduce((acc, crt) => {
    switch (crt.kind) {
      case "miner-under-delivery-day":
        return { ...acc, collateralStatus: "Under sufficient" };
      case "pass-time-with-delivery-as-expected":
        return acc; // TODO
      case "publish":
        return { ...acc, publishDate: crt.publishDate, status: "Awaiting Funding" };
      case "add-funding": {
        const fundings = [
          ...acc.fundings,
          {
            amountSatoshi: crt.amountSatoshi,
            lpId: crt.lpId,
            lpName: crt.lpName,
            date: new Date(crt.fundingDate),
          },
        ];
        const neededBtc = state.neededFundingBTC - crt.amountSatoshi / SATOSHIS_TO_BTC.toNumber();
        return {
          ...acc,
          fundings,
          neededFundingBTC: neededBtc,
          status: neededBtc === 0 ? "Awaiting Collateral" : "Awaiting Funding",
        };
      }
      case "add-needed-collateral":
        return {
          ...acc,
          status: "Active",
          collateralStatus: "Sufficient",
        };
      default:
        checkExhaustive(crt);
    }
  }, state);
};

export const getSimulationTimelineItems = (
  opportunityState: OpportunityState
): { definition: TimelineItemDefinition[]; lastUpdate: Date } => {
  const timeline = sortBy(
    compact([
      { id: v4(), date: opportunityState.creationDate, description: "Opportunity created" },
      opportunityState.publishDate
        ? { id: v4(), date: opportunityState.publishDate, description: "Opportunity published." }
        : undefined,
      // lp fundings
      ...opportunityState.fundings.map((x) => ({
        id: v4(),
        date: x.date,
        description: "LP funding",
        amount: `${x.amountSatoshi / SATOSHIS_TO_BTC.toNumber()} BTC`,
        details: <Typography variant="caption">{`From: ${x.lpName}`}</Typography>,
      })),

      // when the opportunity became active (full funding and collateral)
      opportunityState.activeFromDate
        ? {
            id: v4(),
            date: opportunityState.activeFromDate,
            description: "Opportunity has become active",
          }
        : undefined,

      // reward calculation and distribution events
      ...opportunityState.rewards.flatMap((x) =>
        compact([
          {
            id: x.rewardId,
            date: new Date(x.createdAt),
            amount: formatSatoshi(x.amountSatoshi),
            description: "Expected daily reward calculated",
          },
          ...x.distributions.map((distr) => ({
            id: distr.id,
            date: new Date(distr.date),
            amount: formatSatoshi(distr.amountSatoshi),
            description: "Reward distribution to LP",
            details: <Typography variant="caption">{`To: ${distr.lpCompanyName} (${distr.lpEmail})`}</Typography>,
          })),
          x.distributions.length === opportunityState.fundings.length
            ? {
                id: v4(),
                date: addHours(new Date(x.updatedAt), 12),
                description: "Daily reward distribution completed",
              }
            : undefined,
        ])
      ),

      ...opportunityState.collaterals.map(collateralDescription),

      // end states
      isInEndState(opportunityState)
        ? {
            id: v4(),
            date: new Date(opportunityState.updatedAt),
            description: "Opportunity is now " + opportunityState.status,
          }
        : undefined,

      opportunityState.excessRewardRelease
        ? {
            id: opportunityState.excessRewardRelease.id,
            date: new Date(opportunityState.excessRewardRelease.date),
            description: "Excess reward release",
            amount: formatSatoshi(opportunityState.excessRewardRelease.amountSatoshi),
          }
        : undefined,
    ]),
    (x) => new Date(x.date).getTime()
  ).map((x) => ({ ...x, date: new Date(x.date) }));
  const lastUpdate = max(timeline.map((x) => new Date(x.date).getTime())) ?? 0;

  return {
    definition: [...timeline],
    lastUpdate: new Date(lastUpdate),
  };
};

export function checkExhaustive(_x: never): never {
  throw new Error("Exhaustive check failed");
}

const collateralDescription = (collateral: ResDemoCollateral): TimelineItemDefinition => {
  const base = {
    id: collateral.id,
    date: new Date(collateral.date),
    amount: formatSatoshi(collateral.amountSatoshi),
  };
  switch (collateral.transactionType) {
    case ResDemoCollateralTransactionTypeEnum.CollateralDeposit:
      return {
        ...base,
        description: "Collateral deposit from miner",
      };
    case ResDemoCollateralTransactionTypeEnum.CollateralLiquidation:
      return {
        ...base,
        description: "Miner under delivery, using collateral liquidation",
      };
    case ResDemoCollateralTransactionTypeEnum.CollateralRelease:
      return {
        ...base,
        description: "Released collateral back to miner",
      };
  }
};
