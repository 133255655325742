import { Box, Typography } from "@mui/material";
import React from "react";
import { useGetMinerPoolCredentials } from "../../app/query/useQueryGetMiner";
import { PoolConfiguration } from "./components/PoolConfiguration";
import { ProxyConfiguration } from "./components/ProxyConfiguration";
import { HashrateMonitor } from "./components/HashrateMonitor";

export const ProxyManagement: React.FC = () => {
  const { data: existingCredentials } = useGetMinerPoolCredentials();
  const hasPoolConfig = !!existingCredentials?.credentials;

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 3, px: 2 }}>
      <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
        Mining Proxy Management
      </Typography>

      <PoolConfiguration />

      {hasPoolConfig && (
        <>
          <ProxyConfiguration />
          <HashrateMonitor />
        </>
      )}
    </Box>
  );
};
